import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Select,
  InputNumber,
  Input,
  Checkbox,
  Button
} from 'antd';
import _ from 'lodash';
import COUNTRIES from '../../../utils/countries';
import COUNTIES from '../../../utils/counties';

const ServiceCompanyInfo = ({ serviceCompany, onSave, form }) => {
  const { getFieldDecorator } = form;

  if (_.isEmpty(serviceCompany)) return null;

  const handleSave = () => {
    form.validateFields((err, values) => {
      if (err) return;

      onSave(_.assign({}, values, { 
        feePercent: values.feePercent / 100,
        feePercentOwnClients: values.feePercentOwnClients / 100,
        percentageHasVAT: values.percentageHasVAT === undefined ? serviceCompany.percentageHasVAT : values.percentageHasVAT
      }));
    });
  };

  const changeVAT = (shouldAddVAT) => {
    if (shouldAddVAT === undefined) {
      shouldAddVAT = serviceCompany.percentageHasVAT
    }

    console.log(shouldAddVAT)
    let feePercent = form.getFieldValue('feePercent');
    let feePercentOwnClients = form.getFieldValue('feePercentOwnClients')

    feePercent = +((shouldAddVAT ? feePercent * 1.19 : feePercent / 1.19).toFixed(2))
    feePercentOwnClients = +((shouldAddVAT ? feePercentOwnClients * 1.19 : feePercentOwnClients / 1.19).toFixed(2))

    form.setFieldsValue({
      'feePercent': feePercent
    })
    form.setFieldsValue({
      'feePercentOwnClients': feePercentOwnClients
    })
  }

  return (
    <div>
      <h2>Date facturare</h2>
      <Row type={'flex'} gutter={16}>
        <Col span={4}>
          <Form.Item label="Nume companie" hasFeedback>
            {getFieldDecorator('companyName', {
              initialValue: serviceCompany.name,
              rules: [
                {
                  required: true,
                  message: 'Numele companiei este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="CUI" hasFeedback>
            {getFieldDecorator('companyCode', {
              initialValue: serviceCompany.code,
              rules: [
                {
                  required: true,
                  message: 'CUI-ul companiei este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="Nr. Reg. Com" hasFeedback>
            {getFieldDecorator('companyRegCode', {
              initialValue: serviceCompany.regCode,
              rules: [
                {
                  required: true,
                  message:
                    'Numarul din Reg. Com. al companiei este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Plătitor TVA">
            {getFieldDecorator('companyVat', {
              initialValue: serviceCompany.vat,
              valuePropName: 'checked',
              rules: [
                {
                  required: true
                }
              ]
            })(<Checkbox />)}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="Țară" hasFeedback>
            {getFieldDecorator('companyCountry', {
              initialValue: serviceCompany.country,
              rules: [
                {
                  required: true,
                  message: 'Țara companiei este obligatorie!'
                }
              ]
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {_.map(COUNTRIES, country => (
                  <Select.Option key={country} value={country}>
                    {country}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="Județ" hasFeedback>
            {getFieldDecorator('companyCounty', {
              initialValue: serviceCompany.county,
              rules: [
                {
                  required: true,
                  message: 'Județul companiei este obligatoriu!'
                }
              ]
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {_.map(COUNTIES, county => (
                  <Select.Option key={county} value={county}>
                    {county}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Adresa" hasFeedback>
            {getFieldDecorator('companyAddress', {
              initialValue: serviceCompany.address,
              rules: [
                {
                  required: true,
                  message: 'Adresa companiei este obligatorie!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Cod poștal" hasFeedback>
            {getFieldDecorator('companyPostalCode', {
              initialValue: serviceCompany.postalCode,
              rules: [
                {
                  required: true,
                  message: 'Codul poștal al companiei este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type={'flex'} gutter={16} className={'section'}>
        <Col span={4}>
          <Form.Item label="Banca" hasFeedback>
            {getFieldDecorator('companyBankName', {
              initialValue: serviceCompany.bankName,
              rules: [
                {
                  required: true,
                  message: 'Banca este obligatorie!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Sucursala" hasFeedback>
            {getFieldDecorator('companyBankBranch', {
              initialValue: serviceCompany.bankBranch,
              rules: [
                {
                  required: true,
                  message: 'Sucursala este obligatorie!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="IBAN" hasFeedback>
            {getFieldDecorator('companyIban', {
              initialValue: serviceCompany.iban,
              rules: [
                {
                  required: true,
                  message: 'IBAN-ul este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <h2>Date contact</h2>
      <Row type={'flex'} gutter={16} className={'section'}>
        <Col span={4}>
          <Form.Item label="Nume" hasFeedback>
            {getFieldDecorator('companyPersonLastName', {
              initialValue: serviceCompany.personLastName,
              rules: [
                {
                  required: true,
                  message: 'Numele persoanei de contact este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>{' '}
        <Col span={4}>
          <Form.Item label="Prenume" hasFeedback>
            {getFieldDecorator('companyPersonFirstName', {
              initialValue: serviceCompany.personFirstName,
              rules: [
                {
                  required: true,
                  message: 'Prenumele persoanei de contact este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Numar de telefon" hasFeedback>
            {getFieldDecorator('companyPersonPhone', {
              initialValue: serviceCompany.personPhone,
              rules: [
                {
                  required: true,
                  message:
                    'Numărul de telefon al persoanei de contact este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Email" hasFeedback>
            {getFieldDecorator('companyPersonEmail', {
              initialValue: serviceCompany.personEmail,
              rules: [
                {
                  required: true,
                  message: 'Emailul persoanei de contact este obligatoriu!'
                },
                {
                  type: 'email',
                  message: 'Email-ul nu este valid!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <h2>Comisioane</h2>
      <Row type={'flex'} gutter={16} className={'section'}>
        <Col span={5}>
          <Form.Item label="Comision Autopia (%)" hasFeedback>
            {getFieldDecorator('feePercent', {
              initialValue: serviceCompany.feePercent * 100,
              rules: [
                {
                  required: true,
                  message: 'Comisionul Autopia este obligatoriu!'
                }
              ]
            })(<InputNumber />)}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="Comision Autopia pentru clienții proprii (%)" hasFeedback>
            {getFieldDecorator('feePercentOwnClients', {
              initialValue: serviceCompany.feePercentOwnClients * 100,
              rules: [
                {
                  required: true,
                  message: 'Comisionul Autopia pentru clienții proprii este obligatoriu!'
                }
              ]
            })(<InputNumber />)}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="Comision fara TVA" hasFeedback>
            {getFieldDecorator('percentageHasVAT', {
              onChange: (e) => changeVAT(e.target.value)
            })(<Checkbox defaultChecked={serviceCompany.percentageHasVAT}/>)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Button type="primary" onClick={handleSave}>
          Salvează modificările
        </Button>
      </Row>
    </div>
  );
};

ServiceCompanyInfo.propTypes = {
  serviceCompany: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
};

ServiceCompanyInfo.defaultProps = {
  onSave: () => null
};

export default Form.create()(ServiceCompanyInfo);
