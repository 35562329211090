import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import _ from 'lodash';

const MyMap = props => {
  const { centerLat, centerLng, isMarkerShown, handleOnDragEnd, extra } = props;

  return (
    <div>
      <GoogleMap
        defaultZoom={18}
        defaultCenter={{ lat: centerLat, lng: centerLng }}
        center={{ lat: centerLat, lng: centerLng }}
      >
        {isMarkerShown && (
          <Marker position={{ lat: centerLat, lng: centerLng }} draggable={true} onDragEnd={handleOnDragEnd} />
        )}
        {isMarkerShown && extra && _.map(extra, coord => {
          if (coord.lat && coord.lng) {
            return <Marker key={`${coord.lat}${coord.lng}`} position={{ lat: coord.lat, lng: coord.lng }} draggable={false} opacity={0.5} />
          } else {
            return null;
          }
        })}
      </GoogleMap>
    </div>
  );
};

export default withScriptjs(withGoogleMap(MyMap));
