const voucherCashbackPointsTypesText = [
  {
    id: 1,
    text: 'Tip 1 - Vehicul adăugat',
  },
  {
    id: 2,
    text: 'Tip 2 - Programare',
  },
  {
    id: 3,
    text: 'Tip 3 - Achitare online',
  },
  {
    id: 4,
    text: 'Tip 4 - Achitare cash',
  },
  {
    id: 5,
    text: 'Tip 5 - RCA',
  },
  {
    id: 6,
    text: 'Tip 6 - Recomandat prieten',
  },
  {
    id: 7,
    text: 'Tip 7 - Recomandat service',
  },
]

export default voucherCashbackPointsTypesText;