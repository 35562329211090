import React, { Component } from "react";
import { Row, Col, Table, Button, Divider, message, Popconfirm } from "antd";
import { getTutorials, deleteTutorial } from "../../../api";
import moment from "moment";

export default class TutorialList extends Component {
  state = {
    tutorials: [],
    isLoading: false
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    getTutorials()
      .then(response => {
        this.setState({
          tutorials: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleGoToAddTutorial = () => {
    const { history } = this.props;
    history.push("/tutorial/add");
  };

  tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Creat la",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: "Titlu",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: "Descriere",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Prioritate (0 - cea mai mică)",
      key: "priority",
      dataIndex: "priority",
      sorter: (a, b) => a.priority - b.priority,
    },
    {
      title: "Acțiuni",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            type={'link'}
            onClick={() => this.handleEditTutorial(record.id)}
          >
            Vizualizează / Editează
          </Button>
          {record.deletedAt ? null : (
            <React.Fragment>
              <Divider type={'vertical'} />
              <Popconfirm
                title="Ești sigur că vrei să ștergi acest tutorial?"
                onConfirm={() => this.handleDeleteTutorial(record.id)}
              >
                <Button type={'danger'}>
                  Șterge
              </Button>
              </Popconfirm>
            </React.Fragment>
            
          )}
        </span>
      )
    }
  ];

  handleEditTutorial = tutorialId => {
    const { history } = this.props;
    history.push(`/tutorial/edit/${tutorialId}`);
  };

  handleDeleteTutorial = tutorialId => {
    const { history } = this.props;
    deleteTutorial(tutorialId)
      .then(response => {
        message.success("Tutorial șters cu succes!");
        history.push("/tutorial");
      })
      .catch(error => {
        message.error("Eroare.");
        console.log(error);
      });
  };

  render() {
    const { tutorials, isLoading } = this.state;

    return (
      <div style={{ padding: 8 }}>
        <Row type="flex" justify="end" style={{ marginBottom: "20px" }}>
          <Col>
            <Button
              icon="plus"
              type="primary"
              onClick={this.handleGoToAddTutorial}
            >
              Adaugă tutorial
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              bordered
              dataSource={tutorials}
              loading={isLoading}
              columns={this.tableColumns}
              scroll={{ x: 'max-content' }}
              rowKey="id"
              pagination={{ pageSize: 15 }}
              style={{ background: "white" }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
