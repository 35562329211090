import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';


const createTableColumns = () => {
  let tableColumns = [
    {
      title: 'ID user',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Creat la',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'First name',
      dataIndex: 'fname',
      key: 'fname',
      sorter: (a, b) => a.fname.localeCompare(b.fname),
    },
    {
      title: 'Last name',
      dataIndex: 'lname',
      key: 'lname',
      sorter: (a, b) => a.lname.localeCompare(b.lname),
    },
    {
      title: 'Credite totale ramase',
      key: 't3',
      sorter: (a, b) => ((a.creditsAdd - a.creditsSubtract) + (a.creditsAddBypass - a.creditsSubtractBypass)) - ((b.creditsAdd - b.creditsSubtract) + (b.creditsAddBypass - b.creditsSubtractBypass)),
      render: (text, record) => ((record.creditsAdd - record.creditsSubtract) + (record.creditsAddBypass - record.creditsSubtractBypass)) / 100,
      defaultSortOrder: "descend"
    },
    {
      title: 'Credite simple',
      children: [
        {
          title: 'Credite simple ramase',
          key: 't1',
          sorter: (a, b) => (a.creditsAdd - a.creditsSubtract) - (b.creditsAdd - b.creditsSubtract),
          render: (text, record) => (record.creditsAdd - record.creditsSubtract) / 100,
        },
        {
          title: 'Credite simple adunate (+)',
          dataIndex: 'creditsAdd',
          key: 'creditsAdd',
          sorter: (a, b) => a.creditsAdd - b.creditsAdd,
          render: (text, record) => record.creditsAdd / 100
        },
        {
          title: 'Credite simple folosite (-)',
          dataIndex: 'creditsSubtract',
          key: 'creditsSubtract',
          sorter: (a, b) => a.creditsSubtract - b.creditsSubtract,
          render: (text, record) => record.creditsSubtract / 100
        }
      ]
    },
    {
      title: 'Credite bypass',
      children: [
        {
          title: 'Credite bypass ramase',
          key: 't2',
          sorter: (a, b) => (a.creditsAddBypass - a.creditsSubtractBypass) - (b.creditsAddBypass - b.creditsSubtractBypass),
          render: (text, record) => (record.creditsAddBypass - record.creditsSubtractBypass) / 100,
        },
        {
          title: 'Credite bypass adunate (+)',
          dataIndex: 'creditsAddBypass',
          key: 'creditsAddBypass',
          sorter: (a, b) => a.creditsAddBypass - b.creditsAddBypass,
          render: (text, record) => record.creditsAddBypass / 100
        },
        {
          title: 'Credite bypass folosite (-)',
          dataIndex: 'creditsSubtract',
          key: 'creditsSubtract',
          sorter: (a, b) => a.creditsSubtractBypass - b.creditsSubtractBypass,
          render: (text, record) => record.creditsSubtractBypass / 100
        }
      ]
    }
  ]

  return tableColumns;
}

const VouchersCreditsGatheredTable = ({
  isLoading,
  creditsGathered,
}) => {
  
  const tableColumns = createTableColumns()
  return (
    <Table
      bordered
      dataSource={creditsGathered}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={false}
    />
  );
};

VouchersCreditsGatheredTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  creditsGathered: PropTypes.array.isRequired,
};

export default VouchersCreditsGatheredTable;
