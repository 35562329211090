import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import NotificationsList from './NotificationsList/NotificationsList';
import NotificationsAdd from './NotificationsAdd/NotificationsAdd';

export default class NotificationsPage extends Component {
  render() {
    const { match } = this.props;

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Switch>
          <Redirect exact path={`${match.path}/`} to={`${match.path}/list`} />
          <Route path={`${match.path}/list`} component={NotificationsList} />
          <Route path={`${match.path}/add`} component={NotificationsAdd} />
          <Redirect to={`${match.path}/list`} />
        </Switch>
      </div>
    );
  }
}
