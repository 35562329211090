import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import ArticleList from './ArticleList/ArticleList'
import ArticleAddOrEdit from './ArticleAddOrEdit/ArticleAddOrEdit'

export default class ArticlePage extends Component {
  render () {
    const { match } = this.props

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Switch>
          <Redirect exact path={`${match.path}/`} to={`${match.path}/list`} />
          <Route path={`${match.path}/list`} component={ArticleList} />
          <Route path={`${match.path}/add`} component={ArticleAddOrEdit} />
          <Route path={`${match.path}/edit/:articleId`} component={ArticleAddOrEdit} />
          <Redirect to={`${match.path}/list`} />
        </Switch>
      </div>
    )
  }
}
