import { Breadcrumb, Icon, Layout, Menu } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { refreshToken as refreshTokenApi } from '../../api';
import config from '../../config';
import { logout, refreshToken } from '../../ducks/auth';
import logo from '../../images/logo.svg';
import ArticlePage from '../ArticlePage/ArticlePage';
import DashboardPage from '../DashboardPage/DashboardPage';
import DatabaseSearchPage from '../DatabaseSearchPage/DatabaseSearchPage';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import NewsPage from '../NewsPage/NewsPage';
import NotificationsPage from '../NotificationsPage/NotificationsPage';
import PartsCredentialsPage from '../PartsCredentialsPage/PartsCredentialsPage';
import PartsPage from '../PartsPage/PartsPage';
import PickerAboPage from '../PickerAboPage/PickerAboPage';
import RcaPage from '../RcaPage/RcaPage';
import RoadAssistancePage from '../RoadAssistancePage/RoadAssistancePage';
import ServicesPage from '../ServicesPage/ServicesPage';
import SpecialActionsPage from '../SpecialActionsPage/SpecialActionsPage';
import TutorialPage from '../TutorialPage/TutorialPage';
import UserReviewsPage from '../UserReviewsPage/UserReviewsPage';
import VouchersPage from '../VouchersPage/VouchersPage';
import WebContactPage from '../WebContactPage/WebContactPage';
import './styles.css';

class App extends Component {
  state = {
    isLoaded: false,
    isSiderCollapsed: false,
    selectedSiderMenuKey: [],
  };

  handleCollapseSider = () => {
    const { isSiderCollapsed } = this.state;

    this.setState({ isSiderCollapsed: !isSiderCollapsed });
  };

  componentDidMount = () => {
    const { auth, history, location, refreshToken } = this.props;
    // If not authenticated, redirect to login page
    if (!auth.authenticated) history.push('/login');

    // Request a new token
    refreshTokenApi()
      .then((response) => {
        refreshToken(response.data.token);
      })
      .catch((error) => {
        // Token invalid or expired. Logging out.
        this.handleLogout();
      });

    // Set corresponding side menu key at mount
    const key = location.pathname.split('/')[1];
    this.setState({ selectedSiderMenuKey: [key], isLoaded: true });
  };

  handleLogout = () => {
    const { logout, history } = this.props;
    logout();
    history.push('/login');
  };

  handleSiderMenuOnClick = (e) => {
    const { history } = this.props;
    history.push(`/${e.key}`);
    this.setState({ selectedSiderMenuKey: [e.key] });
  };

  handleTopMenuOnClick = (e) => {
    if (e.key === 'LOGOUT') {
      this.handleLogout();
    }
  };

  siderMenuItems = {
    dashboard: {
      icon: 'dashboard',
      label: 'Dashboard',
    },
    users: {
      icon: 'user',
      label: 'Utilizatori',
    },
    statistics: {
      icon: 'bar-chart',
      label: 'Statistici',
    },
    services: {
      icon: 'appstore-o',
      label: 'Service auto',
    },
    greenlight: {
      icon: 'message',
      label: 'Greenlight',
    },
    notifications: {
      icon: 'message',
      label: 'Notificări',
    },
    article: {
      icon: 'switcher',
      label: 'Articole',
    },
    vouchers: {
      icon: 'barcode',
      label: 'Vouchere',
    },
    userreviews: {
      icon: 'notification',
      label: 'Review-uri',
    },
    parts: {
      icon: 'file-search',
      label: 'Piese',
    },
    partscredentials: {
      icon: 'file-search',
      label: 'Credențiale piese',
    },
    tutorial: {
      icon: 'book',
      label: 'Tutoriale',
    },
    dbsearch: {
      icon: 'database',
      label: 'Caută în baza de date',
    },
  };

  render() {
    const { auth, location } = this.props;
    const { isLoaded, isSiderCollapsed, selectedSiderMenuKey } = this.state;

    return (
      <React.Fragment>
        {isLoaded ? (
          <Layout style={{ minHeight: '100vh' }}>
            <Layout.Sider
              theme="light"
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
              }}
              collapsible
              collapsed={isSiderCollapsed}
              onCollapse={this.handleCollapseSider}
            >
              <div
                style={{
                  height: 96,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={logo}
                  alt={config.application.APPLICATION_NAME}
                  style={{ width: 40, marginRight: 8 }}
                />
                {isSiderCollapsed ? (
                  ''
                ) : (
                  <span
                    style={{
                      fontSize: 16,
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      color: '#00ccdf',
                    }}
                  >
                    {config.application.APPLICATION_NAME}
                  </span>
                )}
              </div>
              <Menu
                id={'main-menu'}
                theme="light"
                mode="inline"
                onClick={this.handleSiderMenuOnClick}
                selectedKeys={selectedSiderMenuKey}
                openKeys={[
                  'dashboard',
                  'services',
                  'greenlight',
                  'notifications',
                  'news',
                  'article',
                  'vouchers',
                  'roadassistance',
                  'partscredentials',
                  'tutorial',
                  'parts',
                ]}
              >
                <Menu.Item key="dashboard">
                  {/*<Icon type="dashboard" />*/}
                  <span>Dashboard</span>
                </Menu.Item>
                {/* <Menu.Item key="2">
                  <Icon type="user" />
                  <span>Utilizatori</span>
                </Menu.Item>
                <Menu.Item key="3">
                  <Icon type="bar-chart" />
                  <span>Statistici</span>
                </Menu.Item> */}
                <Menu.SubMenu
                  key="services"
                  title={
                    <span>
                      {/*<Icon type="appstore-o" />*/}
                      <span>Service auto</span>
                    </span>
                  }
                >
                  <Menu.Item key="services/list">
                    <span>Listă</span>
                  </Menu.Item>
                  <Menu.Item key="services/add">
                    <span>Adaugă</span>
                  </Menu.Item>
                  <Menu.Item key="services/addbranch">
                    <span>Adaugă filială</span>
                  </Menu.Item>
                  <Menu.Item key="services/googlereview">
                    <span>Review-uri Google</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="rca">
                  {/*<Icon type="dashboard" />*/}
                  <span>RCA</span>
                </Menu.Item>
                <Menu.SubMenu
                  key="greenlight"
                  title={
                    <span>
                      <span>Greenlight</span>
                    </span>
                  }
                >
                  <Menu.Item key="greenlight/info">
                    <span>Info Greenlight</span>
                  </Menu.Item>
                  <Menu.Item key="greenlight/add">
                    <span>Adauga greenlight</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key="notifications"
                  title={
                    <span>
                      {/*<Icon type="message" />*/}
                      <span>Notificări</span>
                    </span>
                  }
                >
                  <Menu.Item key="notifications/list">
                    <span>Listă</span>
                  </Menu.Item>
                  <Menu.Item key="notifications/add">
                    <span>Adaugă</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key="news"
                  title={
                    <span>
                      {/*<Icon type="book" />*/}
                      <span>Noutăți</span>
                    </span>
                  }
                >
                  <Menu.Item key="news/list">
                    <span>Listă</span>
                  </Menu.Item>
                  <Menu.Item key="news/add">
                    <span>Adaugă</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key="article"
                  title={
                    <span>
                      {/*<Icon type="book" />*/}
                      <span>Articole</span>
                    </span>
                  }
                >
                  <Menu.Item key="article/list">
                    <span>Listă</span>
                  </Menu.Item>
                  <Menu.Item key="article/add">
                    <span>Adaugă</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key="vouchers"
                  title={
                    <span>
                      {/*<Icon type="book" />*/}
                      <span>Vouchere</span>
                    </span>
                  }
                >
                  <Menu.Item key="vouchers/creditsgathered/list">
                    <span>Credite curente</span>
                  </Menu.Item>
                  <Menu.Item key="vouchers/list">
                    <span>Listă</span>
                  </Menu.Item>
                  <Menu.Item key="vouchers/add">
                    <span>Adaugă</span>
                  </Menu.Item>
                  <Menu.Item key="vouchers/cashbackpoints/list">
                    <span>Puncte Cashback</span>
                  </Menu.Item>
                  <Menu.Item key="vouchers/servicerecommended/list">
                    <span>Service-uri recomandate</span>
                  </Menu.Item>
                  <Menu.Item key="vouchers/creditsconsumed/list">
                    <span>Credite folosite</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key="roadassistance"
                  title={
                    <span>
                      {/*<Icon type="car" />*/}
                      <span>Tractare</span>
                    </span>
                  }
                >
                  <Menu.Item key="roadassistance/list">
                    <span>Listă</span>
                  </Menu.Item>
                  <Menu.Item key="roadassistance/add">
                    <span>Adaugă</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="dbsearch">
                  <span>Caută în baza de date</span>
                </Menu.Item>
                <Menu.Item key="userreviews">
                  <span>Review-uri useri</span>
                </Menu.Item>
                <Menu.Item key="webcontact">
                  <span>Formular Contact</span>
                </Menu.Item>
                <Menu.SubMenu
                  key="parts"
                  title={
                    <span>
                      <span>Piese</span>
                    </span>
                  }
                >
                  <Menu.Item key="parts/analytics">
                    <span>Analitice</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key="partscredentials"
                  title={
                    <span>
                      {/*<Icon type="book" />*/}
                      <span>Credențiale Piese</span>
                    </span>
                  }
                >
                  <Menu.Item key="partscredentials/list">
                    <span>Listă</span>
                  </Menu.Item>
                  <Menu.Item key="partscredentials/add">
                    <span>Adaugă</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key="tutorial"
                  title={
                    <span>
                      {/*<Icon type="book" />*/}
                      <span>Tutoriale Service</span>
                    </span>
                  }
                >
                  <Menu.Item key="tutorial/list">
                    <span>Listă</span>
                  </Menu.Item>
                  <Menu.Item key="tutorial/add">
                    <span>Adaugă</span>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key="specialactions">
                  <span>ACȚIUNI SPECIALE</span>
                </Menu.Item>
                <Menu.Item key="placeholder">
                  <span></span>
                </Menu.Item>
              </Menu>
            </Layout.Sider>
            <Layout
              style={{
                marginLeft: isSiderCollapsed ? 80 : 200,
                transition: 'all .2s',
              }}
            >
              <Layout.Header
                style={{
                  background: '#fff',
                  justifyContent: 'left',
                  zIndex: 9,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0 24px',
                  height: '46px',
                }}
              >
                <div style={{ display: 'flex', marginLeft: 'auto' }}>
                  <Menu mode="horizontal" onClick={this.handleTopMenuOnClick}>
                    <Menu.SubMenu
                      style={{
                        float: 'right',
                      }}
                      title={
                        <span>
                          <Icon type="user" className="UserIcon" />
                          {`${auth.fname} ${auth.lname}`}
                        </span>
                      }
                    >
                      <Menu.Item key="LOGOUT">Deconectează-te</Menu.Item>
                    </Menu.SubMenu>
                  </Menu>
                </div>
              </Layout.Header>
              <Layout.Content style={{ padding: 16 }}>
                <Breadcrumb style={{ marginBottom: 8 }}>
                  <Breadcrumb.Item>
                    <Icon type="home" />
                  </Breadcrumb.Item>
                  {/* <Breadcrumb.Item>
                    <Icon type="user" />
                    <span>Service auto</span>
                  </Breadcrumb.Item> */}
                  <Breadcrumb.Item>
                    <Icon
                      type={
                        location.pathname.length > 1 &&
                        this.siderMenuItems[location.pathname.split('/')[1]] &&
                        this.siderMenuItems[location.pathname.split('/')[1]]
                          .icon
                      }
                    />
                    <span>
                      {location.pathname.length > 1 &&
                        this.siderMenuItems[location.pathname.split('/')[1]] &&
                        this.siderMenuItems[location.pathname.split('/')[1]]
                          .label}
                    </span>
                  </Breadcrumb.Item>
                </Breadcrumb>
                <Switch>
                  <Redirect exact path="/" to="/dashboard" />
                  <Route path="/dashboard" component={DashboardPage} />
                  <Route path="/services" component={ServicesPage} />
                  <Route path="/rca" component={RcaPage} />
                  <Route path="/notifications" component={NotificationsPage} />
                  <Route
                    path="/roadassistance"
                    component={RoadAssistancePage}
                  />
                  <Route path="/news" component={NewsPage} />
                  <Route path="/article" component={ArticlePage} />
                  <Route path="/greenlight" component={PickerAboPage} />
                  <Route path="/vouchers" component={VouchersPage} />
                  <Route path="/dbsearch" component={DatabaseSearchPage} />
                  <Route path="/userreviews" component={UserReviewsPage} />
                  <Route path="/webcontact" component={WebContactPage} />
                  <Route path="/parts" component={PartsPage} />
                  <Route
                    path="/partscredentials"
                    component={PartsCredentialsPage}
                  />
                  <Route path="/tutorial" component={TutorialPage} />
                  <Route
                    path="/specialactions"
                    component={SpecialActionsPage}
                  />
                  <Redirect to="/dashboard" />
                </Switch>
              </Layout.Content>
              {/* <Layout.Footer style={{ textAlign: 'center' }}>
                &copy; 2018 DISTOPIA SRL
              </Layout.Footer> */}
            </Layout>
          </Layout>
        ) : (
          <LoadingScreen />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default connect(mapStateToProps, { logout, refreshToken })(App);
