import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';


const createTableColumns = () => {
  let tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Creat la',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user - b.user,
    },
    {
      title: 'Operație',
      dataIndex: 'operation',
      key: 'operation',
      sorter: (a, b) => a.operation - b.operation,
    },
    {
      title: 'Credite folosite',
      dataIndex: 'credits',
      key: 'credits',
      sorter: (a, b) => a.credits - b.credits,
      render: (text, record) => record.credits / 100
    },
    {
      title: 'Credite folosite care ocolesc limita de procent',
      dataIndex: 'bypassPercentageCredits',
      key: 'bypassPercentageCredits',
      sorter: (a, b) => a.bypassPercentageCredits - b.bypassPercentageCredits,
      render: (text, record) => record.bypassPercentageCredits / 100
    }
  ]

  return tableColumns;
}

const VouchersCreditsConsumedTable = ({
  isLoading,
  creditsConsumed,
}) => {
  
  const tableColumns = createTableColumns()
  return (
    <Table
      bordered
      dataSource={creditsConsumed}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 30 }}
    />
  );
};

VouchersCreditsConsumedTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  creditsConsumed: PropTypes.array.isRequired,
};

export default VouchersCreditsConsumedTable;
