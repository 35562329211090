import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox, Divider, Button } from 'antd';
import moment from 'moment';

const ServicesGoogleReviewTable = ({
  data,
  isLoading,
  type,
  onSeen,
  onActivate,
  onDeactivate
}) => {
  const actionColumn = (number) => ({
    title: 'Acțiuni',
    key: `action${number}`,
    render: (text, record) => (
      <span>
        {record.use ? (
          <Button
            type={'link'}
            onClick={() => onDeactivate(record.id)}
          >
            Dezactivează
          </Button>
        ) : (
          <Button
            type={'link'}
            onClick={() => onActivate(record.id)}
          >
            Activează
          </Button>
        )}
        {record.new && (
          <React.Fragment>
            <Divider type={'vertical'} />
            <Button
              type={'link'}
              onClick={() => onSeen(record.id)}
            >
              Marchează văzut
            </Button>
          </React.Fragment>
        )}
      </span>
    )
  })
  const tableColumnsAll = [
    {
      title: 'Review nou',
      dataIndex: 'new',
      key: 'new',
      defaultSortOrder: 'descend',
      sorter: (a, b) => (a.new === b.new) ? 0 : a.new ? 1 : -1,
      render: (text, record) => <Checkbox checked={record.new} />
    },
    {
      title: 'În folosire',
      dataIndex: 'use',
      key: 'use',
      sorter: (a, b) => (a.use === b.use) ? 0 : a.use ? 1 : -1,
      render: (text, record) => <Checkbox checked={record.use} />
    },
    {
      title: 'ID service',
      dataIndex: 'service',
      key: 'service',
      sorter: (a, b) => a.service - b.service,
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      sorter: (a, b) => a.rating - b.rating,
    },
    {
      title: 'Rating total service',
      dataIndex: 'ratingMean',
      key: 'ratingMean',
      sorter: (a, b) => a.ratingMean - b.ratingMean,
    },
    {
      title: 'Număr total ratinguri service',
      dataIndex: 'userRatingsTotal',
      key: 'userRatingsTotal',
      sorter: (a, b) => a.userRatingsTotal - b.userRatingsTotal,
    },
    actionColumn(1),
    {
      title: 'Dat la data de',
      dataIndex: 'time',
      key: 'time',
      sorter: (a, b) => a.time.localeCompare(b.time),
      render: (text, record) => moment(record.time).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'ID review',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Place ID service',
      dataIndex: 'placeId',
      key: 'placeId',
      sorter: (a, b) => a.placeId.localeCompare(b.placeId),
    },
    {
      title: 'Nume autor',
      dataIndex: 'authorName',
      key: 'authorName',
      sorter: (a, b) => a.authorName.localeCompare(b.authorName),
    },
    {
      title: 'URL autor',
      dataIndex: 'authorUrl',
      key: 'authorUrl',
      sorter: (a, b) => a.authorUrl.localeCompare(b.authorUrl),
      render: (text, record) => <a href={record.authorUrl}>{record.authorUrl}</a>
    },
    {
      title: 'Limbă',
      dataIndex: 'language',
      key: 'language',
      sorter: (a, b) => a.language.localeCompare(b.language),
    },
    {
      title: 'Poză profil',
      dataIndex: 'profilePhotoUrl',
      key: 'profilePhotoUrl',
      sorter: (a, b) => a.profilePhotoUrl.localeCompare(b.profilePhotoUrl),
      render: (text, record) => <a href={record.profilePhotoUrl}>{record.profilePhotoUrl}</a>
    },
    {
      title: 'Conținut',
      dataIndex: 'text',
      key: 'text',
      sorter: (a, b) => a.text.localeCompare(b.text),
    },
    actionColumn(2)
  ];

  const tableColumnsGroupByService = [
    {
      title: 'ID service',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Nume service',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Număr review-uri',
      dataIndex: 'review',
      key: 'review',
      sorter: (a, b) => a.children && b.children ? a.children.length - b.children.length : 0,
      render: (text, record) => record.children ? record.children.length : ''
    },
  ];

  const columns = 
    (type === 0) ? tableColumnsAll :
    (type === 1) ? tableColumnsGroupByService : []

  return (
    <Table
      bordered
      dataSource={data}
      loading={isLoading}
      columns={columns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 100 }}
      expandedRowRender={(type === 1) ? (record, index, indent, expanded) => <Table columns={tableColumnsAll} dataSource={record.children} rowKey="id" pagination={false} /> : undefined}

    />
  );
};

ServicesGoogleReviewTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.number.isRequired,
  onSeen: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
};

export default ServicesGoogleReviewTable;
