import React, { Component } from 'react';
import VoucherServiceRecommendedTable from './VoucherServiceRecommendedTable';
import Helmet from 'react-helmet';
import { getServiceRecommended } from '../../../api';
import { Row, Col, Button } from 'antd';
import NProgress from 'nprogress';
import moment from 'moment'
import _ from 'lodash'

const strip = x => (x || '').toString().replace(/,/g, '').replace(/\r/g, ' ').replace(/\n/g, ' ');

export default class VoucherServiceRecommendedList extends Component {
  state = {
    isLoading: false,
    serviceRecommended: [],
  };

  getCsvContent = () => {
    const { serviceRecommended } = this.state;

    const header = 'id,Creat la,Modificat la,Id utilizator,Nume service,Adresa service,Telefon service,Mesaj recomandare\n';
    const serviceRecommendedSorted = _.sortBy(serviceRecommended, line => line.id)
    console.log(serviceRecommendedSorted)

    const rows = _.map(serviceRecommendedSorted, line => (
      `${strip(line.id)},${strip(line.createdAt)},${strip(line.updatedAt)},${strip(line.user)},${strip(line.name)},${strip(line.address)},${strip(line.phone)},${strip(line.message)}\n`
    ))

    let final = header;
    _.forEach(rows, r => {
      final += r
    })

    return final;
  }


  downloadFile = () => {
    const content = this.getCsvContent()
    const element = document.createElement("a");
    const file = new Blob([content], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    const now = moment();
    element.download = `${now.format('YYYY')}-${now.format('MM')}-${now.format('DD')} export recomandari.csv`;
    document.body.appendChild(element);
    element.click();
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getServiceRecommended()
      .then(resp => {
        this.setState({
          serviceRecommended: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };


  render() {
    const { isLoading, serviceRecommended } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Listă serviceuri recomandate" />
        <Row type="flex" justify="end" style={{ marginBottom: "20px" }}>
          <Col>
            <Button
              icon="file-excel"
              type="primary"
              onClick={this.downloadFile}
            >
              Export
            </Button>
          </Col>
        </Row>
        <VoucherServiceRecommendedTable
          isLoading={isLoading}
          serviceRecommended={serviceRecommended}
        />
      </React.Fragment>
    );
  }
}
