import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PickerAboInfoPage from './PickerAboInfoPage/PickerAboInfoPage';
import { AddPickerAboPage } from './AddPickerAboPage/AddPickerAboPage';

export default class PickerAboPage extends Component {
  render() {
    const { match } = this.props;

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Switch>
          <Redirect exact path={`${match.path}/`} to={`${match.path}/info`} />
          <Route path={`${match.path}/info`} component={PickerAboInfoPage} />
          <Route path={`${match.path}/add`} component={AddPickerAboPage} />
        </Switch>
      </div>
    );
  }
}
