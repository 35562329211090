import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Button
} from 'antd';
import _ from 'lodash';
import COUNTRIES from '../../utils/countries';
import COUNTIES from '../../utils/counties';

const RcaServiceCompanyInfo = ({ rcaServiceCompany, onSave, form }) => {
  const { getFieldDecorator } = form;

  const handleSave = () => {
    form.validateFields((err, values) => {
      if (err) return;

      onSave(_.assign({}, values));
    });
  };

  return (
    <div>
      <h2>Date facturare</h2>
      <Row type={'flex'} gutter={16}>
        <Col span={4}>
          <Form.Item label="Nume companie" hasFeedback>
            {getFieldDecorator('companyName', {
              initialValue: rcaServiceCompany.name,
              rules: [
                {
                  required: true,
                  message: 'Numele companiei este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="CUI" hasFeedback>
            {getFieldDecorator('companyCode', {
              initialValue: rcaServiceCompany.code,
              rules: [
                {
                  required: true,
                  message: 'CUI-ul companiei este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="Nr. Reg. Com" hasFeedback>
            {getFieldDecorator('companyRegCode', {
              initialValue: rcaServiceCompany.regCode,
              rules: [
                {
                  required: true,
                  message:
                    'Numarul din Reg. Com. al companiei este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Plătitor TVA">
            {getFieldDecorator('companyVat', {
              initialValue: rcaServiceCompany.vat,
              valuePropName: 'checked',
              rules: [
                {
                  required: true
                }
              ]
            })(<Checkbox />)}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="Țară" hasFeedback>
            {getFieldDecorator('companyCountry', {
              initialValue: rcaServiceCompany.country,
              rules: [
                {
                  required: true,
                  message: 'Țara companiei este obligatorie!'
                }
              ]
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {_.map(COUNTRIES, country => (
                  <Select.Option key={country} value={country}>
                    {country}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="Județ" hasFeedback>
            {getFieldDecorator('companyCounty', {
              initialValue: rcaServiceCompany.county,
              rules: [
                {
                  required: true,
                  message: 'Județul companiei este obligatoriu!'
                }
              ]
            })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {_.map(COUNTIES, county => (
                  <Select.Option key={county} value={county}>
                    {county}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Adresa" hasFeedback>
            {getFieldDecorator('companyAddress', {
              initialValue: rcaServiceCompany.address,
              rules: [
                {
                  required: true,
                  message: 'Adresa companiei este obligatorie!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Cod poștal" hasFeedback>
            {getFieldDecorator('companyPostalCode', {
              initialValue: rcaServiceCompany.postalCode,
              rules: [
                {
                  required: true,
                  message: 'Codul poștal al companiei este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type={'flex'} gutter={16} className={'section'}>
        <Col span={4}>
          <Form.Item label="Banca" hasFeedback>
            {getFieldDecorator('companyBankName', {
              initialValue: rcaServiceCompany.bankName,
              rules: [
                {
                  required: true,
                  message: 'Banca este obligatorie!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Sucursala" hasFeedback>
            {getFieldDecorator('companyBankBranch', {
              initialValue: rcaServiceCompany.bankBranch,
              rules: [
                {
                  required: true,
                  message: 'Sucursala este obligatorie!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="IBAN" hasFeedback>
            {getFieldDecorator('companyIban', {
              initialValue: rcaServiceCompany.iban,
              rules: [
                {
                  required: true,
                  message: 'IBAN-ul este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <h2>Date contact</h2>
      <Row type={'flex'} gutter={16} className={'section'}>
        <Col span={4}>
          <Form.Item label="Nume" hasFeedback>
            {getFieldDecorator('companyPersonLastName', {
              initialValue: rcaServiceCompany.personLastName,
              rules: [
                {
                  required: true,
                  message: 'Numele persoanei de contact este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>{' '}
        <Col span={4}>
          <Form.Item label="Prenume" hasFeedback>
            {getFieldDecorator('companyPersonFirstName', {
              initialValue: rcaServiceCompany.personFirstName,
              rules: [
                {
                  required: true,
                  message: 'Prenumele persoanei de contact este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Numar de telefon" hasFeedback>
            {getFieldDecorator('companyPersonPhone', {
              initialValue: rcaServiceCompany.personPhone,
              rules: [
                {
                  required: true,
                  message:
                    'Numărul de telefon al persoanei de contact este obligatoriu!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Email" hasFeedback>
            {getFieldDecorator('companyPersonEmail', {
              initialValue: rcaServiceCompany.personEmail,
              rules: [
                {
                  required: true,
                  message: 'Emailul persoanei de contact este obligatoriu!'
                },
                {
                  type: 'email',
                  message: 'Email-ul nu este valid!'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Button type="primary" onClick={handleSave}>
          Salvează modificările
        </Button>
      </Row>
    </div>
  );
};

RcaServiceCompanyInfo.propTypes = {
  serviceCompany: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
};

RcaServiceCompanyInfo.defaultProps = {
  onSave: () => null
};

export default Form.create()(RcaServiceCompanyInfo);
