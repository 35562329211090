import React, { Component } from 'react';
import ServicesGoogleReviewTable from './ServicesGoogleReviewTable';
import Helmet from 'react-helmet';
import { getGoogleReviews, seenReview, activateReview, updateServicesGoogleReviews, updateServicesRating } from '../../../api';
import NProgress from 'nprogress';
import _ from 'lodash';
import { message } from 'antd';
import { Row, Col, Select, Button } from 'antd';

export default class ServicesGoogleReview extends Component {
  state = {
    isLoading: false,
    rawData: [],
    services: [],
    data: [],
    tableType: 0,

    forceUpdateFetching: false
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getGoogleReviews()
      .then(response => {
        if (response.data && response.data.reviews && response.data.ratings && response.data.services){
          this.setState({
            rawData: this.buildData(response.data.reviews, response.data.ratings),
            services: response.data.services
          }, this.groupData);
        }
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  buildData = (reviews, ratings) => {
    return _.map(reviews, review => {
      const rating = _.find(ratings, { service: review.service });
      return {
        ...review,
        ratingMean: rating ? rating.rating : null,
        userRatingsTotal: rating ? rating.userRatingsTotal : null
      }
    })
  }

  handleSeenReview = reviewId => {
    const { history } = this.props;

    seenReview(reviewId)
      .then(response => {
        message.success("Review văzut!");
        history.push("/services/googlereview");
      })
      .catch(error => {
        message.error("Eroare.");
        console.log(error);
      });
  };

  handleActivateReview = (reviewId, activate) => {
    const { history } = this.props;

    activateReview(reviewId, activate)
      .then(response => {
        if (activate){
          message.success("Review activat cu succes!");
        } else {
          message.success("Review dezactivat cu succes!");
        }
        history.push("/services/googlereview");
      })
      .catch(error => {
        message.error("Eroare.");
        console.log(error);
      });
  }

  handleChangeTableType = (type) => {
    this.setState({
      tableType: type
    }, this.groupData)
  }

  groupData = () => {
    const { tableType, rawData, services } = this.state;

    if (tableType === 0) {
      this.setState({
        data: rawData
      })
    } else if (tableType === 1){
      const grouped = _.groupBy(rawData, 'service')
      const data = _.map(services, service => ({
        ...service,
        children: grouped[service.id] ? grouped[service.id] : [] 
      }))
      this.setState({
        data
      })
    } else {
      this.setState({
        data: []
      })
    }
  }

  handleUpdateServicesGoogleReviews = () => {
    this.setState({ forceUpdateFetching: true })
    updateServicesGoogleReviews()
      .then(response => {
        this.setState({ forceUpdateFetching: false })
        message.success("Review-uri updatate!");
      })
      .catch(error => {
        this.setState({ forceUpdateFetching: false })
        message.error("Eroare.");
        console.log(error);
      });
  };

  handleUpdateServicesRating = () => {
    this.setState({ forceUpdateFetching: true })
    updateServicesRating()
      .then(response => {
        this.setState({ forceUpdateFetching: false })
        message.success("Rating-uri updatate!");
      })
      .catch(error => {
        this.setState({ forceUpdateFetching: false })
        message.error("Eroare.");
        console.log(error);
      });
  };

  render() {
    const { isLoading, data, tableType, forceUpdateFetching } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Listă service" />
        <Row
          type="flex"
          justify="space-between"
          gutter={24}
          style={{ marginBottom: 16 }}
        >
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Select value={tableType} style={{ width: '100%' }} onChange={this.handleChangeTableType}>
              <Select.Option key={0} value={0}>Toate review-urile</Select.Option>
              <Select.Option key={1} value={1}>Review-uri grupate pe service-uri</Select.Option>
            </Select>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            style={{ textAlign: 'right' }}
          >
            <Button
              loading={forceUpdateFetching}
              icon="heat-map"
              style={{ width: '50%' }}
              onClick={this.handleUpdateServicesGoogleReviews}
            >
              Updatează forțat review-urile la service-uri
            </Button>
            <Button
              loading={forceUpdateFetching}
              icon="heat-map"
              style={{ width: '50%' }}
              onClick={this.handleUpdateServicesRating}
            >
              Updatează forțat ratingul la service-uri
            </Button>
          </Col>
        </Row>
        <ServicesGoogleReviewTable
          isLoading={isLoading}
          data={data}
          type={tableType}
          onSeen={this.handleSeenReview}
          onActivate={(reviewId) => this.handleActivateReview(reviewId, true)}
          onDeactivate={(reviewId) => this.handleActivateReview(reviewId, false)}
        />
      </React.Fragment>
    );
  }
}
