import React, { Component } from 'react';
import VouchersCreditsConsumedTable from './VouchersCreditsConsumedTable';
import Helmet from 'react-helmet';
import { getCreditsConsumed } from '../../../api';
import NProgress from 'nprogress';


export default class VouchersCreditsConsumedList extends Component {
  state = {
    isLoading: false,
    creditsConsumed: [],
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getCreditsConsumed()
      .then(resp => {
        this.setState({
          creditsConsumed: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };


  render() {
    const { isLoading, creditsConsumed } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Listă credite consumate" />

        <VouchersCreditsConsumedTable
          isLoading={isLoading}
          creditsConsumed={creditsConsumed}
        />
      </React.Fragment>
    );
  }
}
