import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select, Row, Col } from 'antd';
import _ from 'lodash';

class AddServiceRampForm extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    mechanicsList: PropTypes.array.isRequired
  };

  componentDidMount = () => {
    console.log(this.props.mechanicsList);
  };

  handleSaveServiceRamp = () => {
    const { validateFields } = this.props.form;

    validateFields((err, values) => {
      if (err) return;

      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="inline">
        <h4>Formular adăugare rampă nouă</h4>
        <Row type="flex" gutter={16} justify="space-between">
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
              label="Nume rampă"
            >
              {getFieldDecorator('name', {
                rules: [
                  { required: true, message: 'Introduceți numele rampei.' }
                ]
              })(<Input placeholder="Nume rampă" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
              label="Tip rampă"
            >
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Selectați tipul rampei.' }]
              })(
                <Select style={{ width: '100%' }} placeholder="Tip rampă">
                  <Select.Option value="MECANICA">Mecanică</Select.Option>
                  <Select.Option value="ELECTRICA">Electrică</Select.Option>
                  <Select.Option value="VOPSITORIE">Vopsitorie</Select.Option>
                  <Select.Option value="ITP">ITP</Select.Option>
                  <Select.Option value="TUNING">Tuning</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: '100%' }}
              label="Mecanic rampă"
            >
              {getFieldDecorator('mechanic', {
                rules: [
                  {
                    required: true,
                    message: 'Selectați mecanicul asociat rampei.'
                  }
                ]
              })(
                <Select style={{ width: '100%' }} placeholder="Mecanic rampă">
                  {_.map(this.props.mechanicsList, mechanic => (
                    <Select.Option key={mechanic.id} value={mechanic.id}>
                      {mechanic.fname} {mechanic.lname}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          gutter={32}
          style={{ marginTop: '8px' }}
        >
          <Col span={4}>
            <Button style={{ width: '100%' }} onClick={this.props.onCancel}>
              Renunță
            </Button>
          </Col>
          <Col span={4}>
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={this.handleSaveServiceRamp}
            >
              Salvează rampă nouă
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(AddServiceRampForm);
