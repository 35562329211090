import React, { Component } from 'react';
import VouchersTable from './VouchersTable';
import Helmet from 'react-helmet';
import { getVouchers, deleteVoucher, deleteVoucherChild } from '../../../api';
import NProgress from 'nprogress';
import voucherTypesText from "../../../utils/voucherTypesText";
import { Select, Row, Col, Button, message } from 'antd';
import _ from "lodash";


export default class ServicesList extends Component {
  state = {
    isLoading: false,
    vouchers: {},
    typeShown: 1
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getVouchers()
      .then(resp => {
        this.setState({
          vouchers: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  handleGoToAddVoucher = () => {
    const { history } = this.props;
    history.push('/vouchers/add');
  };

  handleChangeVoucherType = (type) => {
    this.setState({
      typeShown: type
    })
  }

  handleEditVoucherClick = voucherId => {
    const { typeShown } = this.state;
    const { history } = this.props;
    history.push(`/vouchers/${typeShown}/${voucherId}`);
  };

  handleDeleteVoucherClick = voucherId => {
    const { typeShown } = this.state;
    const { history } = this.props;

    deleteVoucher(typeShown, voucherId)
      .then(response => {
        message.success("Voucher șters cu succes!");
        history.push("/vouchers");
      })
      .catch(error => {
        message.error("Eroare.");
        console.log(error);
      });
  };

  handleDeleteVoucherChildClick = childId => {
    const { history } = this.props;

    deleteVoucherChild(childId)
      .then(response => {
        message.success("Voucher subordonat șters cu succes!");
        history.push("/vouchers");
      })
      .catch(error => {
        message.error("Eroare.");
        console.log(error);
      });
  };


  render() {
    const { isLoading, vouchers, typeShown } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Listă vouchere" />
        <Row
          type="flex"
          justify="space-between"
          gutter={24}
          style={{ marginBottom: 16 }}
        >
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <Select value={typeShown} style={{ width: '100%' }} onChange={this.handleChangeVoucherType}>
              {_.map(voucherTypesText, typeObject => (
                <Select.Option key={typeObject.id} value={typeObject.id}>{typeObject.text}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={4}
            xl={4}
            style={{ textAlign: 'right' }}
          >
            <Button
              icon="plus"
              style={{ width: '100%' }}
              onClick={this.handleGoToAddVoucher}
            >
              Adaugă
            </Button>
          </Col>
        </Row>
        <VouchersTable
          isLoading={isLoading}
          vouchers={vouchers}
          type={typeShown}
          onEditVoucherClick={this.handleEditVoucherClick}
          onDeleteVoucherClick={this.handleDeleteVoucherClick}
          onDeleteVoucherChildClick={this.handleDeleteVoucherChildClick}
        />
      </React.Fragment>
    );
  }
}
