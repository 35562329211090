import React, { Component } from "react";
import { Row, Col, Table, Button, Checkbox, Divider } from "antd";
import { getArticles } from "../../../api";

export default class NewsList extends Component {
  state = {
    articles: [],
    isLoading: false
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    getArticles()
      .then(response => {
        console.log(response.data);
        this.setState({ articles: response.data, isLoading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleGoToAddNewsItem = () => {
    const { history } = this.props;

    history.push("/news/add");
  };

  tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Autor",
      dataIndex: "author",
      key: "author"
    },
    {
      title: "Titlu",
      dataIndex: "title",
      key: "title"
    },
    {
      title: "Continut",
      key: "content",
      render: (text, record) => (
        <div>
          <span>{record.content.substring(0, 50) + "..."}</span>
        </div>
      )
    },
    {
      title: "Pin to top",
      key: "pinned",
      align: "center",
      render: (text, record) => <Checkbox checked={record.pinned} />
    },
    {
      title: "Importanță",
      key: "sort",
      dataIndex: "sort"
    },
    {
      title: "Acțiuni",
      key: "action",
      render: (text, record) => (
        <span>
          <span
            className={"a-link"}
            onClick={() => this.handleViewNewsItem(record.id)}
          >
            Vizualizează
          </span>
          <Divider type="vertical" />
          <span
            className={"a-link"}
            onClick={() => this.handleEditNewsItem(record)}
            style={{ color: "#FF851B" }}
          >
            Editează
          </span>
        </span>
      )
    }
  ];

  handleViewNewsItem = newsItemId => {
    const { history } = this.props;
    history.push(`/news/${newsItemId}`);
  };

  handleEditNewsItem = newsItem => {
    const { history } = this.props;
    history.push(`/news/edit/${newsItem.id}`, { newsItem });
  };

  render() {
    const { articles, isLoading } = this.state;

    return (
      <div style={{ padding: 8 }}>
        <Row type="flex" justify="end" style={{ marginBottom: "20px" }}>
          <Col>
            <Button
              icon="plus"
              type="primary"
              onClick={this.handleGoToAddNewsItem}
            >
              Adaugă noutate
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              bordered
              dataSource={articles}
              loading={isLoading}
              columns={this.tableColumns}
              scroll={{ x: 'max-content' }}
              rowKey="id"
              pagination={{ pageSize: 15 }}
              style={{ background: "white" }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
