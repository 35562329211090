import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Form, Input, Row, Col, Button, Transfer, message, Spin } from 'antd';
import { sendNotification, getServices } from '../../../api';
import _ from 'lodash';
import './NotificationsAdd.css';

class ServicesAdd extends Component {
  state = {
    sending: false,
    services: [],
    targetKeys: [],
    selectedKeys: []
  };

  componentDidMount = () => {
    getServices()
      .then(response => {
        this.setState({
          services: _.map(response.data, item => ({
            key: item.id,
            title: item.name,
            description: item.name,
            disabled: false
          }))
        });
      })
      .catch(error => console.error(error));
  };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys]
    });
  };

  handleSendNotification = () => {
    const { form, history } = this.props;

    form.validateFields((error, values) => {
      if (error) return;

      console.log(values);
      this.setState({ sending: true });
      sendNotification(values)
        .then(response => {
          console.log(response.data);
          message.success('Notificările au fost trimise cu succes!');
          history.push('/notifications/');
        })
        .catch(error => {
          console.error(error);
          message.error('A apărut o eroare la trimiterea notificărilor.');
          this.setState({ sending: false });
        });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { services, sending } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Adaugă un service nou" />
        <Spin spinning={sending}>
          <Row type="flex" justify="space-between">
            <Col>
              <h3>Trimite o notificare nouă</h3>
            </Col>
            <Col>
              <Button
                type="primary"
                icon="plus"
                onClick={this.handleSendNotification}
              >
                Trimite notificare
              </Button>
            </Col>
          </Row>
          <Form onSubmit={this.handleSendNotification}>
            <Form.Item label="De la" hasFeedback>
              {getFieldDecorator('from', {
                initialValue: 'Sistem', // TODO: Remove in production
                rules: [
                  {
                    required: true,
                    message: 'Campul `De la` este obligatoriu!'
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Mesaj">
              {getFieldDecorator('message', {
                initialValue: 'Mesaj notificare', // TODO: Remove in production
                rules: [
                  {
                    required: true,
                    message: 'Mesajul este obligatoriu!'
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Către" hasFeedback>
              {getFieldDecorator('to', {
                rules: [
                  {
                    required: true,
                    message: 'Campul `Către` este obligatoriu!'
                  }
                ]
              })(
                <Transfer
                  className="NotificationsAddTransfer"
                  dataSource={services}
                  titles={['Source', 'Target']}
                  targetKeys={this.state.targetKeys}
                  selectedKeys={this.state.selectedKeys}
                  onChange={this.handleChange}
                  onSelectChange={this.handleSelectChange}
                  onScroll={this.handleScroll}
                  render={item => item.title}
                />
              )}
            </Form.Item>
          </Form>
        </Spin>
      </React.Fragment>
    );
  }
}

export default Form.create()(ServicesAdd);
