import React, { Component } from 'react';
import { Button, Row, Form, Input, Alert } from 'antd';
import Helmet from 'react-helmet';
import logo from '../../images/logo.svg';
import './LoginPage.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { login, logout } from '../../ducks/auth';
import prettyError from '../../utils/prettyError';
import config from '../../config';

class LoginPage extends Component {
  state = {};

  componentDidMount = () => {
    const { auth, history, logout } = this.props;

    if (auth.authenticated) {
      history.push('/');
    } else {
      logout();
    }
  };

  static getDerivedStateFromProps = (props, state) => {
    const { auth, history } = props;
    if (!auth.isLoading && auth.authenticated) {
      history.push('/');
    }

    return null;
  };

  handleLogin = () => {
    const { form, login } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      login(values.email, values.password);
    });
  };

  render() {
    const { form, auth } = this.props;

    return (
      <div>
        <Helmet title="Autentificare" />
        <div className="MainContainer">
          <div className="LoginForm">
            <div className="Logo">
              <img alt="Autopia" src={logo} />
              <span style={{ color: '#00ccdf' }}>
                {config.application.APPLICATION_NAME}
              </span>
            </div>
            {auth.isError && (
              <div className="Errors">
                <Alert
                  message={prettyError(auth.error)}
                  type="error"
                  showIcon
                />
              </div>
            )}
            <Form>
              <Form.Item hasFeedback>
                {form.getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: 'Câmpul Email este obligatoriu.'
                    },
                    {
                      type: 'email',
                      message: 'Emailul trebuie să fie valid.'
                    }
                  ]
                })(
                  <Input
                    onPressEnter={this.handleLogin}
                    placeholder="Utilizator"
                  />
                )}
              </Form.Item>
              <Form.Item hasFeedback>
                {form.getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Câmpul Parolă este obligatoriu.'
                    },
                    {
                      min: 1,
                      message: 'Parola trebuie să conțină minim 1 caracter.'
                    },
                    {
                      max: 30,
                      message: 'Parola poate să conțină maxim 30 caractere.'
                    }
                  ]
                })(
                  <Input
                    type="password"
                    onPressEnter={this.handleLogin}
                    placeholder="Parolă"
                  />
                )}
              </Form.Item>
              <Row>
                <Button
                  type="primary"
                  onClick={this.handleLogin}
                  loading={auth.isLoading}
                >
                  Autentificare
                </Button>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default compose(
  Form.create(),
  connect(
    mapStateToProps,
    { login, logout }
  )
)(LoginPage);
