import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox, Divider, Button, Popconfirm } from 'antd';
import moment from 'moment';
import voucherTypesText from "../../../utils/voucherTypesText";
import _ from 'lodash';


const createTableColumns = (type, onEditVoucherClick, onDeleteVoucherClick) => {
  let tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Creat la',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
  ];

  if (type === 1 || type === 2 || type === 3 || type === 5) {
    tableColumns = tableColumns.concat([
      {
        title: 'Subtip',
        dataIndex: 'subType',
        key: 'subType',
        sorter: (a, b) => a.subType - b.subType,
        render: (text, record) => _.find((_.find(voucherTypesText, {id: type}).subTypes), {id: record.subType}).text
      }
    ])
  }

  if (type === 1 || type === 2 || type === 3 || type === 5 || type === 6) {
    tableColumns = tableColumns.concat([
      {
        title: 'Credite (suma în RON)',
        dataIndex: 'credits',
        key: 'credits',
        sorter: (a, b) => a.credits - b.credits,
        render: (text, record) => record.credits / 100
      }
    ])
  }

  if (type === 1 || type === 2 || type === 3){
    tableColumns = tableColumns.concat([
      {
        title: 'Nume',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name)
      },
      {
        title: 'Descriere',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.name.localeCompare(b.name)
      }
    ])
  }

  if (type === 1){
    tableColumns = tableColumns.concat([
      {
        title: 'Utilizatori maximi',
        dataIndex: 'maxUsers',
        key: 'maxUsers',
        sorter: (a, b) => a.maxUsers - b.maxUsers,
      }
    ])
  }

  if (type === 2) {
    tableColumns = tableColumns.concat([
      {
        title: 'Dată limită maximă',
        dataIndex: 'endTime',
        key: 'endTime',
        sorter: (a, b) => a.endTime.localeCompare(b.endTime),
        render: (text, record) => moment(record.endTime).format('DD.MM.YYYY HH:mm:ss')
      }
    ])
  }

  if (type === 3) {
    tableColumns = tableColumns.concat([
      {
        title: 'Service invitat',
        dataIndex: 'service',
        key: 'service',
        sorter: (a, b) => a.service - b.service,
      },
      {
        title: 'Nu lua în considerare procentul',
        dataIndex: 'bypassPercentage',
        key: 'bypassPercentage',
        sorter: (a, b) => (a.bypassPercentage === b.bypassPercentage) ? 0 : a.bypassPercentage ? 1 : -1,
        render: (text, record) => <Checkbox checked={record.bypassPercentage} />
      }
    ])
  }

  if (type === 6) {
    tableColumns = tableColumns.concat([
      {
        title: 'Service care a invitat userul',
        dataIndex: 'service',
        key: 'service',
        sorter: (a, b) => a.service - b.service,
      }
    ])
  }

  if (type === 1 || type === 2) {
    tableColumns = tableColumns.concat([
      {
        title: 'Stop temporar',
        dataIndex: 'stopNew',
        key: 'stopNew',
        sorter: (a, b) => (a.stopNew === b.stopNew) ? 0 : a.stopNew ? 1 : -1,
        render: (text, record) => <Checkbox checked={record.stopNew} />
      },
      {
        title: 'Cod voucher',
        dataIndex: 'creditsCode',
        key: 'creditsCode',
        sorter: (a, b) => a.creditsCode.localeCompare(b.creditsCode),
      }
    ])
  }

  if (type === 4){
    tableColumns = tableColumns.concat([
      {
        title: 'User care a dat',
        dataIndex: 'userFrom',
        key: 'userFrom',
        sorter: (a, b) => a.userFrom - b.userFrom,
      },
      {
        title: 'User care a primit',
        dataIndex: 'userTo',
        key: 'userTo',
        sorter: (a, b) => a.userTo - b.userTo,
      },
      {
        title: 'Credit către user care a dat',
        dataIndex: 'creditsFrom',
        key: 'creditsFrom',
        sorter: (a, b) => a.creditsFrom - b.creditsFrom,
        render: (text, record) => record.creditsFrom / 100

      },
      {
        title: 'Credit către user care a primit',
        dataIndex: 'creditsTo',
        key: 'creditsTo',
        sorter: (a, b) => a.creditsTo - b.creditsTo,
        render: (text, record) => record.creditsTo / 100
      }
    ])
  }

  if (type === 5){
    tableColumns = tableColumns.concat([
      {
        title: 'User prieten de la care a luat',
        dataIndex: 'friend',
        key: 'friend',
        sorter: (a, b) => (a.friend === null ? 0 : a.friend) - (b.friend === null ? 0 : b.friend)

      },
      {
        title: 'Operațiune pe baza căreia a primit',
        dataIndex: 'operation',
        key: 'operation',
        sorter: (a, b) => a.operation - b.operation,
      }
    ])
  }

  tableColumns = tableColumns.concat([
    {
      title: 'Șters la',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      sorter: (a, b) => (a.deletedAt === null ? '' : a.deletedAt).localeCompare(b.deletedAt === null ? '' : b.deletedAt),
      render: (text, record) => record.deletedAt ? moment(record.deletedAt).format('DD.MM.YYYY HH:mm:ss') : ''
    }
  ])

  tableColumns = tableColumns.concat([
    {
      title: 'Acțiuni',
      key: 'action',
      render: (text, record) => (
        record.deletedAt ? null : (
          <span>
            {(type === 1 || type === 2 || type === 3) ? (
              <React.Fragment>
                <Button
                  type={'link'}
                  onClick={() => onEditVoucherClick(record.id)}
                >
                  Modifică
                </Button>
              </React.Fragment>
            ) : null}
            <Divider type={'vertical'} />
            <Popconfirm
              title="Ești sigur că vrei să ștergi acest voucher?"
              onConfirm={() => onDeleteVoucherClick(record.id)}
            >
              <Button type={'danger'}>
                Șterge
              </Button>
            </Popconfirm>
          </span>
        )
      )
    }
  ])

  return tableColumns;
}

const expandedRowRender = (record, onDeleteVoucherChildClick) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Creat la',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'Utilizator',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Șters la',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      sorter: (a, b) => (a.deletedAt === null ? '' : a.deletedAt).localeCompare(b.deletedAt === null ? '' : b.deletedAt),
      render: (text, record) => record.deletedAt ? moment(record.deletedAt).format('DD.MM.YYYY HH:mm:ss') : ''
    },
    {
      title: 'Acțiuni',
      key: 'action',
      render: (text, record) => (
        record.deletedAt ? null : (
          <Popconfirm
            title="Ești sigur că vrei să ștergi această activare de voucher?"
            onConfirm={() => onDeleteVoucherChildClick(record.id)}
          >
            <Button type={'danger'}>
              Șterge
            </Button>
          </Popconfirm>
        )
      )
    }
  ]
  return <Table columns={columns} dataSource={record.creditsEnabled} rowKey="id" pagination={false} />;
}

const VouchersTable = ({
  vouchers,
  isLoading,
  type,
  onEditVoucherClick,
  onDeleteVoucherClick,
  onDeleteVoucherChildClick
}) => {
  
  const tableColumns = createTableColumns(type, onEditVoucherClick, onDeleteVoucherClick)
  return (
    <Table
      bordered
      dataSource={vouchers[type]}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 30 }}
      expandedRowRender={(record, index, indent, expanded) => expandedRowRender(record, onDeleteVoucherChildClick)}
    />
  );
};

VouchersTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  vouchers: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired,
  onEditVoucherClick: PropTypes.func.isRequired,
  onDeleteVoucherClick: PropTypes.func.isRequired,
  onDeleteVoucherChildClick: PropTypes.func.isRequired
};

export default VouchersTable;
