import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PartsAnalytics from './PartsAnalytics';

export default class PartsPage extends Component {
  render() {
    const { match } = this.props;

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Switch>
          <Redirect
            exact
            path={`${match.path}/`}
            to={`${match.path}/analytics`}
          />
          <Route path={`${match.path}/analytics`} component={PartsAnalytics} />
          <Redirect to={`${match.path}/analytics`} />
        </Switch>
      </div>
    );
  }
}
