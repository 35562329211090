import React, { Component } from 'react';
import VouchersCashbackPointsTable from './VouchersCashbackPointsTable';
import Helmet from 'react-helmet';
import { getVouchersCashbackPoints, deleteVoucherCashbackPoints } from '../../../api';
import NProgress from 'nprogress';
import { message } from 'antd';


export default class VouchersCashbackPointsList extends Component {
  state = {
    isLoading: false,
    vouchersCashbackPoints: [],
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getVouchersCashbackPoints()
      .then(resp => {
        this.setState({
          vouchersCashbackPoints: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  handleDeleteVoucherCashbackPointsClick = cashbackPointsId => {
    const { history } = this.props;

    deleteVoucherCashbackPoints(cashbackPointsId)
      .then(response => {
        message.success("Cashback points șterse cu succes!");
        history.push("/vouchers/cashbackpoints");
      })
      .catch(error => {
        message.error("Eroare.");
        console.log(error);
      });
  };


  render() {
    const { isLoading, vouchersCashbackPoints } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Listă vouchere cashback points" />

        <VouchersCashbackPointsTable
          isLoading={isLoading}
          vouchersCashbackPoints={vouchersCashbackPoints}
          onDeleteVoucherCashbackPointsClick={this.handleDeleteVoucherCashbackPointsClick}
        />
      </React.Fragment>
    );
  }
}
