import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import PartsCredentialsList from './PartsCredentialsList/PartsCredentialsList'
import PartsCredentialsAdd from './PartsCredentialsAdd/PartsCredentialsAdd'

export default class PartsCredentialsPage extends Component {
  render () {
    const { match } = this.props

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Switch>
          <Redirect exact path={`${match.path}/`} to={`${match.path}/list`} />
          <Route path={`${match.path}/list`} component={PartsCredentialsList} />
          <Route path={`${match.path}/add`} component={PartsCredentialsAdd} />
          <Redirect to={`${match.path}/list`} />
        </Switch>
      </div>
    )
  }
}
