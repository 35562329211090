const voucherTypesText = [
  {
    id: 1,
    text: 'Tip 1 - Maxim utilizatori',
    subTypes: [
      {
        id: 1,
        text: 'Subtip 1 - Maxim utilizatori doar useri noi'
      },
      {
        id: 2,
        text: 'Subtip 2 - Maxim utilizatori și useri noi și cei vechi'
      },
    ]
  },
  {
    id: 2,
    text: 'Tip 2 - Maxim timp',
    subTypes: [
      {
        id: 1,
        text: 'Subtip 1 - Maxim timp doar useri noi'
      },
      {
        id: 2,
        text: 'Subtip 2 - Maxim timp și useri noi și cei vechi'
      },
    ]
  },
  {
    id: 3,
    text: 'Tip 3 - Manuale, pentru un user',
    subTypes: [
      {
        id: 1,
        text: 'Subtip 1 - Manuale, pentru un user, simplu'
      },
      {
        id: 2,
        text: 'Subtip 2 - Manuale, pentru un user care a adus un service în platformă'
      },
    ]
  },
  {
    id: 4,
    text: 'Tip 4 - Între useri',
  },
  {
    id: 5,
    text: 'Tip 5 - MLM - Cashback',
    subTypes: [
      {
        id: 1,
        text: 'Subtip 1 - MLM - Cashback de la user însuși'
      },
      {
        id: 2,
        text: 'Subtip 2 - MLM - Cashback de la un prieten de al lui'
      },
    ]
  },
  {
    id: 6,
    text: 'Tip 6 - De la service oferit',
  }
]

export default voucherTypesText;