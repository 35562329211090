import { login as apiLogin } from '../api';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const initialState = {
  authenticated: false,
  token: null,
  userId: null,
  email: null,
  fname: null,
  lname: null,
  isLoading: false,
  isError: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return Object.assign({}, state, {
        isError: false,
        authenticated: false,
        error: null,
        isLoading: true
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        authenticated: true,
        token: action.payload.token,
        userId: action.payload.user.id,
        email: action.payload.user.email,
        fname: action.payload.user.fname,
        lname: action.payload.user.lname
      });

    case LOGIN_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        error: action.payload
      });
    case LOGOUT:
      return initialState;
    case REFRESH_TOKEN:
      return Object.assign({}, state, {
        token: action.payload
      });
    default:
      return state;
  }
};

export const login = (email, password) => {
  return dispatch => {
    dispatch({ type: LOGIN });
    return apiLogin({ email, password })
      .then(response => {
        if (response.data.user.userGroups.indexOf('ADMIN') > -1)
          dispatch(loginSuccess(response.data));
        else dispatch(loginError('INVALID_CREDENTIALS'));
      })
      .catch(error => {
        if (error.response)
          switch (error.response.status) {
            case 400:
              dispatch(loginError('BAD_REQUEST'));
              break;
            case 403:
              dispatch(loginError('INVALID_CREDENTIALS'));
              break;
            default:
              dispatch(loginError('SERVER_ERROR'));
          }
        else dispatch(loginError('SERVER_ERROR'));
      });
  };
};

export const loginSuccess = loginData => {
  return {
    type: LOGIN_SUCCESS,
    payload: loginData
  };
};

export const loginError = error => {
  return {
    type: LOGIN_ERROR,
    payload: error
  };
};

export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const refreshToken = token => {
  return {
    type: REFRESH_TOKEN,
    payload: token
  };
};
