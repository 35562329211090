import React, { Component } from 'react';
import { Row, Col, Input, Button } from 'antd';
import ServicesTable from './ServicesTable';
import Helmet from 'react-helmet';
import { getServiceAuthToken, getServices } from '../../../api';
import NProgress from 'nprogress';
import backend from '../../../config/backend';

export default class ServicesList extends Component {
  state = {
    isLoading: false,
    originalServices: [],
    filteredServices: [],
    nameFilter: null
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getServices()
      .then(resp => {
        this.setState({
          filteredServices: resp.data,
          originalServices: resp.data
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  handleGoToAddService = () => {
    const { history } = this.props;

    // TODO: Use relative paths
    history.push('/services/add');
  };

  handleGoToAddServiceBranch = () => {
    const { history } = this.props;

    // TODO: Use relative paths
    history.push('/services/addbranch');
  };

  handleSetNameFilter = e => {
    this.setState({ nameFilter: e }, () => {
      this.filterResults();
    });
  };

  filterResults = () => {
    const { originalServices, nameFilter } = this.state;
    this.setState({ isLoading: true });

    const servicesFilteredByName = originalServices.filter(
      service =>
        service.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1
    );

    this.setState({
      filteredServices: servicesFilteredByName,
      isLoading: false
    });
  };

  handleConfigureServiceClick = serviceId => {
    const { history } = this.props;
    history.push(`/services/${serviceId}`);
  };

  handleEditServiceCompanyClick = serviceId => {
    const { history } = this.props;
    history.push(`/services/company/${serviceId}`);
  };

  handleImpersonateServiceClick = serviceId => {
    getServiceAuthToken(serviceId)
      .then(response => {
        console.log(response);
        const { token } = response.data;
        const serviceLoginURL = `${backend.SERVICE_BASE_URL}/auth/${token}`;
        console.log(serviceLoginURL);
        window.open(serviceLoginURL);
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { isLoading, filteredServices } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Listă service" />
        <Row
          type="flex"
          justify="space-between"
          gutter={24}
          style={{ marginBottom: 16 }}
        >
          <Col xs={24} sm={12} md={8} lg={4} xl={4}>
            <Input.Search
              placeholder="Denumire"
              onSearch={this.handleSetNameFilter}
            />
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            style={{ textAlign: 'right' }}
          >
            <Button
              icon="plus"
              style={{ width: '50%' }}
              onClick={this.handleGoToAddServiceBranch}
            >
              Adaugă filială
            </Button>
            <Button
              icon="plus"
              style={{ width: '50%' }}
              onClick={this.handleGoToAddService}
            >
              Adaugă
            </Button>
          </Col>
        </Row>
        <ServicesTable
          isLoading={isLoading}
          services={filteredServices}
          onConfigureServiceClick={this.handleConfigureServiceClick}
          onImpersonateServiceClick={this.handleImpersonateServiceClick}
          onEditServiceCompanyClick={this.handleEditServiceCompanyClick}
        />
      </React.Fragment>
    );
  }
}
