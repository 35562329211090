import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';

const WebContactTable = ({
  webcontact,
  isLoading,
}) => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Data si ora (GMT)',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: createdAt => (
        moment.utc(createdAt).format('DD.MM.YYYY HH:mm:ss')
      )
    },
    {
      title: 'Nume',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Mesaj',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Origine',
      dataIndex: 'origin',
      key: 'origin',
    }
  ];

  return (
    <Table
      bordered
      dataSource={webcontact}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 15 }}
    />
  );
};

WebContactTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  webcontact: PropTypes.array.isRequired,
};

export default WebContactTable;
