import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ServicesList from './ServicesList/ServicesList';
import ServicesAdd from './ServicesAdd/ServicesAdd';
import ServicesAddBranch from './ServicesAddBranch/ServicesAddBranch';
import ServicesGoogleReview from './ServicesGoogleReview/ServicesGoogleReview';
import ServiceProfile from './ServiceProfile/ServiceProfile';
import ServiceCompanyProfile from './ServiceCompanyProfile/ServiceCompanyProfile';

export default class ServicesPage extends Component {
  render() {
    const { match } = this.props;

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Switch>
          <Redirect exact path={`${match.path}/`} to={`${match.path}/list`} />
          <Redirect exact path={`${match.path}/googlereview`} to={`${match.path}/googlereview/list`} />
          <Route path={`${match.path}/list`} component={ServicesList} />
          <Route path={`${match.path}/add`} component={ServicesAdd} />
          <Route path={`${match.path}/addbranch`} component={ServicesAddBranch} />
          <Route path={`${match.path}/googlereview/list`} component={ServicesGoogleReview} />
          <Route
            path={`${match.path}/company/:serviceId`}
            component={ServiceCompanyProfile}
          />
          <Route path={`${match.path}/:serviceId`} component={ServiceProfile} />
          <Redirect to={`${match.path}/list`} />
        </Switch>
      </div>
    );
  }
}
