import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import RoadAssistanceList from "./RoadAssistanceList/RoadAssistanceList";
import RoadAssistanceAdd from "./RoadAssistanceAdd/RoadAssistanceAdd";

export default class RoadAssitancePage extends Component {
  render() {
    const { match } = this.props;

    return (
      <div style={{ background: "#fff", padding: 24 }}>
        <Switch>
          <Redirect exact path={`${match.path}/`} to={`${match.path}/list`} />
          <Route path={`${match.path}/list`} component={RoadAssistanceList} />
          <Route path={`${match.path}/add`} component={RoadAssistanceAdd} />
          <Route
            path={`${match.path}/:roadAssistanceId`}
            component={RoadAssistanceAdd}
          />
          <Redirect to={`${match.path}/list`} />
        </Switch>
      </div>
    );
  }
}
