import React, { Component } from "react";
import Helmet from "react-helmet";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  InputNumber,
  Spin,
} from "antd";
import _ from "lodash";
import { getTutorial, createTutorial, updateTutorial } from "../../../api";
import "./styles.css";
import { connect } from 'react-redux';


class TutorialAddOrEdit extends Component {
  state = {
    isAdding: false,

    edit: false,
    currentTutorialId: null,
    isLoading: false,
  };

  componentDidMount = () => {
    this.loadTutorialInfo();
  };

  loadTutorialInfo = () => {
    const { history, match, form } = this.props;
    const { tutorialId } = match.params;

    if (tutorialId){
      if (!(_.isInteger(parseInt(tutorialId)) && !isNaN(parseInt(tutorialId)))) {
        message.error('Eroare la încărcare pentru editarea tutorialului, id greșit')
        history.push('/tutorial');
        return;
      }
      
      this.setState({
        edit: true,
        currentTutorialId: parseInt(tutorialId)
      })
      this.setState({ isLoading: true });
      getTutorial(tutorialId)
        .then(response => {
          this.setState({
            isLoading: false,
          })
          form.setFieldsValue({
            title: response.data.title,
            description: response.data.description,
            priority: response.data.priority,
            link: response.data.link,
          })
        })
        .catch(error => {
          message.error('Eroare la încărcare pentru editarea tutorialului')
          console.error(error);
          history.push('/tutorial');
        });
    }
  }

  validationToSave = (apiCall, apiCallArguments) => {
    const { form, history } = this.props;

    form.validateFields((err, newTutorialValues) => {
      if (err) return;

      const body = {
        title: newTutorialValues.title,
        description: newTutorialValues.description,
        priority: newTutorialValues.priority,
        link: newTutorialValues.link
      };

      apiCallArguments.push(body)

      this.setState({ isAdding: true });

      apiCall(...apiCallArguments)
        .then(response => {
          message.success("Operație efectuată cu succes!");
          history.push("/tutorial");
        })
        .catch(error => {
          this.setState({ isAdding: false });
          message.error("Verificați corectitudinea completării datelor.");
        });

    });
  }

  handleSaveNewTutorial = () => {
    this.validationToSave(createTutorial, [])
  };

  handleEditTutorial = () => {
    const { currentTutorialId } = this.state;
    this.validationToSave(updateTutorial, [currentTutorialId])
  };

  getYoutubeVideo = (content) => {   
    if (content) {
      //taken from client
      //do not use URLSearchParams because it is not available on client
      //we use the same code to not have any differences in parsing
      const splitted = _.split(content, '?')
      if (_.size(splitted) === 2) {
        const urlParams = _.split(splitted[1], '&')
        const arrayOfKv = _.map(urlParams, p => {
          const kv = _.split(p, '=')
          if (_.size(kv) === 2) {
            return [kv[0], kv[1]];
          }
          return null;
        })
        const idKv = _.find(arrayOfKv, kv => kv && kv[0] === 'v');
        const id = idKv ? idKv[1] : null;
        if (id) {
          return (
            <iframe width="80%" height="400" src={`https://www.youtube-nocookie.com/embed/${id}`} title="Autopia" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          )
        }
      }
    }
    return null;
  }


  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { isAdding, edit, isLoading } = this.state;

    return (
      <div className={"tutorial-add-page"}>
        <Helmet title={edit ? 'Modifică un tutorial' : 'Adaugă un tutorial'}/>
        <Row type="flex" justify="space-between" className={"section"}>
          <Col>
            <h3>{edit ? 'Modifică un tutorial' : 'Adaugă un tutorial'}</h3>
          </Col>
          <Col>
            <Button
              type="primary"
              icon="plus"
              onClick={edit ? this.handleEditTutorial : this.handleSaveNewTutorial}
              loading={isAdding}
            >
              {edit ? 'Salvează modificările' : 'Salvează si adaugă tutorial'}
            </Button>
          </Col>
        </Row>
        <Spin spinning={isLoading} tip="Se încarcă...">

          <Form>
            <h2>Date despre tutorial</h2>
            <Row type={"flex"} gutter={16} className={"section"}>
              <Col span={10}>
                <Form.Item label="Titlu" hasFeedback>
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        message: 'Titlul este obligatoriu'
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Descriere" hasFeedback>
                  {getFieldDecorator("description", {
                    initialValue: null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Prioritate (0 - cea mai mică)" hasFeedback>
                  {getFieldDecorator("priority", {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        message: 'Prioritatea este obligatorie'
                      }
                    ]
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
              <Col span={24}>
                <div style={{marginBottom: '2em'}} />
              </Col>
              <Col span={10}>
                <Form.Item label="Link Youtube" hasFeedback>
                  {getFieldDecorator("link", {
                    rules: [
                      {
                        required: true,
                        message: 'Titlul este obligatoriu'
                      }
                    ]
                  })(<Input placeholder="https://www.youtube.com/watch?v=qbXZZLa_y1U" />)}
                </Form.Item>                
                <p className="ant-upload-hint">
                  În principiu nu sunt probleme. Dacă merge aici, sigur va merge și în aplicația de service.
                </p>
              </Col>
              <Col span={14}>
                {this.getYoutubeVideo(getFieldValue('link'))}
              </Col>


            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, null)(Form.create()(TutorialAddOrEdit));
