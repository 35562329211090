const articleTypesText = [
  {
    id: 1,
    text: 'Tip 1 - Articol cu galerie de poze sus',
  },
  {
    id: 2,
    text: 'Tip 2 - Articol cu video sus',
  },
  {
    id: 3,
    text: 'Tip 3 - Articol doar cu video pe home',
  },
]

export default articleTypesText;