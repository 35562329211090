import React, { Component } from "react";
import { Row, Col, Table, Button } from "antd";
import { getRoadAssistanceServices } from "../../../api";

export default class RoadAssistanceList extends Component {
  state = {
    roadAssistanceServices: [],
    isLoading: false
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    getRoadAssistanceServices()
      .then(response => {
        console.log(response.data);
        this.setState({
          roadAssistanceServices: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleGoToAddRoadAssistance = () => {
    const { history } = this.props;

    history.push("/roadassistance/add");
  };

  tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Denumire",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: "Acțiuni",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            type={"link"}
            onClick={() => this.handleConfigureOnClick(record.id)}
          >
            Configurează
          </Button>
        </span>
      )
    }
  ];

  handleConfigureOnClick = roadAssistanceId => {
    const { history } = this.props;

    history.push(`/roadassistance/${roadAssistanceId}`);
  };

  render() {
    const { roadAssistanceServices, isLoading } = this.state;

    return (
      <div style={{ padding: 8 }}>
        <Row type="flex" justify="end" style={{ marginBottom: "20px" }}>
          <Col>
            <Button
              icon="plus"
              type="primary"
              onClick={this.handleGoToAddRoadAssistance}
            >
              Adaugă firmă tractare nouă
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              bordered
              dataSource={roadAssistanceServices}
              loading={isLoading}
              columns={this.tableColumns}
              scroll={{ x: 'max-content' }}
              rowKey="id"
              pagination={{ pageSize: 15 }}
              style={{ background: "white" }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
