import React from 'react';
import { getService } from '../../../api';
import _ from 'lodash';
import { Spin } from 'antd';
import { editServiceCompany, createNewInvoice } from '../../../api';
import ServiceCompanyInfo from './ServiceCompanyInfo';
import ServiceCompanyNetopiaCredentials from './ServiceCompanyNetopiaCredentials';
import ServiceCompanyInvoices from './ServiceCompanyInvoices';

class ServiceCompanyProfile extends React.Component {
  state = {
    isLoading: true,
    serviceCompany: {},
    invoices: []
  };

  componentDidMount() {
    this.loadServiceCompanyInfo();
  }

  loadServiceCompanyInfo = () => {
    const { match, history } = this.props;
    const { serviceId } = match.params;

    this.setState({ isLoading: true });
    getService(serviceId)
      .then(response => {
        const { serviceCompany, invoices } = response.data;

        const firstServiceCompany = _.first(serviceCompany);

        this.setState({
          serviceCompany: firstServiceCompany,
          invoices: invoices,
          isLoading: false
        });
      })
      .catch(error => {
        console.error(error);
        history.push('/');
      });
  };

  handleSaveChanges = newValues => {
    const { serviceCompany } = this.state;

    console.log('Old values', serviceCompany);
    console.log('New values', newValues);

    this.setState({ isLoading: true });
    editServiceCompany(serviceCompany.id, newValues)
      .then(result => {
        console.log(result);
        this.setState({ isLoading: false }, this.loadServiceCompanyInfo);
      })
      .catch(err => {
        console.error(err);
        this.setState({ isLoading: false });
      });
  };

  handleAddNewInvoice = newInvoice => {
    const { match } = this.props;
    const { serviceId } = match.params;

    console.log(newInvoice);
    this.setState({ isLoading: true });
    createNewInvoice(_.assign({}, newInvoice, { service: serviceId }))
      .then(result => {
        console.log(result);
        this.setState({ isLoading: false }, this.loadServiceCompanyInfo);
      })
      .catch(err => {
        console.error(err);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, serviceCompany, invoices } = this.state;

    return (
      <div>
        <Spin spinning={isLoading}>
          <ServiceCompanyInfo
            serviceCompany={serviceCompany}
            onSave={this.handleSaveChanges}
          />
          <ServiceCompanyNetopiaCredentials
            username={serviceCompany.username}
            password={serviceCompany.password}
          />
          <ServiceCompanyInvoices
            invoices={invoices}
            onAddNewInvoice={this.handleAddNewInvoice}
          />
        </Spin>
      </div>
    );
  }
}

export default ServiceCompanyProfile;
