import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox, Divider } from 'antd';
import moment from 'moment';
import _ from 'lodash';

const DatabaseSearchTable = ({
  data,
  isLoading,
}) => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Creat la (GMT)',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => (a.email ? a.email : '').localeCompare((b.email ? b.email : ''))
    },
    {
      title: 'Fname',
      dataIndex: 'fname',
      key: 'fname',
      sorter: (a, b) => (a.fname ? a.fname : '').localeCompare((b.fname ? b.fname : ''))
    },
    {
      title: 'Lname',
      dataIndex: 'lname',
      key: 'lname',
      sorter: (a, b) => (a.lname ? a.lname : '').localeCompare((b.lname ? b.lname : ''))
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => (a.phone ? a.phone : '').localeCompare((b.phone ? b.phone : ''))
    },
    {
      title: 'Poza',
      dataIndex: 'photo',
      key: 'photo',
      sorter: (a, b) => (a.photo ? a.photo : '').localeCompare((b.photo ? b.photo : ''))
    },
    {
      title: 'Cod voucher',
      dataIndex: 'creditsCode',
      key: 'creditsCode',
      sorter: (a, b) => (a.creditsCode ? a.creditsCode : '').localeCompare((b.creditsCode ? b.creditsCode : ''))
    },
    {
      title: 'A dat rating',
      dataIndex: 'hasRated',
      key: 'hasRated',
      sorter: (a, b) => (a.hasRated === b.hasRated) ? 0 : a.hasRated ? 1 : -1,
      render: (text, record) => <Checkbox checked={record.hasRated} />
    },
  ];

  const expandedRowRender = (record) => {
    const vehicleColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Creat la',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
      },
      {
        title: 'VIN',
        dataIndex: 'vin',
        key: 'vin',
        sorter: (a, b) => (a.vin ? a.vin : '').localeCompare((b.vin ? b.vin : ''))
      },
      {
        title: 'Număr de înmatriculare',
        dataIndex: 'registrationNumber',
        key: 'registrationNumber',
        sorter: (a, b) => (a.registrationNumber ? a.registrationNumber : '').localeCompare((b.registrationNumber ? b.registrationNumber : ''))
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
        sorter: (a, b) => ((a.brand && a.brand.name) ? a.brand.name : '').localeCompare(((b.brand && b.brand.name) ? b.brand.name : '')),
        render: (text, record) => (record.brand && record.brand.name) ? record.brand.name : ''
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        sorter: (a, b) => ((a.model && a.model.name) ? a.model.name : '').localeCompare(((b.model && b.model.name) ? b.model.name : '')),
        render: (text, record) => (record.model && record.model.name) ? record.model.name : ''
      },
      {
        title: 'Brand extra',
        dataIndex: 'brandExtra',
        key: 'brandExtra',
        sorter: (a, b) => (a.brandExtra ? a.brandExtra : '').localeCompare((b.brandExtra ? b.brandExtra : ''))
      },
      {
        title: 'Model extra',
        dataIndex: 'modelExtra',
        key: 'modelExtra',
        sorter: (a, b) => (a.modelExtra ? a.modelExtra : '').localeCompare((b.modelExtra ? b.modelExtra : ''))
      },
      {
        title: 'An',
        dataIndex: 'year',
        key: 'year',
        sorter: (a, b) => (a.year ? a.year : '').localeCompare((b.year ? b.year : ''))
      },
      {
        title: 'Dată expirare ITP',
        dataIndex: 'itpExpirationDate',
        key: 'itpExpirationDate',
        sorter: (a, b) => (a.itpExpirationDate === null ? '' : a.itpExpirationDate).localeCompare(b.itpExpirationDate === null ? '' : b.itpExpirationDate),
        render: (text, record) => record.itpExpirationDate ? moment.utc(record.itpExpirationDate).format('DD.MM.YYYY') : ''
      },
      {
        title: 'Dată expirare RCA',
        dataIndex: 'rcaExpirationDate',
        key: 'rcaExpirationDate',
        sorter: (a, b) => (a.rcaExpirationDate === null ? '' : a.rcaExpirationDate).localeCompare(b.rcaExpirationDate === null ? '' : b.rcaExpirationDate),
        render: (text, record) => record.rcaExpirationDate ? moment.utc(record.rcaExpirationDate).format('DD.MM.YYYY') : ''
      },
      {
        title: 'Dată expirare rovinietă',
        dataIndex: 'vignetteExpirationDate',
        key: 'vignetteExpirationDate',
        sorter: (a, b) => (a.vignetteExpirationDate === null ? '' : a.vignetteExpirationDate).localeCompare(b.vignetteExpirationDate === null ? '' : b.vignetteExpirationDate),
        render: (text, record) => record.vignetteExpirationDate ? moment.utc(record.vignetteExpirationDate).format('DD.MM.YYYY') : ''
      },
      {
        title: 'ITP online',
        dataIndex: 'itpExpirationOnline',
        key: 'itpExpirationOnline',
        sorter: (a, b) => (a.itpExpirationOnline === b.itpExpirationOnline) ? 0 : a.itpExpirationOnline ? 1 : -1,
        render: (text, record) => <Checkbox checked={record.itpExpirationOnline} />
      },
      {
        title: 'RCA online',
        dataIndex: 'rcaExpirationOnline',
        key: 'rcaExpirationOnline',
        sorter: (a, b) => (a.rcaExpirationOnline === b.rcaExpirationOnline) ? 0 : a.rcaExpirationOnline ? 1 : -1,
        render: (text, record) => <Checkbox checked={record.rcaExpirationOnline} />
      },
      {
        title: 'Rovinietă online',
        dataIndex: 'vignetteExpirationOnline',
        key: 'vignetteExpirationOnline',
        sorter: (a, b) => (a.vignetteExpirationOnline === b.vignetteExpirationOnline) ? 0 : a.vignetteExpirationOnline ? 1 : -1,
        render: (text, record) => <Checkbox checked={record.vignetteExpirationOnline} />
      },
      {
        title: 'RCA locked',
        dataIndex: 'rcaLocked',
        key: 'rcaLocked',
        sorter: (a, b) => (a.rcaLocked === b.rcaLocked) ? 0 : a.rcaLocked ? 1 : -1,
        render: (text, record) => <Checkbox checked={record.rcaLocked} />
      },
      {
        title: 'RCA de la Autopia',
        dataIndex: 'rcaFromAutopia',
        key: 'rcaFromAutopia',
        sorter: (a, b) => (a.rcaFromAutopia === b.rcaFromAutopia) ? 0 : a.rcaFromAutopia ? 1 : -1,
        render: (text, record) => <Checkbox checked={record.rcaFromAutopia} />
      },
      {
        title: 'Dată expirare revizie',
        dataIndex: 'nextRevisionDate',
        key: 'nextRevisionDate',
        sorter: (a, b) => (a.nextRevisionDate === null ? '' : a.nextRevisionDate).localeCompare(b.nextRevisionDate === null ? '' : b.nextRevisionDate),
        render: (text, record) => record.nextRevisionDate ? moment.utc(record.nextRevisionDate).format('DD.MM.YYYY HH:mm:ss') : ''
      },
      {
        title: 'Cod Autopia',
        dataIndex: 'externalId',
        key: 'externalId',
        sorter: (a, b) => (a.externalId ? a.externalId : '').localeCompare((b.externalId ? b.externalId : ''))
      },
      {
        title: 'Poză',
        dataIndex: 'photo',
        key: 'photo',
        sorter: (a, b) => (a.photo ? a.photo : '').localeCompare((b.photo ? b.photo : ''))
      },
      {
        title: 'Kilometraj',
        dataIndex: 'mileage',
        key: 'mileage',
        sorter: (a, b) => (a.mileage ? a.mileage : '').localeCompare((b.mileage ? b.mileage : ''))
      },
      {
        title: 'Kilometraj revizie',
        dataIndex: 'nextRevisionMileage',
        key: 'nextRevisionMileage',
        sorter: (a, b) => (a.nextRevisionMileage ? a.nextRevisionMileage : '').localeCompare((b.nextRevisionMileage ? b.nextRevisionMileage : ''))
      },
      {
        title: 'Nume companie asigurări',
        dataIndex: 'insuranceCompanyName',
        key: 'insuranceCompanyName',
        sorter: (a, b) => (a.insuranceCompanyName ? a.insuranceCompanyName : '').localeCompare((b.insuranceCompanyName ? b.modelExtra : ''))
      },
      {
        title: 'Șters la',
        dataIndex: 'deletedAt',
        key: 'deletedAt',
        sorter: (a, b) => (a.deletedAt === null ? '' : a.deletedAt).localeCompare(b.deletedAt === null ? '' : b.deletedAt),
        render: (text, record) => record.deletedAt ? moment(record.deletedAt).format('DD.MM.YYYY HH:mm:ss') : ''
      },
      {
        title: 'Tip',
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => ((a.type && a.type.name) ? a.type.name : '').localeCompare(((b.type && b.type.name) ? b.type.name : '')),
        render: (text, record) => (record.type && record.type.name) ? record.type.name : ''
      },
      {
        title: 'Masă',
        dataIndex: 'mass',
        key: 'mass',
        sorter: (a, b) => (a.mass ? a.mass : '').localeCompare((b.mass ? b.mass : ''))
      },
      {
        title: 'Capacitate',
        dataIndex: 'capacity',
        key: 'capacity',
        sorter: (a, b) => (a.capacity ? a.capacity : '').localeCompare((b.capacity ? b.capacity : ''))
      },
      {
        title: 'Putere',
        dataIndex: 'power',
        key: 'power',
        sorter: (a, b) => (a.power ? a.power : '').localeCompare((b.power ? b.power : ''))
      },
      {
        title: 'Combustibil',
        dataIndex: 'fuel',
        key: 'fuel',
        sorter: (a, b) => ((a.fuel && a.fuel.name) ? a.fuel.name : '').localeCompare(((b.fuel && b.fuel.name) ? b.fuel.name : '')),
        render: (text, record) => (record.fuel && record.fuel.name) ? record.fuel.name : ''
      },
      {
        title: 'Locuri',
        dataIndex: 'seats',
        key: 'seats',
        sorter: (a, b) => (a.seats ? a.seats : '').localeCompare((b.seats ? b.seats : ''))
      },
      {
        title: 'CIV',
        dataIndex: 'civ',
        key: 'civ',
        sorter: (a, b) => (a.civ ? a.civ : '').localeCompare((b.civ ? b.civ : ''))
      },
      {
        title: 'Expirări custom',
        dataIndex: 'customExpire',
        key: 'customExpire',
        sorter: (a, b) => _.size(a.customExpire) - _.size(b.customExpire),
        render: (text, record) => _.map(record.customExpire, r => `${r.deletedAt ? '(DELETED)' : ''}${r.name} ${moment.utc(r.expirationDate).format('DD.MM.YYYY')}`).join(' // ')
      },
    ]

    const rcaColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Creat la',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
      },
      {
        title: 'Updatat la',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
        render: (text, record) => moment(record.updatedAt).format('DD.MM.YYYY HH:mm:ss')
      },
      {
        title: 'pas',
        dataIndex: 'step',
        key: 'step',
        sorter: (a, b) => (a.step ? a.step : '').localeCompare((b.step ? b.step : ''))
      },
      {
        title: 'Șters la',
        dataIndex: 'deletedAt',
        key: 'deletedAt',
        sorter: (a, b) => (a.deletedAt === null ? '' : a.deletedAt).localeCompare(b.deletedAt === null ? '' : b.deletedAt),
        render: (text, record) => record.deletedAt ? moment(record.deletedAt).format('DD.MM.YYYY HH:mm:ss') : ''
      },
      {
        title: 'Persoană juridică',
        dataIndex: 'ownerLegalPerson',
        key: 'ownerLegalPerson',
        sorter: (a, b) => (a.ownerLegalPerson === b.ownerLegalPerson) ? 0 : a.ownerLegalPerson ? 1 : -1,
        render: (text, record) => <Checkbox checked={record.ownerLegalPerson} />
      },
      {
        title: 'Nume companie',
        dataIndex: 'ownerCompanyName',
        key: 'ownerCompanyName',
        sorter: (a, b) => (a.ownerCompanyName ? a.ownerCompanyName : '').localeCompare((b.ownerCompanyName ? b.ownerCompanyName : ''))
      },
      {
        title: 'CIF',
        dataIndex: 'ownerCif',
        key: 'ownerCif',
        sorter: (a, b) => (a.ownerCif ? a.ownerCif : '').localeCompare((b.ownerCif ? b.ownerCif : ''))
      },
      {
        title: 'CAEN',
        dataIndex: 'ownerCaen',
        key: 'ownerCaen',
        sorter: (a, b) => ((a.ownerCaen && a.ownerCaen.name) ? a.ownerCaen.name : '').toString().localeCompare(((b.ownerCaen && b.ownerCaen.name) ? b.ownerCaen.name : '').toString()),
        render: (text, record) => (record.ownerCaen && record.ownerCaen.name) ? record.ownerCaen.name : ''
      },
      {
        title: 'User',
        children: [
          {
            title: 'Nume familie',
            dataIndex: 'nameFamily',
            key: 'nameFamily',
            sorter: (a, b) => ((a.owner && a.owner.nameFamily) ? a.owner.nameFamily : '').localeCompare(((b.owner && b.owner.nameFamily) ? b.owner.nameFamily : '')),
            render: (text, record) => (record.owner && record.owner.nameFamily) ? record.owner.nameFamily : ''
          },
          {
            title: 'Nume propriu',
            dataIndex: 'nameGiven',
            key: 'nameGiven',
            sorter: (a, b) => ((a.owner && a.owner.nameGiven) ? a.owner.nameGiven : '').localeCompare(((b.owner && b.owner.nameGiven) ? b.owner.nameGiven : '')),
            render: (text, record) => (record.owner && record.owner.nameGiven) ? record.owner.nameGiven : ''
          },
          {
            title: 'CNP',
            dataIndex: 'cnp',
            key: 'cnp',
            sorter: (a, b) => ((a.owner && a.owner.cnp) ? a.owner.cnp : '').localeCompare(((b.owner && b.owner.cnp) ? b.owner.cnp : '')),
            render: (text, record) => (record.owner && record.owner.cnp) ? record.owner.cnp : ''
          },
          {
            title: 'An permis',
            dataIndex: 'yearDrivingLicense',
            key: 'yearDrivingLicense',
            sorter: (a, b) => ((a.owner && a.owner.yearDrivingLicense) ? a.owner.yearDrivingLicense : '').localeCompare(((b.owner && b.owner.yearDrivingLicense) ? b.owner.yearDrivingLicense : '')),
            render: (text, record) => (record.owner && record.owner.yearDrivingLicense) ? record.owner.yearDrivingLicense : ''
          },
          {
            title: 'Serie buletin',
            dataIndex: 'identityCardLetters',
            key: 'identityCardLetters',
            sorter: (a, b) => ((a.owner && a.owner.identityCardLetters) ? a.owner.identityCardLetters : '').localeCompare(((b.owner && b.owner.identityCardLetters) ? b.owner.identityCardLetters : '')),
            render: (text, record) => (record.owner && record.owner.identityCardLetters) ? record.owner.identityCardLetters : ''
          },
          {
            title: 'Număr buletin',
            dataIndex: 'identityCardNumbers',
            key: 'identityCardNumbers',
            sorter: (a, b) => ((a.owner && a.owner.identityCardNumbers) ? a.owner.identityCardNumbers : '').localeCompare(((b.owner && b.owner.identityCardNumbers) ? b.owner.identityCardNumbers : '')),
            render: (text, record) => (record.owner && record.owner.identityCardNumbers) ? record.owner.identityCardNumbers : ''
          }
        ]
      },
      {
        title: 'Adresă',
        children: [
          {
            title: 'Județ',
            dataIndex: 'addressCounty',
            key: 'addressCounty',
            sorter: (a, b) => ((a.addressCounty && a.addressCounty.name) ? a.addressCounty.name : '').localeCompare(((b.addressCounty && b.addressCounty.name) ? b.addressCounty.name : '')),
            render: (text, record) => (record.addressCounty && record.addressCounty.name) ? record.addressCounty.name : ''

          },
          {
            title: 'Oraș',
            dataIndex: 'addressTown',
            key: 'addressTown',
            sorter: (a, b) => ((a.addressTown && a.addressTown.name) ? a.addressTown.name : '').localeCompare(((b.addressTown && b.addressTown.name) ? b.addressTown.name : '')),
            render: (text, record) => (record.addressTown && record.addressTown.name) ? record.addressTown.name : ''
          },
          {
            title: 'Stradă',
            dataIndex: 'addressStreet',
            key: 'addressStreet',
            sorter: (a, b) => (a.addressStreet ? a.addressStreet : '').localeCompare((b.addressStreet ? b.addressStreet : ''))
          },
          {
            title: 'Număr',
            dataIndex: 'addressNumber',
            key: 'addressNumber',
            sorter: (a, b) => (a.addressNumber ? a.addressNumber : '').localeCompare((b.addressNumber ? b.addressNumber : ''))
          },
          {
            title: 'Bloc',
            dataIndex: 'addressBlock',
            key: 'addressBlock',
            sorter: (a, b) => (a.addressBlock ? a.addressBlock : '').localeCompare((b.addressBlock ? b.addressBlock : ''))
          },
          {
            title: 'Scară',
            dataIndex: 'addressStaircase',
            key: 'addressStaircase',
            sorter: (a, b) => (a.addressStaircase ? a.addressStaircase : '').localeCompare((b.addressStaircase ? b.addressStaircase : ''))
          },
          {
            title: 'Etaj',
            dataIndex: 'addressFloor',
            key: 'addressFloor',
            sorter: (a, b) => (a.addressFloor ? a.addressFloor : '').localeCompare((b.addressFloor ? b.addressFloor : ''))
          },
          {
            title: 'Apartament',
            dataIndex: 'addressApartment',
            key: 'addressApartment',
            sorter: (a, b) => (a.addressApartment ? a.addressApartment : '').localeCompare((b.addressApartment ? b.addressApartment : ''))
          }
        ]
      },
      {
        title: 'Consultanță',
        dataIndex: 'consultancy',
        key: 'consultancy',
        sorter: (a, b) => (a.consultancy === b.consultancy) ? 0 : a.consultancy ? 1 : -1,
        render: (text, record) => record.consultancy === null ? null : <Checkbox checked={record.consultancy} />
      },
      {
        title: 'CASCO?',
        dataIndex: 'consultancyCasco',
        key: 'consultancyCasco',
        sorter: (a, b) => (a.consultancyCasco === b.consultancyCasco) ? 0 : a.consultancyCasco ? 1 : -1,
        render: (text, record) => record.consultancyCasco === null ? null : <Checkbox checked={record.consultancyCasco} />
      },
      {
        title: 'Alte țări?',
        dataIndex: 'consultancyOtherCountries',
        key: 'consultancyOtherCountries',
        sorter: (a, b) => (a.consultancyOtherCountries === b.consultancyOtherCountries) ? 0 : a.consultancyOtherCountries ? 1 : -1,
        render: (text, record) => record.consultancyOtherCountries === null ? null : <Checkbox checked={record.consultancyOtherCountries} />
      },
      {
        title: 'Prima înmatriculare',
        dataIndex: 'firstRegistration',
        key: 'firstRegistration',
        sorter: (a, b) => (a.firstRegistration === b.firstRegistration) ? 0 : a.firstRegistration ? 1 : -1,
        render: (text, record) => record.firstRegistration === null ? null : <Checkbox checked={record.firstRegistration} />
      },
      {
        title: 'Vehicul',
        children: [
          {
            title: 'Id vehicul asociat',
            dataIndex: 'vehicle',
            key: 'vehicle',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.vehicle) ? a.vehicleData.vehicle : '').localeCompare(((b.vehicleData && b.vehicleData.vehicle) ? b.vehicleData.vehicle : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.vehicle) ? record.vehicleData.vehicle : ''
          },
          {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.vin) ? a.vehicleData.vin : '').localeCompare(((b.vehicleData && b.vehicleData.vin) ? b.vehicleData.vin : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.vin) ? record.vehicleData.vin : ''
          },
          {
            title: 'Număr înmatriculare',
            dataIndex: 'registrationNumber',
            key: 'registrationNumber',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.registrationNumber) ? a.vehicleData.registrationNumber : '').localeCompare(((b.vehicleData && b.vehicleData.registrationNumber) ? b.vehicleData.registrationNumber : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.registrationNumber) ? record.vehicleData.registrationNumber : ''
          },
          {
            title: 'Tip',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.type) ? a.vehicleData.type : '').toString().localeCompare(((b.vehicleData && b.vehicleData.type) ? b.vehicleData.type : '').toString()),
            render: (text, record) => (record.vehicleData && record.vehicleData.type) ? record.vehicleData.type : ''          
          },
          {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.brand) ? a.vehicleData.brand : '').toString().localeCompare(((b.vehicleData && b.vehicleData.brand) ? b.vehicleData.brand : '').toString()),
            render: (text, record) => (record.vehicleData && record.vehicleData.brand) ? record.vehicleData.brand : ''          
          },
          {
            title: 'Brand extra',
            dataIndex: 'brandExtra',
            key: 'brandExtra',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.brandExtra) ? a.vehicleData.brandExtra : '').localeCompare(((b.vehicleData && b.vehicleData.brandExtra) ? b.vehicleData.brandExtra : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.brandExtra) ? record.vehicleData.brandExtra : ''          
          },
          {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.model) ? a.vehicleData.model : '').toString().localeCompare(((b.vehicleData && b.vehicleData.model) ? b.vehicleData.model : '').toString()),
            render: (text, record) => (record.vehicleData && record.vehicleData.model) ? record.vehicleData.model : ''          
          },
          {
            title: 'Model extra',
            dataIndex: 'modelExtra',
            key: 'modelExtra',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.modelExtra) ? a.vehicleData.modelExtra : '').localeCompare(((b.vehicleData && b.vehicleData.modelExtra) ? b.vehicleData.modelExtra : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.modelExtra) ? record.vehicleData.modelExtra : ''          
          },
          {
            title: 'An',
            dataIndex: 'year',
            key: 'year',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.year) ? a.vehicleData.year : '').localeCompare(((b.vehicleData && b.vehicleData.year) ? b.vehicleData.year : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.year) ? record.vehicleData.year : ''          
          },
          {
            title: 'Masă',
            dataIndex: 'mass',
            key: 'mass',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.mass) ? a.vehicleData.mass : '').localeCompare(((b.vehicleData && b.vehicleData.mass) ? b.vehicleData.mass : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.mass) ? record.vehicleData.mass : ''          
          },
          {
            title: 'Capacitate',
            dataIndex: 'capacity',
            key: 'capacity',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.capacity) ? a.vehicleData.capacity : '').localeCompare(((b.vehicleData && b.vehicleData.capacity) ? b.vehicleData.capacity : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.capacity) ? record.vehicleData.capacity : ''          
          },
          {
            title: 'Putere',
            dataIndex: 'power',
            key: 'power',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.power) ? a.vehicleData.power : '').localeCompare(((b.vehicleData && b.vehicleData.power) ? b.vehicleData.power : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.power) ? record.vehicleData.power : ''          
          },
          {
            title: 'Combustibil',
            dataIndex: 'fuel',
            key: 'fuel',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.fuel) ? a.vehicleData.fuel : '').toString().localeCompare(((b.vehicleData && b.vehicleData.fuel) ? b.vehicleData.fuel : '').toString()),
            render: (text, record) => (record.vehicleData && record.vehicleData.fuel) ? record.vehicleData.fuel : ''          
          },
          {
            title: 'Locuri',
            dataIndex: 'seats',
            key: 'seats',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.seats) ? a.vehicleData.seats : '').localeCompare(((b.vehicleData && b.vehicleData.seats) ? b.vehicleData.seats : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.seats) ? record.vehicleData.seats : ''          
          },
          {
            title: 'CIV',
            dataIndex: 'civ',
            key: 'civ',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.civ) ? a.vehicleData.civ : '').localeCompare(((b.vehicleData && b.vehicleData.civ) ? b.vehicleData.civ : '')),
            render: (text, record) => (record.vehicleData && record.vehicleData.civ) ? record.vehicleData.civ : ''          
          },
          {
            title: 'Folosire',
            dataIndex: 'use',
            key: 'use',
            sorter: (a, b) => ((a.vehicleData && a.vehicleData.use) ? a.vehicleData.use : '').toString().localeCompare(((b.vehicleData && b.vehicleData.use) ? b.vehicleData.use : '').toString()),
            render: (text, record) => (record.vehicleData && record.vehicleData.use) ? record.vehicleData.use : ''
          }
        ]
      },
      {
        title: 'Dată de start',
        dataIndex: 'startDate',
        key: 'startDate',
        sorter: (a, b) => a.startDate.localeCompare(b.startDate),
        render: (text, record) => record.startDate ? moment.utc(record.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : null

      },
      {
        title: 'Vechea dată de expirare',
        dataIndex: 'oldExpirationDateBeforeDoing',
        key: 'oldExpirationDateBeforeDoing',
        sorter: (a, b) => a.oldExpirationDateBeforeDoing.localeCompare(b.oldExpirationDateBeforeDoing),
        render: (text, record) => record.oldExpirationDateBeforeDoing ? moment.utc(record.oldExpirationDateBeforeDoing, 'YYYY-MM-DD').format('DD.MM.YYYY') : null
      },
      {
        title: 'Răspuns de la ei la salvarea ofertei',
        children: [
          {
            title: 'MesajEroare',
            dataIndex: 'MesajEroare',
            key: 'MesajEroare',
            sorter: (a, b) => ((a.rcaQuoteSave && a.rcaQuoteSave.MesajEroare) ? a.rcaQuoteSave.MesajEroare : '').localeCompare(((b.rcaQuoteSave && b.rcaQuoteSave.MesajEroare) ? b.rcaQuoteSave.MesajEroare : '')),
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.MesajEroare) ? record.rcaQuoteSave.MesajEroare : ''  
          },
          {
            title: 'EmailAgent',
            dataIndex: 'EmailAgent',
            key: 'EmailAgent',
            sorter: (a, b) => ((a.rcaQuoteSave && a.rcaQuoteSave.EmailAgent) ? a.rcaQuoteSave.EmailAgent : '').localeCompare(((b.rcaQuoteSave && b.rcaQuoteSave.EmailAgent) ? b.rcaQuoteSave.EmailAgent : '')),
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.EmailAgent) ? record.rcaQuoteSave.EmailAgent : ''
          },
          {
            title: 'IdOferta',
            dataIndex: 'IdOferta',
            key: 'IdOferta',
            sorter: (a, b) => ((a.rcaQuoteSave && a.rcaQuoteSave.IdOferta) ? a.rcaQuoteSave.IdOferta : '').localeCompare(((b.rcaQuoteSave && b.rcaQuoteSave.IdOferta) ? b.rcaQuoteSave.IdOferta : '')),
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.IdOferta) ? record.rcaQuoteSave.IdOferta : ''  
          },
          {
            title: 'Prima',
            dataIndex: 'Prima',
            key: 'Prima',
            sorter: (a, b) => ((a.rcaQuoteSave && a.rcaQuoteSave.Prima) ? a.rcaQuoteSave.Prima : '').localeCompare(((b.rcaQuoteSave && b.rcaQuoteSave.Prima) ? b.rcaQuoteSave.Prima : '')),
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.Prima) ? record.rcaQuoteSave.Prima : ''  
          },
          {
            title: 'Asigurator',
            dataIndex: 'Asigurator',
            key: 'Asigurator',
            sorter: (a, b) => ((a.rcaQuoteSave && a.rcaQuoteSave.Asigurator) ? a.rcaQuoteSave.Asigurator : '').localeCompare(((b.rcaQuoteSave && b.rcaQuoteSave.Asigurator) ? b.rcaQuoteSave.Asigurator : '')),
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.Asigurator) ? record.rcaQuoteSave.Asigurator : ''  
          },
          {
            title: 'ProcentComision',
            dataIndex: 'ProcentComision',
            key: 'ProcentComision',
            sorter: (a, b) => ((a.rcaQuoteSave && a.rcaQuoteSave.ProcentComision) ? a.rcaQuoteSave.ProcentComision : '').localeCompare(((b.rcaQuoteSave && b.rcaQuoteSave.ProcentComision) ? b.rcaQuoteSave.ProcentComision : '')),
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.ProcentComision) ? record.rcaQuoteSave.ProcentComision : ''  
          },
          {
            title: 'ValabilitateNumarLuni',
            dataIndex: 'ValabilitateNumarLuni',
            key: 'ValabilitateNumarLuni',
            sorter: (a, b) => ((a.rcaQuoteSave && a.rcaQuoteSave.ValabilitateNumarLuni) ? a.rcaQuoteSave.ValabilitateNumarLuni : '').localeCompare(((b.rcaQuoteSave && b.rcaQuoteSave.ValabilitateNumarLuni) ? b.rcaQuoteSave.ValabilitateNumarLuni : '')),
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.ValabilitateNumarLuni) ? record.rcaQuoteSave.ValabilitateNumarLuni : ''  
          },
          {
            title: 'ClasaBonusMalus',
            dataIndex: 'ClasaBonusMalus',
            key: 'ClasaBonusMalus',
            sorter: (a, b) => ((a.rcaQuoteSave && a.rcaQuoteSave.ClasaBonusMalus) ? a.rcaQuoteSave.ClasaBonusMalus : '').localeCompare(((b.rcaQuoteSave && b.rcaQuoteSave.ClasaBonusMalus) ? b.rcaQuoteSave.ClasaBonusMalus : '')),
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.ClasaBonusMalus) ? record.rcaQuoteSave.ClasaBonusMalus : ''  
          },
          {
            title: 'Cu decontare',
            dataIndex: 'special',
            key: 'special2',
            render: (text, record) => (record.rcaQuoteSave && record.rcaQuoteSave.special) ? <Checkbox checked={record.firstRegistration} /> : null
          }
        ]
      },
      {
        title: 'Total de plată',
        dataIndex: 'totalToPay',
        key: 'totalToPay',
        sorter: (a, b) => parseFloat(a.totalToPay ? a.totalToPay : 0) - parseFloat(b.totalToPay ? b.totalToPay : 0)
      },
      {
        title: 'Număr de luni',
        dataIndex: 'period',
        key: 'period',
        sorter: (a, b) => parseInt(a.period ? a.period : 0) - parseInt(b.period ? b.period : 0)
      },
      {
        title: 'Decontare directă',
        dataIndex: 'special',
        key: 'special',
        sorter: (a, b) => (a.special === b.special) ? 0 : a.special ? 1 : -1,
        render: (text, record) => record.special === null ? null : <Checkbox checked={record.special} />      
      },
      {
        title: 'Asigurator',
        dataIndex: 'insurer',
        key: 'insurer',
        sorter: (a, b) => (a.insurer ? a.insurer : '').localeCompare((b.insurer ? b.insurer : ''))
      },
      {
        title: 'Detalii livrare',
        children: [
          {
            title: 'Nume livrare rapidă',
            dataIndex: 'deliveryFastName',
            key: 'deliveryFastName',
            sorter: (a, b) => (a.deliveryFastName ? a.deliveryFastName : '').localeCompare((b.deliveryFastName ? b.deliveryFastName : ''))
          },
          {
            title: 'Preț livrare rapidă',
            dataIndex: 'deliveryFastPrice',
            key: 'deliveryFastPrice',
            sorter: (a, b) => parseFloat(a.deliveryFastPrice ? a.deliveryFastPrice : 0) - parseFloat(b.deliveryFastPrice ? b.deliveryFastPrice : 0)
          },
          {
            title: 'Livrare fizică',
            dataIndex: 'deliverPhysically',
            key: 'deliverPhysically',
            sorter: (a, b) => (a.deliverPhysically === b.deliverPhysically) ? 0 : a.deliverPhysically ? 1 : -1,
            render: (text, record) => record.deliverPhysically === null ? null : <Checkbox checked={record.deliverPhysically} /> 
          },
          {
            title: 'Email',
            dataIndex: 'deliveryEmail',
            key: 'deliveryEmail',
            sorter: (a, b) => (a.deliveryEmail ? a.deliveryEmail : '').localeCompare((b.deliveryEmail ? b.deliveryEmail : ''))
          },
          {
            title: 'Stradă',
            dataIndex: 'deliveryStreet',
            key: 'deliveryStreet',
            sorter: (a, b) => (a.deliveryStreet ? a.deliveryStreet : '').localeCompare((b.deliveryStreet ? b.deliveryStreet : ''))
          },
          {
            title: 'Număr',
            dataIndex: 'deliveryNumber',
            key: 'deliveryNumber',
            sorter: (a, b) => (a.deliveryNumber ? a.deliveryNumber : '').localeCompare((b.deliveryNumber ? b.deliveryNumber : ''))
          },
          {
            title: 'Bloc',
            dataIndex: 'deliveryBlock',
            key: 'deliveryBlock',
            sorter: (a, b) => (a.deliveryBlock ? a.deliveryBlock : '').localeCompare((b.deliveryBlock ? b.deliveryBlock : ''))
          },
          {
            title: 'Scară',
            dataIndex: 'deliveryStaircase',
            key: 'deliveryStaircase',
            sorter: (a, b) => (a.deliveryStaircase ? a.deliveryStaircase : '').localeCompare((b.deliveryStaircase ? b.deliveryStaircase : ''))
          },
          {
            title: 'Apartament',
            dataIndex: 'deliveryApartment',
            key: 'deliveryApartment',
            sorter: (a, b) => (a.deliveryApartment ? a.deliveryApartment : '').localeCompare((b.deliveryApartment ? b.deliveryApartment : ''))
          },
          {
            title: 'Mențiuni',
            dataIndex: 'deliveryMention',
            key: 'deliveryMention',
            sorter: (a, b) => (a.deliveryMention ? a.deliveryMention : '').localeCompare((b.deliveryMention ? b.deliveryMention : ''))
          },
          {
            title: 'Livrare cu curier',
            dataIndex: 'deliveryFast',
            key: 'deliveryFast',
            sorter: (a, b) => (a.deliveryFast === b.deliveryFast) ? 0 : a.deliveryFast ? 1 : -1,
            render: (text, record) => record.deliveryFast === null ? null : <Checkbox checked={record.deliveryFast} />
          },
          {
            title: 'Județ',
            dataIndex: 'deliveryCounty',
            key: 'deliveryCounty',
            sorter: (a, b) => ((a.deliveryCounty && a.deliveryCounty.name) ? a.deliveryCounty.name : '').localeCompare(((b.deliveryCounty && b.deliveryCounty.name) ? b.deliveryCounty.name : '')),
            render: (text, record) => (record.deliveryCounty && record.deliveryCounty.name) ? record.deliveryCounty.name : ''   
          },
          {
            title: 'Oraș',
            dataIndex: 'deliveryTown',
            key: 'deliveryTown',
            sorter: (a, b) => ((a.deliveryTown && a.deliveryTown.name) ? a.deliveryTown.name : '').localeCompare(((b.deliveryTown && b.deliveryTown.name) ? b.deliveryTown.name : '')),
            render: (text, record) => (record.deliveryTown && record.deliveryTown.name) ? record.deliveryTown.name : ''          
          }
        ]
      }
      

    ]
    return (
      <React.Fragment>
        {_.size(record.vehicle) ? <Table columns={vehicleColumns} dataSource={record.vehicle} rowKey="id" pagination={false} /> : null}
        {(_.size(record.vehicle) || _.size(record.rca)) ? <Divider /> : null}
        {_.size(record.rca) ? <Table columns={rcaColumns} dataSource={record.rca} rowKey="id" pagination={false} /> : null}
      </React.Fragment>
    )
  }

  return (
    <Table
      bordered
      dataSource={data}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 100 }}
      expandedRowRender={(record, index, indent, expanded) => expandedRowRender(record)}
    />
  );
};

DatabaseSearchTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
};

export default DatabaseSearchTable;
