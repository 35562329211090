import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import VouchersList from './VouchersList/VouchersList';
import VouchersAddOrEdit from './VouchersAddOrEdit/VouchersAddOrEdit';
import VouchersCashbackPointsList from './VouchersCashbackPointsList/VouchersCashbackPointsList';
import VouchersServiceRecommendedList from './VouchersServiceRecommendedList/VouchersServiceRecommendedList';
import VouchersCreditsGatheredList from './VouchersCreditsGatheredList/VouchersCreditsGatheredList';
import VouchersCreditsConsumedList from './VouchersCreditsConsumedList/VouchersCreditsConsumedList';


export default class VouchersPage extends Component {
  render() {
    const { match } = this.props;

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Switch>
          <Redirect exact path={`${match.path}/`} to={`${match.path}/list`} />
          <Redirect exact path={`${match.path}/cashbackpoints`} to={`${match.path}/cashbackpoints/list`} />
          <Redirect exact path={`${match.path}/servicerecommended`} to={`${match.path}/servicerecommended/list`} />
          <Redirect exact path={`${match.path}/creditsgathered`} to={`${match.path}/creditsgathered/list`} />
          <Redirect exact path={`${match.path}/creditsconsumed`} to={`${match.path}/creditsconsumed/list`} />
          <Route path={`${match.path}/list`} component={VouchersList} />
          <Route path={`${match.path}/add`} component={VouchersAddOrEdit} />
          <Route path={`${match.path}/cashbackpoints/list`} component={VouchersCashbackPointsList} />
          <Route path={`${match.path}/servicerecommended/list`} component={VouchersServiceRecommendedList} />
          <Route path={`${match.path}/creditsgathered/list`} component={VouchersCreditsGatheredList} />
          <Route path={`${match.path}/creditsconsumed/list`} component={VouchersCreditsConsumedList} />
          <Route path={`${match.path}/:type/:voucherId`} component={VouchersAddOrEdit} />
          <Redirect to={`${match.path}/list`} />
        </Switch>
      </div>
    );
  }
}