import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Button,
  message,
  InputNumber,
  Select,
} from 'antd';
import _ from 'lodash';
import { addService, geocode, findPlaceId } from '../../../api';
import Map from '../../Map/Map';
import config from '../../../config';

import './styles.css';
import COUNTRIES from '../../../utils/countries';
import COUNTIES from '../../../utils/counties';

class ServicesAdd extends Component {
  state = {
    lat: 45.148218,
    lng: 26.815176,
    placeIdData: null,
    isAdding: false,
  };

  handleSaveNewService = () => {
    const { form, history } = this.props;
    const { lat, lng } = this.state;

    form.validateFields((err, values) => {
      if (err) return;

      const serviceSpecific = values.specific.join(',');
      const serviceComparator = values.comparator;
      console.log('serviceComparator', serviceComparator);
      const newServiceValues = _.pickBy(
        _.omit(
          _.assign(values, {
            mecanica: serviceSpecific.indexOf('MECANICA') > -1,
            electrica: serviceSpecific.indexOf('ELECTRICA') > -1,
            vopsitorie: serviceSpecific.indexOf('VOPSITORIE') > -1,
            itp: serviceSpecific.indexOf('ITP') > -1,
            tuning: serviceSpecific.indexOf('TUNING') > -1,
            comparator: serviceComparator.indexOf('COMPARATOR') > -1,
            lat: lat,
            lng: lng,
            feePercent: +(values.feePercent / 100).toFixed(2),
            feePercentOwnClients: +(values.feePercentOwnClients / 100).toFixed(
              2
            ),
          }),
          ['specific']
        )
      );

      this.setState({ isAdding: true });

      addService(Object.assign({}, { companyVat: false }, newServiceValues))
        .then((response) => {
          message.success('Service adăugat cu succes!');

          history.push('/services');
        })
        .catch((error) => {
          this.setState({ isAdding: false });
          if (error.response)
            switch (error.response.status) {
              case 400: // Bad Request
                message.error('Verificați corectitudinea completării datelor.');
                break;
              default: {
                const { code } = error.response.data;
                if (code) {
                  message.error(`Eroare: ${code}`);
                } else {
                  message.error('Eroare.');
                }
                console.log(error);
              }
            }
          else {
            message.error('Eroare.');
            console.log(error);
          }
        });
    });
  };

  geocodeAddress = (address) => {
    geocode(address)
      .then((response) => {
        this.setState({
          ...response.data.results[0].geometry.location,
          isAddressFound: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  findPlaceId = (search) => {
    const { form } = this.props;
    findPlaceId(search)
      .then((response) => {
        this.setState({
          placeIdData: response.data,
        });
        form.setFieldsValue({
          placeId: response.data.placeId,
        });
      })
      .catch((error) => {
        console.error(error);
        message.error('A avut loc o eroare la căutarea place ID pentru Google');
      });
  };

  handleOnMarkerDragEnd = (event) => {
    try {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      console.log('Lat', lat);
      console.log('Lng', lng);
      if (lat && lng) {
        this.setState({ lat, lng });
      }
    } catch {
      console.log('Received onDragEnd event', event);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { lat, lng, placeIdData, isAdding } = this.state;

    return (
      <div className={'service-add-page'}>
        <Helmet title="Adaugă un service nou" />
        <Row type="flex" justify="space-between" className={'section'}>
          <Col>
            <h3>Adaugă un service nou</h3>
          </Col>
          <Col>
            <Button
              type="primary"
              icon="plus"
              onClick={this.handleSaveNewService}
              loading={isAdding}
            >
              Salvează
            </Button>
          </Col>
        </Row>
        <Form onSubmit={this.handleSaveNewService}>
          <h2>Date vizibile în aplicație</h2>
          <Row type={'flex'} gutter={16} className={'section'}>
            <Col span={12}>
              <Form.Item label="Denumire" hasFeedback>
                {getFieldDecorator('name', {
                  initialValue: 'SC SERVICE-UL MEU S.R.L.', // TODO: Remove in production
                  rules: [
                    {
                      required: true,
                      message: 'Denumirea este obligatorie!',
                    },
                    {
                      min: 3,
                      message:
                        'Denumirea trebuie să conțină minim 3 caractere.',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Adresa">
                {getFieldDecorator('address', {
                  initialValue: 'Bld. Unrii, bl. 46C', // TODO: Remove in production
                  rules: [
                    {
                      required: true,
                      message: 'Adresa este obligatorie!',
                    },
                  ],
                })(<Input.Search onSearch={this.geocodeAddress} enterButton />)}
              </Form.Item>
              <Form.Item label="Telefon contact" hasFeedback>
                {getFieldDecorator('phone', {
                  initialValue: '0700110011', // TODO: Remove in production
                  rules: [
                    {
                      required: true,
                      message: 'Telefonul de contact este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Email contact" hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: 'contact@service.ro', // TODO: Remove in production
                  rules: [
                    {
                      type: 'email',
                      message: 'Email-ul nu este valid!',
                    },
                    {
                      required: true,
                      message: 'Email-ul de contact este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Facilități" hasFeedback>
                {getFieldDecorator('specific', {
                  rules: [
                    {
                      required: true,
                      message: 'Specificul este obligatoriu!',
                    },
                  ],
                })(
                  <Checkbox.Group
                    options={[
                      { label: 'Mecanică', value: 'MECANICA' },
                      { label: 'Electrică', value: 'ELECTRICA' },
                      { label: 'Vopsitorie/Tinichigerie', value: 'VOPSITORIE' },
                      { label: 'ITP', value: 'ITP' },
                      { label: 'Tuning', value: 'TUNING' },
                    ]}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
              <Form.Item label="Tools" hasFeedback>
                {getFieldDecorator('comparator', {
                  rules: [
                    {
                      required: false,
                    },
                  ],
                })(
                  <Checkbox.Group
                    options={[{ label: 'Comparator', value: 'COMPARATOR' }]}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Review-uri Google termeni căutare">
                    <Input.Search
                      defaultValue="Bld. Unrii, bl. 46C"
                      onSearch={this.findPlaceId}
                      enterButton
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Review-uri Google place ID">
                    {getFieldDecorator('placeId', {
                      initialValue: null,
                    })(
                      <Input
                        disabled
                        suffix={
                          <Button
                            icon="delete"
                            onClick={() => {
                              this.setState({
                                placeIdData: null,
                              });
                              this.props.form.resetFields(['placeId']);
                            }}
                          />
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
                {placeIdData && (
                  <Col span={24}>
                    <div>{`Nume: ${placeIdData.name}, Adresă: ${placeIdData.formattedAddress}`}</div>
                    <div>{`Rating: ${placeIdData.rating}, Număr review-uri: ${placeIdData.userRatingsTotal}`}</div>
                    <div>
                      {`URL Maps: `}
                      <a href={placeIdData.url}>{placeIdData.url}</a>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={12}>
              <Map
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.application.GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: '475px' }} />}
                mapElement={<div style={{ height: `100%` }} />}
                centerLat={lat}
                centerLng={lng}
                isMarkerShown={true}
                handleOnDragEnd={this.handleOnMarkerDragEnd}
                extra={
                  placeIdData
                    ? [{ lat: placeIdData.lat, lng: placeIdData.lng }]
                    : null
                }
              />
            </Col>
          </Row>
          <h2>Date facturare</h2>
          <Row type={'flex'} gutter={16}>
            <Col span={4}>
              <Form.Item label="Nume companie" hasFeedback>
                {getFieldDecorator('companyName', {
                  initialValue: 'DEMOCOMPANY SRL',
                  rules: [
                    {
                      required: true,
                      message: 'Numele companiei este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="CUI" hasFeedback>
                {getFieldDecorator('companyCode', {
                  initialValue: '21232425',
                  rules: [
                    {
                      required: true,
                      message: 'CUI-ul companiei este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Nr. Reg. Com" hasFeedback>
                {getFieldDecorator('companyRegCode', {
                  initialValue: 'J10/222/1994',
                  rules: [
                    {
                      required: true,
                      message:
                        'Numarul din Reg. Com. al companiei este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Plătitor TVA">
                {getFieldDecorator('companyVat', {
                  initialValue: false,
                  valuePropName: 'checked',
                  rules: [
                    {
                      required: true,
                    },
                  ],
                })(<Checkbox />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Țară" hasFeedback>
                {getFieldDecorator('companyCountry', {
                  initialValue: 'Romania',
                  rules: [
                    {
                      required: true,
                      message: 'Țara companiei este obligatorie!',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {_.map(COUNTRIES, (country) => (
                      <Select.Option key={country} value={country}>
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Județ" hasFeedback>
                {getFieldDecorator('companyCounty', {
                  initialValue: 'Buzau',
                  rules: [
                    {
                      required: true,
                      message: 'Județul companiei este obligatoriu!',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {_.map(COUNTIES, (county) => (
                      <Select.Option key={county} value={county}>
                        {county}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Adresa" hasFeedback>
                {getFieldDecorator('companyAddress', {
                  initialValue: 'Calea Plevnei 100',
                  rules: [
                    {
                      required: true,
                      message: 'Adresa companiei este obligatorie!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Cod poștal" hasFeedback>
                {getFieldDecorator('companyPostalCode', {
                  initialValue: '120211',
                  rules: [
                    {
                      required: true,
                      message: 'Codul poștal al companiei este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Row type={'flex'} gutter={16} className={'section'}>
            <Col span={4}>
              <Form.Item label="Banca" hasFeedback>
                {getFieldDecorator('companyBankName', {
                  initialValue: 'Banca Transilvania',
                  rules: [
                    {
                      required: true,
                      message: 'Banca este obligatorie!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Sucursala" hasFeedback>
                {getFieldDecorator('companyBankBranch', {
                  initialValue: 'Unirii',
                  rules: [
                    {
                      required: true,
                      message: 'Sucursala este obligatorie!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="IBAN" hasFeedback>
                {getFieldDecorator('companyIban', {
                  initialValue: 'RO09BCYP0000001234567890',
                  rules: [
                    {
                      required: true,
                      message: 'IBAN-ul este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <h2>Date contact</h2>
          <Row type={'flex'} gutter={16} className={'section'}>
            <Col span={4}>
              <Form.Item label="Nume" hasFeedback>
                {getFieldDecorator('companyPersonLastName', {
                  initialValue: 'Kent',
                  rules: [
                    {
                      required: true,
                      message: 'Numele persoanei de contact este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>{' '}
            <Col span={4}>
              <Form.Item label="Prenume" hasFeedback>
                {getFieldDecorator('companyPersonFirstName', {
                  initialValue: 'Gigi',
                  rules: [
                    {
                      required: true,
                      message:
                        'Prenumele persoanei de contact este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Numar de telefon" hasFeedback>
                {getFieldDecorator('companyPersonPhone', {
                  initialValue: '0722000001',
                  rules: [
                    {
                      required: true,
                      message:
                        'Numărul de telefon al persoanei de contact este obligatoriu!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Email" hasFeedback>
                {getFieldDecorator('companyPersonEmail', {
                  initialValue: 'emailcontact@firma.com',
                  rules: [
                    {
                      required: true,
                      message: 'Emailul persoanei de contact este obligatoriu!',
                    },
                    {
                      type: 'email',
                      message: 'Email-ul nu este valid!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <h2>Comisioane</h2>
          <Row type={'flex'} gutter={16} className={'section'}>
            <Col span={2}>
              <Form.Item label="Comision Autopia (%)" hasFeedback>
                {getFieldDecorator('feePercent', {
                  initialValue: 10,
                  rules: [
                    {
                      required: true,
                      message: 'Comisionul Autopia este obligatoriu!',
                    },
                  ],
                })(<InputNumber />)}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                label="Comision Autopia pentru clienții proprii (%)"
                hasFeedback
              >
                {getFieldDecorator('feePercentOwnClients', {
                  initialValue: 10,
                  rules: [
                    {
                      required: true,
                      message:
                        'Comisionul Autopia pentru clienții proprii este obligatoriu!',
                    },
                  ],
                })(<InputNumber />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create()(ServicesAdd);
