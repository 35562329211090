import React, { Component } from 'react';
import Helmet from 'react-helmet';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Upload,
  Icon,
  message,
  Spin,
  Checkbox,
  InputNumber
} from 'antd';
import { connect } from 'react-redux';
import { updateArticle } from '../../../api';
import config from '../../../config';

class NewsEdit extends Component {
  state = {
    imageUrl: null,
    isLoading: false,
    initialArticle: null,
    fileList: []
  };

  componentDidMount = () => {
    const { location, history } = this.props;
    const { newsItem } = location.state;

    if (!newsItem) history.push('/');

    this.setState({
      initialArticle: newsItem,
      fileList: [
        {
          uid: 'logo',
          name: 'logo',
          status: 'done',
          url: newsItem.image
        }
      ],
      imageUrl: newsItem.image
    });
  };

  handleSaveNewNotification = () => {
    const { form, history } = this.props;
    const { initialArticle } = this.state;

    form.validateFields((error, values) => {
      if (error) return;
      if (!this.state.imageUrl) return;

      const body = {
        title: values.title,
        content: values.content,
        author: values.author,
        image: this.state.imageUrl,
        pinned: values.pinned,
        sort: values.sort
      };
      console.log(body);
      this.setState({ isLoading: true });
      updateArticle(initialArticle.id, body)
        .then(response => {
          console.log(response.data);
          message.success('Noutatea a fost editată cu succes!');
          history.push('/news/');
        })
        .catch(error => {
          console.error(error);
          this.setState({ isLoading: false });
          message.error('A aparut o eroare la editarea noutatii.');
        });
    });
  };

  normFile = e => {
    console.log('Upload event:', e);
    if (e.file.response) this.setState({ imageUrl: e.file.response.url });
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { imageUrl, isLoading, initialArticle, fileList } = this.state;
    const { auth } = this.props;

    return (
      <React.Fragment>
        <Helmet title="Adaugă o noutate nouă" />
        <Spin spinning={isLoading}>
          <Row type="flex" justify="space-between">
            <Col>
              <h3>Editează o noutate</h3>
            </Col>
            <Col>
              <Button
                type="primary"
                icon="save"
                onClick={this.handleSaveNewNotification}
              >
                Salvează modificări
              </Button>
            </Col>
          </Row>
          {initialArticle && (
            <Form onSubmit={this.handleSaveNewNotification}>
              <Form.Item label="Pin to top" hasFeedback>
                {getFieldDecorator('pinned', {
                  initialValue: initialArticle.pinned
                })(
                  <Checkbox
                    style={{ marginRight: '32px' }}
                    defaultChecked={initialArticle.pinned}
                  >
                    Afișează articolul printre primele
                  </Checkbox>
                )}
              </Form.Item>
              <Form.Item label="Importanță" hasFeedback>
                {getFieldDecorator('sort', {
                  initialValue: initialArticle.sort,
                  rules: [
                    {
                      required: true,
                      message: 'Importanța este obligatoriu!'
                    }
                  ]
                })(<InputNumber min={0} style={{ marginRight: '32px' }} />)}
              </Form.Item>
              <Form.Item label="Autor" hasFeedback>
                {getFieldDecorator('author', {
                  initialValue: initialArticle.author,
                  rules: [
                    {
                      required: true,
                      message: 'Autorul este obligatoriu!'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Titlu" hasFeedback>
                {getFieldDecorator('title', {
                  initialValue: initialArticle.title,
                  rules: [
                    {
                      required: true,
                      message: 'Titlul este obligatoriu!'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Conținut">
                {getFieldDecorator('content', {
                  initialValue: initialArticle.content,
                  rules: [
                    {
                      required: true,
                      message: 'Conținutul este obligatoriu!'
                    }
                  ]
                })(<Input.TextArea rows={10} />)}
              </Form.Item>
              <Form.Item label="Imagine">
                <div className="dropbox">
                  {getFieldDecorator('image', {
                    initialValue: fileList,
                    valuePropName: 'fileList',
                    getValueFromEvent: this.normFile,
                    rules: [
                      {
                        required: true,
                        message: 'Imaginea este obligatorie!'
                      }
                    ]
                  })(
                    <Upload.Dragger
                      name="file"
                      action={`${config.backend.BASE_URL}/j4qCKfMC/images/`}
                      headers={{ authorization: `Bearer ${auth.token}` }}
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload.
                      </p>
                    </Upload.Dragger>
                  )}
                </div>
              </Form.Item>
              <Form.Item label="Preview">
                <div className="preview">
                  {getFieldDecorator(
                    'preview',
                    {}
                  )(
                    <div
                      style={{
                        backgroundImage: `url('${imageUrl}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        height: '300px'
                      }}
                    />
                  )}
                </div>
              </Form.Item>
            </Form>
          )}
        </Spin>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(Form.create()(NewsEdit));
