import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Row, Col, Input, Form } from 'antd';
import { downloadInvoice } from '../../../api';
import { saveAs } from 'file-saver';

class ServiceCompanyInvoices extends React.Component {
  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Serie',
      dataIndex: 'series',
      key: 'series'
    },
    {
      title: 'Număr',
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: 'Valoare',
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: 'Descriere',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Acțiuni',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button
            type={'link'}
            onClick={() => this.handleDownloadInvoice(record.id)}
          >
            Descarcă
          </Button>
        </span>
      )
    }
  ];

  handleDownloadInvoice = invoiceId => {
    console.log('Downloading invoice', invoiceId);
    downloadInvoice(invoiceId)
      .then(result => {
        const file = new Blob([result.data], { type: 'application/pdf' });
        saveAs(file, 'factura.pdf');
      })
      .catch(err => {
        console.error(err);
      });
  };

  handleAddNewInvoice = () => {
    const { form, onAddNewInvoice } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      onAddNewInvoice(values);
    });
  };

  render() {
    const { invoices } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <div style={{ marginTop: '1rem' }}>
        <h2>Facturi</h2>
        <Row
          type="flex"
          align="middle"
          gutter={16}
          style={{ marginBottom: '1rem' }}
        >
          <Col span={20}>
            <Row type="flex" gutter={16}>
              <Col span={8}>
                <Form.Item label="Serie" hasFeedback>
                  {getFieldDecorator('series', {
                    initialValue: 'AFCT',
                    rules: [
                      {
                        required: true,
                        message: 'Seria este obligatorie!'
                      }
                    ]
                  })(
                    <Input
                      style={{ width: '100%' }}
                      placeholder="Serie"
                    ></Input>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Număr" hasFeedback>
                  {getFieldDecorator('number', {
                    rules: [
                      {
                        required: true,
                        message: 'Numărul este obligatoriu!'
                      }
                    ]
                  })(
                    <Input
                      style={{ width: '100%' }}
                      placeholder="Număr"
                    ></Input>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Descriere factură" hasFeedback>
                  {getFieldDecorator('description', {
                    rules: [
                      {
                        required: true,
                        message: 'Descrierea este obligatorie!'
                      }
                    ]
                  })(
                    <Input
                      style={{ width: '100%' }}
                      placeholder="Descriere factură"
                    ></Input>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <Button type="primary" onClick={this.handleAddNewInvoice}>
              Adaugă factură
            </Button>
          </Col>
        </Row>
        <Table
          dataSource={invoices}
          columns={this.tableColumns}
          size="small"
          bordered={false}
          rowKey="id"
        />
      </div>
    );
  }
}

ServiceCompanyInvoices.propTypes = {
  invoices: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  onAddNewInvoice: PropTypes.func.isRequired
};

ServiceCompanyInvoices.defaultProps = {
  invoices: [],
  onAddNewInvoice: () => null
};

export default Form.create()(ServiceCompanyInvoices);
