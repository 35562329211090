import React, { Component } from "react";
import { Row, Col, Table, Button, Checkbox, Divider, message, Popconfirm } from "antd";
import { getArticlesV2, deleteArticleV2 } from "../../../api";
import moment from "moment";
import articleTypesText from "../../../utils/articleTypesText";
import _ from "lodash";

export default class ArticleList extends Component {
  state = {
    articles: [],
    isLoading: false
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    getArticlesV2()
      .then(response => {
        this.setState({
          articles: _.orderBy(response.data, [o => !o.deletedAt], ['desc']),
          isLoading: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleGoToAddArticle = () => {
    const { history } = this.props;
    history.push("/article/add");
  };

  tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Creat la",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: "Tip",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type - b.type,
      render: (text, record) => (_.find(articleTypesText, {id: record.type}) || {}).text
    },
    {
      title: "Autor",
      dataIndex: "author",
      key: "author",
      sorter: (a, b) => a.author.localeCompare(b.author)
    },
    {
      title: "Titlu",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: "Pin to top",
      key: "pinned",
      align: "center",
      sorter: (a, b) => (a.pinned === b.pinned) ? 0 : a.pinned ? 1 : -1,
      render: (text, record) => <Checkbox checked={record.pinned} />
    },
    {
      title: "Prioritate (0 - cea mai mică)",
      key: "priority",
      dataIndex: "priority",
      sorter: (a, b) => a.priority - b.priority,
    },
    {
      title: 'Șters la',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      sorter: (a, b) => (a.deletedAt === null ? '' : a.deletedAt).localeCompare(b.deletedAt === null ? '' : b.deletedAt),
      render: (text, record) => record.deletedAt ? moment(record.deletedAt).format('DD.MM.YYYY HH:mm:ss') : ''
    },
    {
      title: "Acțiuni",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            type={'link'}
            onClick={() => this.handleEditArticle(record.id)}
          >
            Vizualizează / Editează
          </Button>
          {record.deletedAt ? null : (
            <React.Fragment>
              <Divider type={'vertical'} />
              <Popconfirm
                title="Ești sigur că vrei să ștergi acest articol?"
                onConfirm={() => this.handleDeleteArticle(record.id)}
              >
                <Button type={'danger'}>
                  Șterge
              </Button>
              </Popconfirm>
            </React.Fragment>
            
          )}
        </span>
      )
    }
  ];

  handleEditArticle = articleId => {
    const { history } = this.props;
    history.push(`/article/edit/${articleId}`);
  };

  handleDeleteArticle = articleId => {
    const { history } = this.props;
    deleteArticleV2(articleId)
      .then(response => {
        message.success("Articol șters cu succes!");
        history.push("/article");
      })
      .catch(error => {
        message.error("Eroare.");
        console.log(error);
      });
  };

  render() {
    const { articles, isLoading } = this.state;

    return (
      <div style={{ padding: 8 }}>
        <Row type="flex" justify="end" style={{ marginBottom: "20px" }}>
          <Col>
            <Button
              icon="plus"
              type="primary"
              onClick={this.handleGoToAddArticle}
            >
              Adaugă articol
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              bordered
              dataSource={articles}
              loading={isLoading}
              columns={this.tableColumns}
              scroll={{ x: 'max-content' }}
              rowKey="id"
              pagination={{ pageSize: 15 }}
              style={{ background: "white" }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
