import React, { Component } from "react";
import Helmet from "react-helmet";
import { Form, Input, Row, Col, Button, message, Popconfirm } from "antd";
import {
  addNewRoadAssistanceService,
  deleteRoadAssistanceService,
  geocode,
  getRoadAssistanceService,
  updateRoadAssistanceService
} from "../../../api";
import Map from "../../Map/Map";
import config from "../../../config";

class ServicesAdd extends Component {
  state = {
    lat: 45.148218,
    lng: 26.815176,
    updatingId: null
  };

  componentDidMount() {
    const { match, history, form } = this.props;

    if (match.params && match.params.roadAssistanceId) {
      console.log("Editing", match.params.roadAssistanceId);

      getRoadAssistanceService(match.params.roadAssistanceId)
        .then(result => {
          console.log(result.data);

          form.setFieldsValue({
            name: result.data.name,
            address: result.data.address,
            phone: result.data.phone
          });

          this.setState({
            updatingId: match.params.roadAssistanceId,
            lat: result.data.lat,
            lng: result.data.lng
          });
        })
        .catch(error => {
          console.error(error);
          history.push("/roadassistance");
        });
    }
  }

  handleSaveNewRoadAssistance = () => {
    const { form, history } = this.props;
    const { lat, lng, updatingId } = this.state;

    form.validateFields((err, values) => {
      if (err) return;

      const newRoadAssistance = Object.assign({}, values, { lat, lng });
      console.log("Should add road assistance", newRoadAssistance);

      if (updatingId) {
        updateRoadAssistanceService(updatingId, newRoadAssistance)
          .then(response => {
            message.success("Firma de tractare a fost configurată cu succes!");

            // TODO: Use relative paths
            history.push("/roadassistance");
          })
          .catch(error => {
            if (error.response)
              switch (error.response.status) {
                case 400: // Bad Request
                  message.error(
                    "Verificați corectitudinea completării datelor."
                  );
                  break;
                default: {
                  message.error("Eroare.");
                  console.log(error);
                }
              }
            else {
              message.error("Eroare.");
              console.log(error);
            }
          });
      } else {
        addNewRoadAssistanceService(newRoadAssistance)
          .then(response => {
            message.success("Firma de tractare a fost adăugată cu succes!");

            // TODO: Use relative paths
            history.push("/roadassistance");
          })
          .catch(error => {
            if (error.response)
              switch (error.response.status) {
                case 400: // Bad Request
                  message.error(
                    "Verificați corectitudinea completării datelor."
                  );
                  break;
                default: {
                  message.error("Eroare.");
                  console.log(error);
                }
              }
            else {
              message.error("Eroare.");
              console.log(error);
            }
          });
      }
    });
  };

  geocodeAddress = address => {
    geocode(address)
      .then(response => {
        this.setState({
          ...response.data.results[0].geometry.location,
          isAddressFound: true
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleOnMarkerDragEnd = event => {
    try {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      console.log("Lat", lat);
      console.log("Lmg", lng);
      if (lat && lng) {
        this.setState({ lat, lng });
      }
    } catch {
      console.log("Received onDragEnd event", event);
    }
  };

  handleDeleteRoadAssistance = () => {
    const { updatingId } = this.state;
    const { history } = this.props;

    if (!updatingId) return;

    deleteRoadAssistanceService(updatingId)
      .then(result => {
        console.log(result);
        message.success("Firma de tractare a fost ștearsă cu succes!");
        history.push("/roadassistance");
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { lat, lng, updatingId } = this.state;

    return (
      <React.Fragment>
        {updatingId ? (
          <Helmet title={`Configurează service-ul #${updatingId}`} />
        ) : (
          <Helmet title="Adaugă o firmă de tractare nouă" />
        )}

        <Row type="flex" justify="space-between">
          <Col>
            {updatingId ? (
              <h3>Configurează service-ul #{updatingId}</h3>
            ) : (
              <h3>Adaugă o firmă de tractare nouă</h3>
            )}
          </Col>
          <Col>
            <Button.Group>
              {updatingId && (
                <Popconfirm
                  title="Ești sigur că vrei să ștergi firma de tractare?"
                  onConfirm={this.handleDeleteRoadAssistance}
                  okText="Da"
                  cancelText="Nu"
                >
                  <Button type="danger" icon="delete">
                    Șterge definitiv
                  </Button>
                </Popconfirm>
              )}
              <Button
                type="primary"
                icon="plus"
                onClick={this.handleSaveNewRoadAssistance}
              >
                Salvează
              </Button>
            </Button.Group>
          </Col>
        </Row>
        <Form onSubmit={this.handleSaveNewRoadAssistance}>
          <Form.Item label="Denumire" hasFeedback>
            {getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  message: "Denumirea este obligatorie!"
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Adresa">
            {getFieldDecorator("address", {
              rules: [
                {
                  required: true,
                  message: "Adresa este obligatorie!"
                }
              ]
            })(<Input.Search onSearch={this.geocodeAddress} enterButton />)}
          </Form.Item>
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.application.GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            centerLat={lat}
            centerLng={lng}
            isMarkerShown={true}
            handleOnDragEnd={this.handleOnMarkerDragEnd}
          />
          <Form.Item label="Telefon contact" hasFeedback>
            {getFieldDecorator("phone", {
              rules: [
                {
                  required: true,
                  message: "Telefonul de contact este obligatoriu!"
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

export default Form.create()(ServicesAdd);
