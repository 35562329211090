import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Form, Row, Col } from 'antd';
import { getArticle } from '../../../api';

class NewsView extends Component {
  state = {
    article: null
  };

  componentDidMount = () => {
    const { match } = this.props;
    const { articleId } = match.params;

    getArticle(articleId)
      .then(response => {
        console.log(response.data);
        this.setState({ article: response.data });
      })
      .catch(error => console.error(error));
  };

  render() {
    const { article } = this.state;
    return (
      <React.Fragment>
        <Helmet title="Noutate" />
        {article && (
          <div>
            <Row>
              <Col span={24} style={{ height: '300px', textAlign: 'center' }}>
                <img src={article.image} height="300px" alt="" />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <h2 style={{ margin: '24px 0px' }}>{article.title}</h2>
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <p>{article.content}</p>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Form.create()(NewsView);
