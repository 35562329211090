import React, { Component } from 'react';
import WebContactTable from './WebContactTable/WebContactTable';
import Helmet from 'react-helmet';
import { getWebContact } from '../../api';
import NProgress from 'nprogress';

export default class ServicesList extends Component {
  state = {
    isLoading: false,
    webcontact: [],
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getWebContact()
      .then(resp => {
        this.setState({
          webcontact: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  render() {
    const { isLoading, webcontact } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Listă răspunsuri contact" />
        <WebContactTable
          isLoading={isLoading}
          webcontact={webcontact}
        />
      </React.Fragment>
    );
  }
}
