import React, { Component } from "react";
import Helmet from "react-helmet";
import {
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Button,
  message,
  InputNumber,
  Select,
  DatePicker,
  Spin
} from "antd";
import _ from "lodash";
import { addVoucher, getVoucher, editVoucher } from "../../../api";
import voucherTypesText from "../../../utils/voucherTypesText";


import "./styles.css";
import moment from "moment";

class VouchersAddOrEdit extends Component {
  state = {
    type: 1,
    subType: 1,
    isAdding: false,

    edit: false,
    currentVoucherId: null,
    isLoading: false
  };

  componentDidMount = () => {
    this.loadVoucherInfo();
  };

  loadVoucherInfo = () => {
    const { history, match, form } = this.props;
    const { type, voucherId } = match.params;

    if (type && voucherId){
      if (!_.includes([1, 2, 3], parseInt(type))){
        message.error('Eroare la încărcare pentru editarea voucherului, tip greșit')
        history.push('/vouchers');
        return;
      }
      if (!(_.isInteger(parseInt(voucherId)) && !isNaN(parseInt(voucherId)))) {
        message.error('Eroare la încărcare pentru editarea voucherului, id greșit')
        history.push('/vouchers');
        return;
      }
      
      this.setState({
        edit: true,
        type: parseInt(type),
        currentVoucherId: parseInt(voucherId)
      })
      this.setState({ isLoading: true });
      getVoucher(type, voucherId)
        .then(response => {
          console.log(response.data);
          this.setState({
            isLoading: false
          })
          if (parseInt(type) === 1){
            form.setFieldsValue({
              subType: response.data.subType,
              name: response.data.name,
              description: response.data.description,
              credits: response.data.credits / 100 ,
              maxUsers: response.data.maxUsers,
              stopNew: response.data.stopNew,
              creditsCode: response.data.creditsCode,
            })
          } else if (parseInt(type) === 2){
            form.setFieldsValue({
              subType: response.data.subType,
              name: response.data.name,
              description: response.data.description,
              credits: response.data.credits / 100,
              endTime: moment(response.data.endTime),
              stopNew: response.data.stopNew,
              creditsCode: response.data.creditsCode,
            })
          } else if (parseInt(type) === 3) {
            form.setFieldsValue({
              subType: response.data.subType,
              name: response.data.name,
              description: response.data.description,
              credits: response.data.credits / 100,
              service: response.data.service,
              bypassPercentage: response.data.bypassPercentage,
              user: _.first(response.data.creditsEnabled).user
            })
          } else {
            history.push('/vouchers');
          }
        })
        .catch(error => {
          message.error('Eroare la încărcare pentru editarea voucherului')
          console.error(error);
          history.push('/vouchers');
        });
    }
  }

  handleSaveNewVoucher = () => {
    const { form, history } = this.props;

    form.validateFields((err, newVoucherValues) => {
      if (err) return;

      console.log(newVoucherValues)

      this.setState({ isAdding: true });

      addVoucher(_.assign({}, newVoucherValues, { endTime: moment(newVoucherValues.endTime).isValid() ? moment.utc(newVoucherValues.endTime).toISOString() : undefined } ))
        .then(response => {
          message.success("Voucher adăugat cu succes!");

          history.push("/vouchers");
        })
        .catch(error => {
          this.setState({ isAdding: false });
          if (error.response)
            switch (error.response.status) {
              case 400: // Bad Request
                message.error("Verificați corectitudinea completării datelor.");
                break;
              default: {
                const { code } = error.response.data;
                if (code) {
                  message.error(`Eroare: ${code}`);
                } else {
                  message.error("Eroare.");
                }
                console.log(error);
              }
            }
          else {
            message.error("Eroare.");
            console.log(error);
          }
        });
    });
  };

  handleEditVoucher = () => {
    const { form, history } = this.props;
    const { type, currentVoucherId } = this.state;

    form.validateFields((err, newVoucherValues) => {
      if (err) return;

      console.log(newVoucherValues)

      this.setState({ isAdding: true });

      editVoucher(type, currentVoucherId, _.assign({}, newVoucherValues, { endTime: moment(newVoucherValues.endTime).isValid() ? moment.utc(newVoucherValues.endTime).toISOString() : undefined }))
        .then(response => {
          message.success("Voucher modificat cu succes!");
          history.push("/vouchers");
        })
        .catch(error => {
          this.setState({ isAdding: false });
          if (error.response)
            switch (error.response.status) {
              case 400: // Bad Request
                message.error("Verificați corectitudinea completării datelor.");
                break;
              default: {
                const { code } = error.response.data;
                if (code) {
                  message.error(`Eroare: ${code}`);
                } else {
                  message.error("Eroare.");
                }
                console.log(error);
              }
            }
          else {
            message.error("Eroare.");
            console.log(error);
          }
        });
    });
  };

  generateFormItems = getFieldDecorator => {
    const { type, subType, edit } = this.state;

    let final = [
      <Col span={8} key="subType">
        <Form.Item label="Subtip de voucher" hasFeedback>
          {getFieldDecorator("subType", {
            initialValue: 1,
            rules: [
              {
                required: true,
                message: "Subtipul este obligatoriu!"
              }
            ]
          })(
            <Select onChange={this.handleChangeVoucherSubType} disabled={edit}>
              {_.map(_.find(voucherTypesText, {id: type}).subTypes, subTypeObject => {
                return <Select.Option key={subTypeObject.id} value={subTypeObject.id}>{subTypeObject.text}</Select.Option>
              })}
            </Select>
          )}
        </Form.Item>
      </Col>,
      <Col span={4} key="credits">
        <Form.Item label="Credite (bani RON)" hasFeedback>
          {getFieldDecorator("credits", {
            initialValue: 0,
            rules: [
              {
                required: true,
                message: "Numărul de credite este obligatoriu!"
              }
            ]
          })(<InputNumber />)}
        </Form.Item>
      </Col>,
      <Col span={4} key="name">
        <Form.Item label="Nume" hasFeedback>
          {getFieldDecorator("name", {
            rules: []
          })(<Input />)}
        </Form.Item>
      </Col>,
      <Col span={4} key="description">
        <Form.Item label="Descriere" hasFeedback>
          {getFieldDecorator("description", {
            rules: []
          })(<Input />)}
        </Form.Item>
      </Col>
    ]


    if (type === 1) {
      final = final.concat([
        <Col span={4} key="maxUsers">
          <Form.Item label="Utilizatori maximi" hasFeedback>
            {getFieldDecorator("maxUsers", {
              initialValue: 0,
              rules: [
                {
                  required: true,
                  message: "Numarul de utilizatori maximi este obligatoriu!"
                }
              ]
            })(<InputNumber />)}
          </Form.Item>
        </Col>
      ])
    }

    if (type === 2){
      final = final.concat([
        <Col span={4} key="endTime">
          <Form.Item label="Data limită maximă" hasFeedback>
            {getFieldDecorator("endTime", {
              rules: [
                {
                  required: true,
                  message: "Data limită maximă este obligatorie!"
                }
              ]
            })(<DatePicker showTime />)}
          </Form.Item>
        </Col>
      ])
    }

    if (type === 3) {
      final = final.concat([
        <Col span={4} key="user">
          <Form.Item label="Id user la care se adaugă" hasFeedback>
            {getFieldDecorator("user", {
              rules: [
                {
                  required: true,
                  message: 'Userul este obligatoriu'
                }
              ]
            })(<InputNumber />)}
          </Form.Item>
        </Col>
      ])
      final = final.concat([
        <Col span={4} key="bypassPercentage">
          <Form.Item label="Nu lua în considerare procentul">
            {getFieldDecorator("bypassPercentage", {
              initialValue: false,
              valuePropName: "checked",
              rules: [
                {
                  required: true
                }
              ]
            })(<Checkbox />)}
          </Form.Item>
        </Col>
      ])
    }

    if (type === 3 && subType === 2) {
      final = final.concat([
        <Col span={4} key="service">
          <Form.Item label="Id service recomandat" hasFeedback>
            {getFieldDecorator("service", {
              rules: [
                {
                  required: true,
                  message: 'Id-ul serviceului este obligatoriu'
                }
              ]
            })(<InputNumber />)}
          </Form.Item>
        </Col>
      ])
    }

    if (type === 1 || type === 2){
      final = final.concat([
        <Col span={4} key="stopNew">
          <Form.Item label="Stop temporar">
            {getFieldDecorator("stopNew", {
              initialValue: false,
              valuePropName: "checked",
              rules: [
                {
                  required: true
                }
              ]
            })(<Checkbox />)}
          </Form.Item>
        </Col>,
        <Col span={4} key="creditsCode">
          <Form.Item label="Cod voucher" hasFeedback>
            {getFieldDecorator("creditsCode", {
              normalize: input => input ? _.trim(input.toUpperCase()) : input,
              rules: [
                {
                  required: true,
                  message: 'Codul voucher este obligatoriu'
                }
              ]
            })(<Input />)}
          </Form.Item>
        </Col>
      ])
    }

    return final;
  }

  handleChangeVoucherType = type => {
    this.setState({
      type
    })
  }

  handleChangeVoucherSubType = subType => {
    this.setState({
      subType
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isAdding, edit, isLoading } = this.state;

    return (
      <div className={"voucher-add-page"}>
        <Helmet title={edit ? 'Modifică un voucher' : 'Adaugă un voucher'}/>
        <Row type="flex" justify="space-between" className={"section"}>
          <Col>
            <h3>{edit ? 'Modifică un voucher' : 'Adaugă un voucher'}</h3>
          </Col>
          <Col>
            <Button
              type="primary"
              icon="plus"
              onClick={edit ? this.handleEditVoucher : this.handleSaveNewVoucher}
              loading={isAdding}
            >
              {edit ? 'Salvează modificările' : 'Salvează si adaugă'}
            </Button>
          </Col>
        </Row>
        <Spin spinning={isLoading} tip="Se încarcă...">

          <Form onSubmit={this.handleSaveNewService}>
            <h2>Tipul voucherului</h2>
            <Row type={"flex"} gutter={16} className={"section"}>
              <Col span={8}>
                <Form.Item label="Tip de voucher" hasFeedback>
                  {getFieldDecorator("type", {
                    initialValue: 1,
                    rules: [
                      {
                        required: true,
                        message: "Tipul este obligatoriu!"
                      }
                    ]
                  })(
                    <Select onChange={this.handleChangeVoucherType} disabled={edit}>
                      {_.map(voucherTypesText, typeObject => {
                        if (typeObject.id === 1 || typeObject.id === 2 || typeObject.id === 3) {
                          return <Select.Option key={typeObject.id} value={typeObject.id}>{typeObject.text}</Select.Option>
                        }
                        return null;
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <h2>Date despre voucher</h2>
            <Row type={"flex"} gutter={16} className={"section"}>
              {this.generateFormItems(getFieldDecorator)}
            </Row>
          </Form>

        </Spin>
      </div>
    );
  }
}

export default Form.create()(VouchersAddOrEdit);
