import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';

const UserReviewsTable = ({
  userreviews,
  isLoading,
}) => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Data si ora (GMT)',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'Fname',
      dataIndex: 'fname',
      key: 'fname',
      sorter: (a, b) => ((a.user && a.user.fname) ? a.user.fname : '').localeCompare(((b.user && b.user.fname) ? b.user.fname : '')),
      render: (text, record) => (record.user && record.user.fname) ? record.user.fname : ''
    },
    {
      title: 'Lname',
      dataIndex: 'lname',
      key: 'lname',
      sorter: (a, b) => ((a.user && a.user.lname) ? a.user.lname : '').localeCompare(((b.user && b.user.lname) ? b.user.lname : '')),
      render: (text, record) => (record.user && record.user.lname) ? record.user.lname : ''
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => ((a.user && a.user.email) ? a.user.email : '').localeCompare(((b.user && b.user.email) ? b.user.email : '')),
      render: (text, record) => (record.user && record.user.email) ? record.user.email : ''
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      sorter: (a, b) => a.rating - b.rating,
    },
    {
      title: 'Dispozitiv',
      dataIndex: 'device',
      key: 'device',
      sorter: (a, b) => a.device.localeCompare(b.device)
    },
    {
      title: 'Mesaj',
      dataIndex: 'message',
      key: 'message',
      sorter: (a, b) => (a.message ? a.message : '').localeCompare((b.message ? b.message : ''))
    },
  ];

  return (
    <Table
      bordered
      dataSource={userreviews}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 15 }}
    />
  );
};

UserReviewsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  userreviews: PropTypes.array.isRequired,
};

export default UserReviewsTable;
