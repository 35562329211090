import React, { Component } from 'react';
import UserReviewsTable from './UserReviewsTable/UserReviewsTable';
import Helmet from 'react-helmet';
import { getUserReviews } from '../../api';
import NProgress from 'nprogress';

export default class UserReviewsPage extends Component {
  state = {
    isLoading: false,
    userreviews: [],
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getUserReviews()
      .then(resp => {
        this.setState({
          userreviews: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  render() {
    const { isLoading, userreviews } = this.state;

    return (
      <React.Fragment>
        <Helmet title="Listă review-uri useri" />
        <UserReviewsTable
          isLoading={isLoading}
          userreviews={userreviews}
        />
      </React.Fragment>
    );
  }
}
