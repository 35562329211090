import React, { Component } from 'react';
import DatabaseSearchTable from './DatabaseSearchTable/DatabaseSearchTable';
import Helmet from 'react-helmet';
import { getDatabaseSearchUsersSize, getDatabaseSearchUsersPartial, getDatabaseSearchVehiclesSize, getDatabaseSearchVehiclesPartial, getDatabaseSearchRcasSize, getDatabaseSearchRcasPartial } from '../../api';
import NProgress from 'nprogress';
import _ from 'lodash';
import { Row, Col, Input, Button, Spin, message } from 'antd';

const MAX_RECORDS_AT_ONCE = 500;

export default class DatabaseSearchPage extends Component {
  state = {
    isLoadingBig: false,
    isLoading: false,
    data: [],
    dataFiltered: [],

    userId: null,
    userEmail: null,
    userFname: null,
    userLname: null,
    userPhone: null,
    userCreditsCode: null,
    vehicleId: null,
    vehicleVin: null,
    vehicleRegistrationNumber: null,
    vehicleBrand: null,
    vehicleModel: null,
    vehicleYear: null,
    vehicleExternalId: null,
    rcaId: null
  };

  getUsers = async () => {
    const size = (await getDatabaseSearchUsersSize()).data.size;
    let users = [];

    while (_.size(users) < size) {
      const tempUsers = (await getDatabaseSearchUsersPartial(_.size(users), MAX_RECORDS_AT_ONCE)).data.users
      if (_.size(tempUsers) === 0) {
        return users;
      }
      users = [...users, ...tempUsers]
    }

    return { users };
  }

  getVehicles = async () => {
    const size = (await getDatabaseSearchVehiclesSize()).data.size;
    let vehicles = [];

    while (_.size(vehicles) < size) {
      const tempVehicles = (await getDatabaseSearchVehiclesPartial(_.size(vehicles), MAX_RECORDS_AT_ONCE)).data.vehicles
      if (_.size(tempVehicles) === 0) {
        return vehicles;
      }
      vehicles = [...vehicles, ...tempVehicles]
    }

    return { vehicles };
  }

  getRcas = async () => {
    const size = (await getDatabaseSearchRcasSize()).data.size;
    let rcas = [];

    while (_.size(rcas) < size) {
      const tempRcas = (await getDatabaseSearchRcasPartial(_.size(rcas), MAX_RECORDS_AT_ONCE)).data.rcas
      if (_.size(tempRcas) === 0) {
        return rcas;
      }
      rcas = [...rcas, ...tempRcas]
    }

    return { rcas };
  }

  componentDidMount = async () => {
    this.setState({ isLoadingBig: true });
    NProgress.start();
    try {
      console.log('incep r-urile')
      const r1 = await this.getUsers();
      NProgress.set(0.3);
      console.log('gata r1')
      const r2 = await this.getVehicles();
      NProgress.set(0.6);
      console.log('gata r2')
      const r3 = await this.getRcas();
      NProgress.set(0.9);
      console.log('gata r3')
      this.constructData(r1.users, r2.vehicles, r3.rcas);
    } catch (e) {
      console.log(e)
      console.log('a murit cererea')
      message.error('A dat greș încărcarea bazei de date, dă-mi mesaj.')
    }

    this.setState({ isLoadingBig: false });
    NProgress.done();

  };

  constructData = (users, vehicles, rcas) => {
    const vehiclesGrouped = _.groupBy(vehicles, 'user');
    const rcasGrouped = _.groupBy(rcas, 'user');

    const data = _.map(users, user => {
      return {
        ...user,
        vehicle: vehiclesGrouped[user.id] ? vehiclesGrouped[user.id] : [],
        rca: rcasGrouped[user.id] ? rcasGrouped[user.id] : []
      }
    })
    
    this.setState({ data, dataFiltered: data })
  }

  handleFilterUserId = e => this.setState({ userId: e }, this.filterResults)
  handleFilterUserEmail = e => this.setState({ userEmail: e }, this.filterResults)
  handleFilterUserFname = e => this.setState({ userFname: e }, this.filterResults)
  handleFilterUserLname = e => this.setState({ userLname: e }, this.filterResults)
  handleFilterUserPhone = e => this.setState({ userPhone: e }, this.filterResults)
  handleFilterUserCreditsCode = e => this.setState({ userCreditsCode: e }, this.filterResults)

  handleFilterVehicleId = e => this.setState({ vehicleId: e }, this.filterResults)
  handleFilterVehicleVin = e => this.setState({ vehicleVin: e }, this.filterResults)
  handleFilterVehicleRegistrationNumber = e => this.setState({ vehicleRegistrationNumber: e }, this.filterResults)
  handleFilterVehicleBrand = e => this.setState({ vehicleBrand: e }, this.filterResults)
  handleFilterVehicleModel = e => this.setState({ vehicleModel: e }, this.filterResults)
  handleFilterVehicleYear = e => this.setState({ vehicleYear: e }, this.filterResults)
  handleFilterVehicleExternalId = e => this.setState({ vehicleExternalId: e }, this.filterResults)

  handleFilterRcaId = e => this.setState({ rcaId: e }, this.filterResults)


  filterResults = () => {
    const { 
      data,
      userId, userEmail, userFname, userLname, userPhone, userCreditsCode,
      vehicleId, vehicleVin, vehicleRegistrationNumber, vehicleBrand, vehicleModel, vehicleYear, vehicleExternalId,
      rcaId, 
    } = this.state;
    this.setState({ isLoading: true });

    const dataFilteredWithNull = _.map(data, user => {
      if (
        (userId && (!user.id || user.id.toString().toLowerCase().indexOf(userId) === -1)) ||
        (userEmail && (!user.email || user.email.toString().toLowerCase().indexOf(userEmail) === -1)) ||
        (userFname && (!user.fname || user.fname.toString().toLowerCase().indexOf(userFname) === -1)) ||
        (userLname && (!user.lname || user.lname.toString().toLowerCase().indexOf(userLname) === -1)) ||
        (userPhone && (!user.phone || user.phone.toString().toLowerCase().indexOf(userPhone) === -1)) ||
        (userCreditsCode && (!user.creditsCode || user.creditsCode.toString().toLowerCase().indexOf(userCreditsCode) === -1))
      ) {
        return null;
      }

      const vehicles = _.filter(user.vehicle, vehicle => {
        if (
          (vehicleId && (!vehicle.id || vehicle.id.toString().toLowerCase().indexOf(vehicleId) === -1)) ||
          (vehicleVin && (!vehicle.vin || vehicle.vin.toString().toLowerCase().indexOf(vehicleVin) === -1)) ||
          (vehicleRegistrationNumber && (!vehicle.registrationNumber || vehicle.registrationNumber.toString().toLowerCase().indexOf(vehicleRegistrationNumber) === -1)) ||
          (vehicleBrand && !(
            (vehicle.brandExtra && vehicle.brandExtra.toString().toLowerCase().indexOf(vehicleBrand) !== -1) ||
            (!vehicle.brandExtra && vehicle.brand && vehicle.brand.name && vehicle.brand.name.toString().toLowerCase().indexOf(vehicleBrand) !== -1)
          )) ||
          (vehicleModel && !(
            (vehicle.modelExtra && vehicle.modelExtra.toString().toLowerCase().indexOf(vehicleModel) !== -1) ||
            (!vehicle.modelExtra && vehicle.model && vehicle.model.name && vehicle.model.name.toString().toLowerCase().indexOf(vehicleModel) !== -1)
          )) ||
          (vehicleYear && (!vehicle.year || vehicle.year.toString().toLowerCase().indexOf(vehicleYear) === -1)) ||
          (vehicleExternalId && (!vehicle.externalId || vehicle.externalId.toString().toLowerCase().indexOf(vehicleExternalId) === -1))
        ) {
          return false;
        }
        return true;
      });

      if ((vehicleId || vehicleVin || vehicleRegistrationNumber || vehicleBrand || vehicleModel || vehicleYear || vehicleExternalId ) && _.size(vehicles) === 0) {
        return null;
      }

      const rcas = _.filter(user.rca, rca => {
        if (
          (rcaId && (!rca.id || rca.id.toString().toLowerCase().indexOf(rcaId) === -1))
        ) {
          return false;
        }
        return true;
      });
      
      if ((rcaId) && _.size(rcas) === 0) {
        return null;
      }

      return {
        ...user,
        vehicle: vehicles,
        rca: rcas
      };
    })

    const dataFiltered = _.filter(dataFilteredWithNull, o => o !== null);

    this.setState({
      dataFiltered,
      isLoading: false
    });
  };

  handleRemoveFilters = () => {
    const { data } = this.state;
    this.setState({
      userId: null,
      userEmail: null,
      userFname: null,
      userLname: null,
      userPhone: null,
      userCreditsCode: null,
      vehicleId: null,
      vehicleVin: null, 
      vehicleRegistrationNumber: null, 
      vehicleBrand: null, 
      vehicleModel: null, 
      vehicleYear: null, 
      vehicleExternalId: null,
      rcaId: null,
      dataFiltered: data
    })
  }

  render() {
    const { 
      isLoadingBig, isLoading, dataFiltered,
      userId, userEmail, userFname, userLname, userPhone, userCreditsCode,
      vehicleId, vehicleVin, vehicleRegistrationNumber, vehicleBrand, vehicleModel, vehicleYear, vehicleExternalId,
      rcaId,
    } = this.state;

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Helmet title="Caută în baza de date" />
        <Spin spinning={isLoadingBig} tip="Se încarcă (nu pe bune, chiar se încarcă). Dacă arată eroare sus atunci chiar nu se încarcă și a dat greș.">

          <Row type="flex" justify="space-between" gutter={24} style={{ marginBottom: 16 }}>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Id-ul userului" value={userId} onSearch={this.handleFilterUserId} onChange={e => this.handleFilterUserId(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Email-ul userului" value={userEmail} onSearch={this.handleFilterUserEmail} onChange={e => this.handleFilterUserEmail(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Fname-ul userului" value={userFname} onSearch={this.handleFilterUserFname} onChange={e => this.handleFilterUserFname(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Lname-ul userului" value={userLname} onSearch={this.handleFilterUserLname} onChange={e => this.handleFilterUserLname(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Telefonul userului" value={userPhone} onSearch={this.handleFilterUserPhone} onChange={e => this.handleFilterUserPhone(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Codul de voucher de share al userului" value={userCreditsCode} onSearch={this.handleFilterUserCreditsCode} onChange={e => this.handleFilterUserCreditsCode(e.target.value)} />
            </Col>
          </Row>

          <Row type="flex" justify="space-between" gutter={24} style={{ marginBottom: 16 }}>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Id-ul vehiculului" value={vehicleId} onSearch={this.handleFilterVehicleId} onChange={e => this.handleFilterVehicleId(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="VIN-ul vehiculului" value={vehicleVin} onSearch={this.handleFilterVehicleVin} onChange={e => this.handleFilterVehicleVin(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Numărul de înmatriculare al vehiculului" value={vehicleRegistrationNumber} onSearch={this.handleFilterVehicleRegistrationNumber} onChange={e => this.handleFilterVehicleRegistrationNumber(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Brandul vehiculului" value={vehicleBrand} onSearch={this.handleFilterVehicleBrand} onChange={e => this.handleFilterVehicleBrand(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Modelul vehiculului" value={vehicleModel} onSearch={this.handleFilterVehicleModel} onChange={e => this.handleFilterVehicleModel(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Anul vehiculului" value={vehicleYear} onSearch={this.handleFilterVehicleYear} onChange={e => this.handleFilterVehicleYear(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Id-ul Autopia al vehiculului" value={vehicleExternalId} onSearch={this.handleFilterVehicleExternalId} onChange={e => this.handleFilterVehicleExternalId(e.target.value)} />
            </Col>
          </Row>

          <Row type="flex" justify="space-between" gutter={24} style={{ marginBottom: 16 }}>
            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
              <Input.Search placeholder="Id-ul RCA-ului" value={rcaId} onSearch={this.handleFilterRcaId} onChange={e => this.handleFilterRcaId(e.target.value)} />
            </Col>
            <Col xs={24} sm={12} md={16} lg={20} xl={20} style={{ textAlign: 'center' }}>
              <Button
                icon="delete"
                style={{ width: '50%' }}
                onClick={this.handleRemoveFilters}
              >
                Șterge filtre
              </Button>
            </Col>
          </Row>

          <Row type="flex" justify="space-between" gutter={24} style={{ marginBottom: 16 }}>
            
          </Row>

          <DatabaseSearchTable
            isLoading={isLoading}
            data={dataFiltered}
          />
        </Spin>
      </div>
    );
  }
}
