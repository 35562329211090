import React, { Component } from "react";
import Helmet from "react-helmet";
import {
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Button,
  message,
  Select
} from "antd";
import _ from "lodash";
import { getServiceCompanies, addServiceBranch, geocode, findPlaceId } from "../../../api";
import Map from "../../Map/Map";
import config from "../../../config";
import NProgress from 'nprogress';

import "./styles.css";

class ServicesAddBranch extends Component {
  state = {
    lat: 45.148218,
    lng: 26.815176,
    existingServiceCompanies: [],
    placeIdData: null,
    isAdding: false
  };

  componentDidMount = () => {
    NProgress.start();
    getServiceCompanies()
      .then(resp => {
        this.setState({
          existingServiceCompanies: this.filterSameServiceCompanies(resp.data),
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        NProgress.done();
      });
  };

  filterSameServiceCompanies = data => {
    if (!_.isArray(data)){
      return [];
    }
    const unique = _.uniqBy(data, o => 
      `${o.name} ${o.code} ${o.redCode} ${o.vat} ${o.country} ${o.county} ${o.address} ${o.postalCode} ${o.bankName} ${o.bankBranch} ${o.iban} ${o.feePercent} ${o.feePercentOwnClients} ${o.percentageHasVAT} ${o.personFirstName} ${o.personLastName} ${o.personPhone} ${o.personEmail} ${o.username} ${o.password} ${o.sellerId}`
    )

    const constructed = _.map(unique, o => ({
      id: o.id,
      text: `${o.name} ${o.code} ${o.redCode} ${o.vat} ${o.country} ${o.county} ${o.address} ${o.postalCode} ${o.bankName} ${o.bankBranch} ${o.iban} ${o.feePercent} ${o.feePercentOwnClients} ${o.percentageHasVAT} ${o.personFirstName} ${o.personLastName} ${o.personPhone} ${o.personEmail} ${o.username} ${o.password} ${o.sellerId}`
    }))

    constructed.sort((o1, o2) => o1.text.localeCompare(o2.text));
    return constructed;
  }

  handleSaveNewService = () => {
    const { form, history } = this.props;
    const { lat, lng } = this.state;

    form.validateFields((err, values) => {
      if (err) return;

      const serviceSpecific = values.specific.join(",");
      const newServiceValues = _.pickBy(
        _.omit(
          _.assign(values, {
            mecanica: serviceSpecific.indexOf("MECANICA") > -1,
            electrica: serviceSpecific.indexOf("ELECTRICA") > -1,
            vopsitorie: serviceSpecific.indexOf("VOPSITORIE") > -1,
            itp: serviceSpecific.indexOf("ITP") > -1,
            tuning: serviceSpecific.indexOf("TUNING") > -1,
            lat: lat,
            lng: lng,
          }),
          ["specific"]
        )
      );

      this.setState({ isAdding: true });

      addServiceBranch(Object.assign({}, newServiceValues))
        .then(response => {
          message.success("Filială service adăugată cu succes!");

          history.push("/services");
        })
        .catch(error => {
          this.setState({ isAdding: false });
          if (error.response)
            switch (error.response.status) {
              case 400: // Bad Request
                message.error("Verificați corectitudinea completării datelor.");
                break;
              default: {
                const { code } = error.response.data;
                if (code) {
                  message.error(`Eroare: ${code}`);
                } else {
                  message.error("Eroare.");
                }
                console.log(error);
              }
            }
          else {
            message.error("Eroare.");
            console.log(error);
          }
        });
    });
  };

  geocodeAddress = address => {
    geocode(address)
      .then(response => {
        this.setState({
          ...response.data.results[0].geometry.location,
          isAddressFound: true
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  findPlaceId = search => {
    const { form } = this.props;
    findPlaceId(search)
      .then(response => {
        this.setState({
          placeIdData: response.data
        });
        form.setFieldsValue({
          placeId: response.data.placeId
        });
      })
      .catch(error => {
        console.error(error);
        message.error("A avut loc o eroare la căutarea place ID pentru Google");
      });
  };

  handleOnMarkerDragEnd = event => {
    try {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      console.log("Lat", lat);
      console.log("Lng", lng);
      if (lat && lng) {
        this.setState({ lat, lng });
      }
    } catch {
      console.log("Received onDragEnd event", event);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { lat, lng, placeIdData, isAdding, existingServiceCompanies } = this.state;

    return (
      <div className={"service-add-page"}>
        <Helmet title="Adaugă o filială service nou" />
        <Row type="flex" justify="space-between" className={"section"}>
          <Col>
            <h3>Adaugă o filială service nouă</h3>
          </Col>
          <Col>
            <Button
              type="primary"
              icon="plus"
              onClick={this.handleSaveNewService}
              loading={isAdding}
            >
              Salvează
            </Button>
          </Col>
        </Row>
        <Form onSubmit={this.handleSaveNewService}>
          <h2>Date vizibile în aplicație</h2>
          <Row type={"flex"} gutter={16} className={"section"}>
            <Col span={12}>
              <Form.Item label="Denumire" hasFeedback>
                {getFieldDecorator("name", {
                  initialValue: "SC SERVICE-UL MEU S.R.L.", // TODO: Remove in production
                  rules: [
                    {
                      required: true,
                      message: "Denumirea este obligatorie!"
                    },
                    {
                      min: 3,
                      message: "Denumirea trebuie să conțină minim 3 caractere."
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Adresa">
                {getFieldDecorator("address", {
                  initialValue: "Bld. Unrii, bl. 46C", // TODO: Remove in production
                  rules: [
                    {
                      required: true,
                      message: "Adresa este obligatorie!"
                    }
                  ]
                })(<Input.Search onSearch={this.geocodeAddress} enterButton />)}
              </Form.Item>
              <Form.Item label="Telefon contact" hasFeedback>
                {getFieldDecorator("phone", {
                  initialValue: "0700110011", // TODO: Remove in production
                  rules: [
                    {
                      required: true,
                      message: "Telefonul de contact este obligatoriu!"
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Email contact" hasFeedback>
                {getFieldDecorator("email", {
                  initialValue: "contact@service.ro", // TODO: Remove in production
                  rules: [
                    {
                      type: "email",
                      message: "Email-ul nu este valid!"
                    },
                    {
                      required: true,
                      message: "Email-ul de contact este obligatoriu!"
                    }
                  ]
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Facilități" hasFeedback>
                {getFieldDecorator("specific", {
                  rules: [
                    {
                      required: true,
                      message: "Specificul este obligatoriu!"
                    }
                  ]
                })(
                  <Checkbox.Group
                    options={[
                      { label: "Mecanică", value: "MECANICA" },
                      { label: "Electrică", value: "ELECTRICA" },
                      { label: "Vopsitorie/Tinichigerie", value: "VOPSITORIE" },
                      { label: "ITP", value: "ITP" },
                      { label: "Tuning", value: "TUNING" }
                    ]}
                    style={{ width: "100%" }}
                  />
                )}
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Review-uri Google termeni căutare">
                    <Input.Search defaultValue="Bld. Unrii, bl. 46C" onSearch={this.findPlaceId} enterButton />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Review-uri Google place ID">
                    {getFieldDecorator("placeId", {
                      initialValue: null,
                    })(<Input disabled suffix={
                      <Button icon="delete" onClick={() => {
                        this.setState({
                          placeIdData: null
                        })
                        this.props.form.resetFields(['placeId'])
                      }} />
                    } />)}
                  </Form.Item>
                </Col>
                {placeIdData && (
                  <Col span={24}>
                    <div>{`Nume: ${placeIdData.name}, Adresă: ${placeIdData.formattedAddress}`}</div>
                    <div>{`Rating: ${placeIdData.rating}, Număr review-uri: ${placeIdData.userRatingsTotal}`}</div>
                    <div>{`URL Maps: `}<a href={placeIdData.url}>{placeIdData.url}</a></div>
                  </Col>
                )}
              </Row>
              
            </Col>
            <Col span={12}>
              <Map
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.application.GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: "475px" }} />}
                mapElement={<div style={{ height: `100%` }} />}
                centerLat={lat}
                centerLng={lng}
                isMarkerShown={true}
                handleOnDragEnd={this.handleOnMarkerDragEnd}
                extra={placeIdData ? [{ lat: placeIdData.lat, lng: placeIdData.lng }] : null}
              />
            </Col>
          </Row>
        </Form>

        <h2>Date facturare</h2>
        <Row type={"flex"} gutter={16}>
          <Col span={24}>
            <Form.Item label="Companie service" hasFeedback>
              {getFieldDecorator("serviceCompanyId", {
                initialValue: null,
                rules: [
                  {
                    required: true,
                    message: "Compania service este obligatorie!"
                  }
                ]
              })(
                <Select 
                  showSearch onChange={this.handleChangeVoucherType} style={{ width: '100%' }}
                  filterOption={(input, option) => 
                    (option && option.props && option.props.children && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  }
                >
                  {_.map(existingServiceCompanies, existingServiceCompany => 
                    <Select.Option key={existingServiceCompany.id} value={existingServiceCompany.id}>{existingServiceCompany.text}</Select.Option>
                  )}
                </Select>
              )}
            </Form.Item>  
          </Col>
        </Row>
      </div>
    );
  }
}

export default Form.create()(ServicesAddBranch);
