import React, { Component } from "react";
import Helmet from "react-helmet";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Select,
} from "antd";
import _ from "lodash";
import { getServices, createPartsCredentials } from "../../../api";
import "./styles.css";
import { connect } from 'react-redux';

const { Option } = Select;

class PartsCredentialsAdd extends Component {
  state = {
    isAdding: false,

    services: [],
    selectedService: null
  };

  componentDidMount = () => {
    getServices()
      .then(response => {
        this.setState({
          services: _.map(response.data, item => ({
            id: item.id,
            name: item.name
          }))
        });
      })
      .catch(error => console.error(error));    
  };

  handleSaveNewPartsCredential = () => {
    const { form, history } = this.props;

    form.validateFields((err, newValues) => {
      if (err) return;

      const body = {
        service: newValues.service,
        supplier: newValues.supplier,
        username: newValues.username,
        password: newValues.password,
      };

      this.setState({ isAdding: true });

      createPartsCredentials(body)
        .then(response => {
          message.success("Operație efectuată cu succes!");
          history.push("/partscredentials");
        })
        .catch(error => {
          this.setState({ isAdding: false });
          message.error("Verificați corectitudinea completării datelor.");
        });

    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isAdding, services } = this.state;

    return (
      <div className={"credential-add-page"}>
        <Helmet title="Adaugă o credențială"/>
        <Row type="flex" justify="space-between" className={"section"}>
          <Col>
            <h3>Adaugă o credențială</h3>
          </Col>
          <Col>
            <Button
              type="primary"
              icon="plus"
              onClick={this.handleSaveNewPartsCredential}
              loading={isAdding}
            >
              Salvează si adaugă credențială
            </Button>
          </Col>
        </Row>


        <Form>
          <h2>Date despre credențială</h2>
          <Row type={"flex"} gutter={16} className={"section"}>
            <Col span={6}>
              <Form.Item label="Service" hasFeedback>
                {getFieldDecorator("service", {
                  rules: [
                    {
                      required: true,
                      message: 'Service-ul este obligatoriu'
                    }
                  ]
                })(
                  <Select>
                    {_.map(services, service => (
                      <Option value={service.id}>{service.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Supplier" hasFeedback>
                {getFieldDecorator("supplier", {
                  rules: [
                    {
                      required: true,
                      message: 'Supplier-ul este obligatoriu'
                    }
                  ]
                })(
                  <Select>
                    <Option value="eoriginal">eoriginal.ro</Option>
                    <Option value="webcat-solutions">webcat-solutions.com</Option>
                    <Option value="euroestcar">euroestcar</Option>
                    <Option value="aicat">aicat (augsburg)</Option>
                    <Option value="intercars">intercars</Option>
                    <Option value="unix">unix</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Username" hasFeedback>
                {getFieldDecorator("username", {
                  initialValue: null,
                  rules: [
                    {
                      required: true,
                      message: 'Username-ul este obligatoriu'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Parolă" hasFeedback>
                {getFieldDecorator("password", {
                  initialValue: null,
                  rules: [
                    {
                      required: true,
                      message: 'Parola-ul este obligatorie'
                    }
                  ]
                })(<Input />)}
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, null)(Form.create()(PartsCredentialsAdd));
