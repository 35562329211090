import React, { Component } from 'react';
import { Row, Col, Table, Button, Tooltip } from 'antd';
import { getAllNotifications } from '../../../api';

export default class DashboardPage extends Component {
  state = {
    notifications: [],
    isLoading: false
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    getAllNotifications()
      .then(response => {
        console.log(response.data);
        this.setState({ notifications: response.data, isLoading: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleGoToAddNotification = () => {
    const { history } = this.props;

    history.push('/notifications/add');
  };

  tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'De la',
      dataIndex: 'from',
      key: 'from'
    },
    {
      title: 'Către',
      key: 'to',
      render: (text, record) => (
        <span
          className={'a-link'}
          onClick={() => this.props.history.push(`/services/${record.to}`)}
        >
          {record.to}
        </span>
      )
    },
    {
      title: 'Mesaj',
      key: 'message',
      render: (text, record) => (
        <Tooltip title={record.message}>
          {record.message && (
            <span>{record.message.substring(0, 50) + '...'}</span>
          )}
        </Tooltip>
      )
    }
  ];
  render() {
    const { notifications, isLoading } = this.state;

    return (
      <div style={{ padding: 8 }}>
        <Row type="flex" justify="end" style={{ marginBottom: '20px' }}>
          <Col>
            <Button
              icon="plus"
              type="primary"
              onClick={this.handleGoToAddNotification}
            >
              Adaugă notificare
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              bordered
              dataSource={notifications}
              loading={isLoading}
              columns={this.tableColumns}
              scroll={{ x: 'max-content' }}
              rowKey="id"
              pagination={{ pageSize: 15 }}
              style={{ background: 'white' }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
