import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, Select, Row, Col } from "antd";

/**
 * Form for creating a new user for a specific service
 */
class AddUserForm extends Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
  };

  handleSaveUser = () => {
    const { validateFields } = this.props.form;

    validateFields((err, values) => {
      if (err) return;

      this.props.onSave(values);
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="inline">
        <h4>Formular adăugare utilizator</h4>
        <Row type="flex" gutter={16} justify="space-between">
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: "100%" }}
              label="Nume"
            >
              {getFieldDecorator("lname", {
                rules: [{ required: true, message: "Introduceți numele." }]
              })(<Input placeholder="Nume" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: "100%" }}
              label="Prenume"
            >
              {getFieldDecorator("fname", {
                rules: [{ required: true, message: "Introduceți prenumele." }]
              })(<Input placeholder="Prenume" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: "100%" }}
              label="Email"
            >
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Introduceți email-ul." },
                  { type: "email", message: "Email-ul este invalid" }
                ]
              })(<Input placeholder="Email" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: "100%" }}
              label="Parola"
            >
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "Introduceți parola." }]
              })(<Input.Password placeholder="Parola" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: "100%" }}
              label="Rol"
            >
              {getFieldDecorator("type", {
                rules: [{ required: true, message: "Selectați rolul" }]
              })(
                <Select style={{ width: "100%" }} placeholder="Rol">
                  <Select.Option value="SERVICE">
                    Consilier service
                  </Select.Option>
                  <Select.Option value="MECHANIC">Mecanic</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ width: "100%" }}
              label="Telefon (cu +40... la consilier)"
            >
              {getFieldDecorator("phone", {
              })(<Input placeholder="+40123456789" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          gutter={32}
          style={{ marginTop: "8px" }}
        >
          <Col span={4}>
            <Button style={{ width: "100%" }} onClick={this.props.onCancel}>
              Renunță
            </Button>
          </Col>
          <Col span={4}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={this.handleSaveUser}
            >
              Salvează utilizator nou
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(AddUserForm);
