import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Popconfirm } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import voucherCashbackPointsTypesText from "../../../utils/voucherCashbackPointsTypesText";


const createTableColumns = (onDeleteVoucherCashbackPointsClick) => {
  let tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Creat la',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user - b.user,
    },
    {
      title: 'Tip',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type - b.type,
      render: (text, record) => _.find(voucherCashbackPointsTypesText, {id: record.type}).text
    },
    {
      title: 'Vehicul',
      dataIndex: 'vehicle',
      key: 'vehicle',
      sorter: (a, b) => (a.vehicle === null ? 0 : a.vehicle) - (b.vehicle === null ? 0 : b.vehicle),
    },
    {
      title: 'Programare',
      dataIndex: 'appointment',
      key: 'appointment',
      sorter: (a, b) => (a.appointment === null ? 0 : a.appointment) - (b.appointment === null ? 0 : b.appointment),
    },
    {
      title: 'Operație',
      dataIndex: 'operation',
      key: 'operation',
      sorter: (a, b) => (a.operation === null ? 0 : a.operation) - (b.operation === null ? 0 : b.operation),
    },
    {
      title: 'Cerere RCA',
      dataIndex: 'rcarequest',
      key: 'rcarequest',
      sorter: (a, b) => (a.rcarequest === null ? 0 : a.rcarequest) - (b.rcarequest === null ? 0 : b.rcarequest),
    },
    {
      title: 'User pe care a recomandat',
      dataIndex: 'userRecommended',
      key: 'userRecommended',
      sorter: (a, b) => (a.userRecommended === null ? 0 : a.userRecommended) - (b.userRecommended === null ? 0 : b.userRecommended),
    },
    {
      title: 'Service pe care a recomandat',
      dataIndex: 'serviceRecommended',
      key: 'serviceRecommended',
      sorter: (a, b) => (a.serviceRecommended === null ? 0 : a.serviceRecommended) - (b.serviceRecommended === null ? 0 : b.serviceRecommended),
    },
    {
      title: 'Puncte oferite',
      dataIndex: 'points',
      key: 'points',
      sorter: (a, b) => a.points - b.points,
    },
    {
      title: 'Șters la',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      sorter: (a, b) => (a.deletedAt === null ? '' : a.deletedAt).localeCompare(b.deletedAt === null ? '' : b.deletedAt),
      render: (text, record) => record.deletedAt ? moment(record.deletedAt).format('DD.MM.YYYY HH:mm:ss') : ''
    },
    {
      title: 'Acțiuni',
      key: 'action',
      render: (text, record) => (
        record.deletedAt ? null : (
          <Popconfirm
            title="Ești sigur că vrei să ștergi acest voucher cashback points?"
            onConfirm={() => onDeleteVoucherCashbackPointsClick(record.id)}
          >
            <Button type={'danger'}>
              Șterge
            </Button>
          </Popconfirm>
        )
      )
    }
  ];

  return tableColumns;
}

const VouchersCashbackPointsTable = ({
  isLoading,
  vouchersCashbackPoints,
  onDeleteVoucherCashbackPointsClick
}) => {
  
  const tableColumns = createTableColumns(onDeleteVoucherCashbackPointsClick)
  return (
    <Table
      bordered
      dataSource={vouchersCashbackPoints}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 30 }}
    />
  );
};

VouchersCashbackPointsTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  vouchersCashbackPoints: PropTypes.array.isRequired,
  onDeleteVoucherCashbackPointsClick: PropTypes.func.isRequired,
};

export default VouchersCashbackPointsTable;
