import React, { Component } from 'react';
import {
  getService,
  editService,
  enableService,
  disableService,
  addServiceRamp,
  editServiceRamp,
  deleteServiceRamp,
  addServiceUser,
  enableSmsForUser,
  changeServiceManagerCode,
} from '../../../api';
import {
  Row,
  Col,
  Spin,
  Divider,
  Checkbox,
  Tag,
  Table,
  Icon,
  Input,
  Button,
  Form,
  Modal,
  Popconfirm,
  message,
  Menu,
  Dropdown,
  InputNumber,
  Popover,
} from 'antd';
import { Carousel } from 'react-responsive-carousel';
import _ from 'lodash';
import * as moment from 'moment';
import { StyleSheet, css } from 'aphrodite';
import { diff } from 'deep-object-diff';
import AddServiceRampForm from './AddServiceRampForm';
import AddUserForm from './AddUserForm';
import Map from '../../Map/Map';
import config from '../../../config';

class ServiceProfile extends Component {
  static propTypes = {};

  state = {
    initialService: null,
    service: null,
    isLoading: false,
    isSaveAvailable: false,
    isEditingAddress: false,
    isEditingDescription: false,
    isEditingPhone: false,
    isEditingEmail: false,
    isEditingFbUrl: false,
    isEditingSold: false,
    isAddingServiceRamp: false,
    isAddingUser: false,
    lat: null,
    lng: null,
  };

  componentDidMount = () => {
    this.loadServiceInfo();
  };

  loadServiceInfo = () => {
    const { history, match } = this.props;
    const { serviceId } = match.params;
    this.setState({ isLoading: true });
    getService(serviceId)
      .then((response) => {
        console.log(response.data);
        this.setState({
          service: _.cloneDeep(response.data),
          initialService: _.cloneDeep(response.data),
          isLoading: false,
          lat: response.data.lat,
          lng: response.data.lng,
        });
      })
      .catch((error) => {
        console.error(error);
        history.push('/');
      });
  };

  serviceRampsTableColumns = [
    {
      title: 'Nume',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tip',
      key: 'type',
      align: 'center',
      // render: (text, record) => <Tag>{record.type}</Tag>
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <span
                  className={'a-link'}
                  onClick={() =>
                    this.changeServiceRampType(
                      this.state.service.id,
                      record.id,
                      'MECANICA'
                    )
                  }
                >
                  Schimbă în Mecanică
                </span>
              </Menu.Item>
              <Menu.Item>
                <span
                  className={'a-link'}
                  onClick={() =>
                    this.changeServiceRampType(
                      this.state.service.id,
                      record.id,
                      'ELECTRICA'
                    )
                  }
                >
                  Schimbă în Electrică
                </span>
              </Menu.Item>
              <Menu.Item>
                <span
                  className={'a-link'}
                  onClick={() =>
                    this.changeServiceRampType(
                      this.state.service.id,
                      record.id,
                      'VOPSITORIE'
                    )
                  }
                >
                  Schimbă în Vopsitorie
                </span>
              </Menu.Item>{' '}
              <Menu.Item>
                <span
                  className={'a-link'}
                  onClick={() =>
                    this.changeServiceRampType(
                      this.state.service.id,
                      record.id,
                      'ITP'
                    )
                  }
                >
                  Schimbă în ITP
                </span>
              </Menu.Item>
              <Menu.Item>
                <span
                  className={'a-link'}
                  onClick={() =>
                    this.changeServiceRampType(
                      this.state.service.id,
                      record.id,
                      'TUNING'
                    )
                  }
                >
                  Schimbă în Tuning
                </span>
              </Menu.Item>
            </Menu>
          }
        >
          <span className={'a-link'}>
            {record.type === 'MECANICA'
              ? 'Mecanică'
              : record.type === 'ELECTRICA'
              ? 'Electrică'
              : record.type === 'VOPSITORIE'
              ? 'Vopsitorie'
              : record.type === 'ITP'
              ? 'ITP'
              : record.type === 'TUNING'
              ? 'Tuning'
              : record.type}
            <Icon type="down" className={css(styles.editIcon)} />
          </span>
        </Dropdown>
      ),
    },
    {
      title: 'Mecanic titular',
      key: 'mechanic',
      align: 'center',
      render: (text, record) => {
        const { service } = this.state;
        const mechanic = _.find(
          service.mechanics,
          (mechanic) => mechanic.id === record.mechanic
        );
        return (
          <Dropdown
            overlay={
              <Menu>
                {_.map(service.mechanics, (mechanic) => (
                  <Menu.Item key={mechanic.id}>
                    <span
                      className={'a-link'}
                      onClick={() =>
                        this.changeServiceRampMechanic(
                          service.id,
                          record.id,
                          mechanic.id
                        )
                      }
                    >
                      Schimbă în {mechanic.fname} {mechanic.lname}
                    </span>
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <span className={'a-link'}>
              {mechanic.fname + ' ' + mechanic.lname}{' '}
              <Icon type="down" className={css(styles.editIcon)} />
            </span>
          </Dropdown>
        );
      },
    },
    {
      title: 'Acțiuni',
      key: 'action',
      render: (text, record) => (
        <Popconfirm
          title={
            <div>
              <p>Ești sigur că vrei să ștergi rampa selectată?</p>
              <p>
                În același timp va fi șters și istoricul tuturor mecanicilor ce
                au lucrat pe această rampă!
              </p>
            </div>
          }
          onConfirm={() => this.handleDeleteServiceRamp(record.id)}
          okText="Da"
          okType="danger"
          cancelText="Nu"
        >
          <span className={'a-link'} style={{ color: '#f5222d' }}>
            Șterge
          </span>
        </Popconfirm>
      ),
    },
  ];

  usersTableColumns = [
    {
      title: 'Nume',
      dataIndex: 'fname',
      key: 'fname',
    },
    {
      title: 'Prenume',
      dataIndex: 'lname',
      key: 'lname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Primește SMS',
      dataIndex: 'serviceSms',
      key: 'serviceSms',
      render: (text, record) => <Checkbox checked={record.serviceSms} />,
    },
    {
      title: 'Acțiuni',
      key: 'action',
      render: (text, record) =>
        record.serviceSms ? (
          <Button
            type={'link'}
            onClick={() => this.handleEnableSms(record.id, false)}
          >
            Dezactivează SMS
          </Button>
        ) : (
          <Button
            type={'link'}
            onClick={() => this.handleEnableSms(record.id, true)}
          >
            Activează SMS
          </Button>
        ),
    },
  ];

  handleEnableSms = (userId, enable) => {
    this.setState({ isLoading: true });
    enableSmsForUser(userId, enable)
      .then((response) => {
        message.success('Setările de SMS au fost schimbate!');
        this.loadServiceInfo();
      })
      .catch((error) => {
        console.error(error);
        message.error('A apărut o eroare la schimbarea setărilor de SMS.');
        this.setState({ isLoading: false });
      });
  };

  handleDeleteServiceRamp = (serviceRampId) => {
    const { service } = this.state;
    this.setState({ isLoading: true });
    deleteServiceRamp(service.id, serviceRampId)
      .then((response) => {
        message.success('Rampa a fost ștearsă cu succes!');
        this.loadServiceInfo();
      })
      .catch((error) => {
        console.error(error);
        message.error('A apărut o eroare la ștergerea rampei.');
        this.setState({ isLoading: false });
      });
  };

  changeServiceRampMechanic = (serviceId, serviceRampId, mechanicId) => {
    this.setState({ isLoading: true });

    editServiceRamp(serviceId, serviceRampId, { mechanic: mechanicId })
      .then((response) => {
        this.loadServiceInfo();
        message.success('Rampa a fost editată cu succes!');
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
        message.error('A apărut o eroare la editarea rampei.');
      });
  };

  changeServiceRampType = (serviceId, serviceRampId, type) => {
    this.setState({ isLoading: true });

    editServiceRamp(serviceId, serviceRampId, { type })
      .then((response) => {
        this.loadServiceInfo();
        message.success('Rampa a fost editată cu succes!');
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isLoading: false });
        message.error('A apărut o eroare la editarea rampei.');
      });
  };

  // ADDRESS
  handleAddressEditClick = () => {
    this.setState({ isEditingAddress: true, isSaveAvailable: true });
  };
  handleSaveAddress = () => {
    const { form } = this.props;
    const address = form.getFieldValue('address');
    this.setState({
      service: _.assign(this.state.service, { address }),
      isEditingAddress: false,
    });
  };

  // DESCRIPTION
  handleDescriptionEditClick = () => {
    this.setState({ isEditingDescription: true, isSaveAvailable: true });
  };
  handleSaveDescription = () => {
    const { form } = this.props;
    const description = form.getFieldValue('description');
    this.setState({
      service: _.assign(this.state.service, { description }),
      isEditingDescription: false,
    });
  };

  // PHONE
  handlePhoneEditClick = () => {
    this.setState({ isEditingPhone: true, isSaveAvailable: true });
  };
  handleSavePhone = () => {
    const { form } = this.props;
    const phone = form.getFieldValue('phone');
    this.setState({
      service: _.assign(this.state.service, { phone }),
      isEditingPhone: false,
    });
  };

  // EMAIL
  handleEmailEditClick = () => {
    this.setState({ isEditingEmail: true, isSaveAvailable: true });
  };
  handleSaveEmail = () => {
    const { form } = this.props;
    const email = form.getFieldValue('email');
    this.setState({
      service: _.assign(this.state.service, { email }),
      isEditingEmail: false,
    });
  };

  // FBURL
  handleFbUrlEditClick = () => {
    this.setState({ isEditingFbUrl: true, isSaveAvailable: true });
  };
  handleSaveFbUrl = () => {
    const { form } = this.props;
    const fbUrl = form.getFieldValue('fburl');
    this.setState({
      service: _.assign(this.state.service, { fbUrl }),
      isEditingFbUrl: false,
    });
  };

  // SOLD
  handleSoldEditClick = () => {
    this.setState({ isEditingSold: true, isSaveAvailable: true });
  };
  handleSaveSold = () => {
    const { form } = this.props;
    const sold = form.getFieldValue('sold');
    this.setState({
      service: _.assign(this.state.service, { sold }),
      isEditingSold: false,
    });
  };

  handleSaveManagerCode = () => {
    const { form } = this.props;
    const mgc = form.getFieldValue('mgc');
    this.setState({ isLoading: true });
    changeServiceManagerCode(this.state.service.id, mgc)
      .then((response) => {
        message.success('Codul de manager a fost schimbat!');
        this.loadServiceInfo();
      })
      .catch((error) => {
        console.error(error);
        message.error('A apărut o eroare la schimbarea codului de manager.');
        this.setState({ isLoading: false });
      });
  };

  handleSaveModifications = () => {
    const { initialService, service, lat, lng } = this.state;
    console.log(initialService);
    console.log(service);
    console.log(diff(initialService, service));
    const differences = diff(
      initialService,
      Object.assign({}, service, { lat, lng })
    );
    Modal.confirm({
      title: 'Confirmați salvarea modificărilor',
      content: (
        <div>
          <p>Următoarele modificări vor fi salvate:</p>
          {_.map(_.keys(differences), (key) => (
            <p
              key={key}
              style={{
                display: 'block',
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
            >
              {this.parseDifference(key, differences[key])}
            </p>
          ))}
        </div>
      ),
      onOk: () => {
        this.setState({ isLoading: true });
        editService(service.id, differences)
          .then((response) => {
            message.success('Modificările au fost salvate cu succes!');
            this.loadServiceInfo();
          })
          .catch((error) => {
            message.error('A apărut o eroare la salvarea datelor.');
            console.error(error);
            this.setState({ isLoading: false });
          });
      },
    });
  };

  parseDifference = (key, value) => {
    var text = '';
    switch (key) {
      case 'description':
        text += `Descriere nouă:
${value}`;
        break;
      case 'mecanica':
        text += `Mecanică: ${value ? 'DA' : 'NU'}`;
        break;
      case 'electrica':
        text += `Electrică: ${value ? 'DA' : 'NU'}`;
        break;
      case 'vopsitorie':
        text += `Vopsitorie: ${value ? 'DA' : 'NU'}`;
        break;
      case 'itp':
        text += `ITP: ${value ? 'DA' : 'NU'}`;
        break;
      case 'tuning':
        text += `Tuning: ${value ? 'DA' : 'NU'}`;
        break;
      case 'phone':
        text += `Telefon: ${value}`;
        break;
      case 'email':
        text += `Email: ${value}`;
        break;
      case 'fbUrl':
        text += `Pagină Facebook: ${value}`;
        break;
      case 'address':
        text += `Adresă: ${value}`;
        break;
      case 'sold':
        text += `Sold: ${value}`;
        break;
      default:
        text += `${key} => ${value}`;
    }
    return text;
  };

  handleEnableService = () => {
    const { service } = this.state;
    this.setState({ isLoading: true });
    enableService(service.id)
      .then((response) => {
        message.success('Service-ul a fost activat cu succes!');
        this.loadServiceInfo();
      })
      .catch((error) => {
        message.error('A apărut o eroare la activarea service-ului!');
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  handleDisableService = () => {
    const { service } = this.state;
    this.setState({ isLoading: true });
    disableService(service.id)
      .then((response) => {
        message.success('Service-ul a fost dezactivat cu succes!');
        this.loadServiceInfo();
      })
      .catch((error) => {
        message.error('A apărut o eroare la dezactivarea service-ului!');
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  handleSwitchMecanica = (e) => {
    this.setState({
      service: _.assign(this.state.service, { mecanica: e.target.checked }),
      isSaveAvailable: true,
    });
  };

  handleSwitchElectrica = (e) => {
    this.setState({
      service: _.assign(this.state.service, { electrica: e.target.checked }),
      isSaveAvailable: true,
    });
  };

  handleSwitchVopsitorie = (e) => {
    this.setState({
      service: _.assign(this.state.service, { vopsitorie: e.target.checked }),
      isSaveAvailable: true,
    });
  };
  handleSwitchITP = (e) => {
    this.setState({
      service: _.assign(this.state.service, { itp: e.target.checked }),
      isSaveAvailable: true,
    });
  };
  handleSwitchTuning = (e) => {
    this.setState({
      service: _.assign(this.state.service, { tuning: e.target.checked }),
      isSaveAvailable: true,
    });
  };
  handleSwitchComparator = (e) => {
    this.setState({
      service: _.assign(this.state.service, { comparator: e.target.checked }),
      isSaveAvailable: true,
    });
  };

  handleSwitchSmsExternalAppointments = (e) => {
    this.setState({
      service: _.assign(this.state.service, {
        smsExternalAppointments: e.target.checked,
      }),
      isSaveAvailable: true,
    });
  };

  handleAddServiceRamp = (values) => {
    console.log(values);
    const { service } = this.state;
    this.setState({ isLoading: true });
    addServiceRamp(service.id, values)
      .then((response) => {
        message.success('Rampa a fost adăugată cu succes!');
        this.setState({ isAddingServiceRamp: false });
        this.loadServiceInfo();
      })
      .catch((error) => {
        message.error('A apărut o eroare la adăugarea rampei!');
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  /**
   * Creates new user
   */
  handleAddUser = (values) => {
    console.log(values);
    const { service } = this.state;
    this.setState({ isLoading: true });
    addServiceUser(
      service.id,
      Object.assign({}, values, { service: service.id })
    )
      .then((response) => {
        message.success('Utilizatorul a fost adăugat cu succes!');
        this.setState({ isAddingUser: false });
        this.loadServiceInfo();
      })
      .catch((error) => {
        message.error('A apărut o eroare la adăugarea utilizatorului!');
        console.error(error);
        this.setState({ isLoading: false });
      });
  };

  handleCancelAddServiceRamp = () => {
    this.setState({ isAddingServiceRamp: false });
  };
  handleCancelAddUser = () => {
    this.setState({ isAddingUser: false });
  };

  handleShowAddServiceRampForm = () => {
    this.setState({ isAddingServiceRamp: true });
  };
  handleShowAddUserForm = () => {
    this.setState({ isAddingUser: true });
  };

  handleOnMarkerDragEnd = (event) => {
    try {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      console.log('Lat', lat);
      console.log('Lng', lng);
      if (lat && lng) {
        this.setState({ lat, lng });
      }
    } catch {
      console.log('Received onDragEnd event', event);
    }
  };

  render() {
    const {
      service,
      isLoading,
      isSaveAvailable,
      isEditingAddress,
      isEditingDescription,
      isEditingPhone,
      isEditingEmail,
      isEditingFbUrl,
      isEditingSold,
      isAddingServiceRamp,
      isAddingUser,
      lat,
      lng,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <Spin spinning={isLoading} tip="Se încarcă...">
          <Form layout="inline">
            {/* HEADER ROW */}
            {service && (
              <Row type="flex" align="middle">
                {/* IMAGES COLUMN */}
                <Col span={6}>
                  <Carousel
                    showThumbs={false}
                    showArrows={true}
                    onClickItem={(item) => {
                      console.log(item);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {_.map(service.images, (image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt="profile_picture"
                          style={{ height: '200px', width: 'auto' }}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <div style={{ paddingTop: '4px' }}>
                    <small>
                      Adăugat la data de{' '}
                      {moment
                        .utc(service.createdAt)
                        .local()
                        .format('YYYY-MM-DD HH:mm [GMT]Z')}
                    </small>
                  </div>
                  <div style={{ paddingBottom: '4px' }}>
                    <small>
                      Ultima modificare efectuată la data de{' '}
                      {moment
                        .utc(service.updatedAt)
                        .local()
                        .format('YYYY-MM-DD HH:mm [GMT]Z')}
                    </small>
                  </div>
                </Col>
                {/* NAME COLUMN */}
                <Col
                  span={9}
                  style={{ textAlign: 'center', padding: '0 24px' }}
                >
                  <h3>
                    <strong>{service.name}</strong>
                  </h3>
                  {!isEditingAddress ? (
                    <div>
                      Adresă: {service.address}{' '}
                      <Icon
                        type="edit"
                        className={css(styles.editIcon)}
                        onClick={this.handleAddressEditClick}
                      />
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          height: '300px',
                          width: '500px',
                          display: 'block',
                        }}
                      >
                        <Map
                          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.application.GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={
                            <div style={{ height: '250px', width: '500px' }} />
                          }
                          mapElement={<div style={{ height: `100%` }} />}
                          centerLat={lat}
                          centerLng={lng}
                          isMarkerShown={true}
                          handleOnDragEnd={this.handleOnMarkerDragEnd}
                          showArrows
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Form.Item
                          style={{ flex: 1 }}
                          wrapperCol={{ span: 24 }}
                        >
                          {getFieldDecorator('address', {
                            initialValue: service.address,
                          })(<Input size="small" />)}
                        </Form.Item>
                        <Button
                          size="small"
                          type="primary"
                          onClick={this.handleSaveAddress}
                        >
                          Salvează
                        </Button>
                      </div>
                    </div>
                  )}

                  <div style={{ marginTop: '8px' }}>
                    {service.active === true ? (
                      <Tag color="green">ACTIV</Tag>
                    ) : (
                      <Tag color="red">INACTIV</Tag>
                    )}
                  </div>

                  <Divider
                    type="horizontal"
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                  />
                  {!isEditingDescription ? (
                    <div>
                      Descriere:
                      <p
                        style={{
                          display: 'block',
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {service.description}
                      </p>
                      <Icon
                        type="edit"
                        className={css(styles.editIcon)}
                        onClick={this.handleDescriptionEditClick}
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Form.Item wrapperCol={{ span: 24 }}>
                        {getFieldDecorator('description', {
                          initialValue: service.description,
                        })(
                          <Input.TextArea rows={5} style={{ width: '100%' }} />
                        )}
                      </Form.Item>
                      <Button
                        size="small"
                        type="primary"
                        onClick={this.handleSaveDescription}
                      >
                        Salvează
                      </Button>
                    </div>
                  )}
                </Col>
                {/* DETAILS COLUMN */}
                <Col span={9} style={{ textAlign: 'right', padding: '0 24px' }}>
                  <h4>
                    <strong>Facilități</strong>
                  </h4>
                  <p>
                    <span>
                      Mecanică:{' '}
                      <Checkbox
                        checked={service.mecanica}
                        onChange={this.handleSwitchMecanica}
                      />
                    </span>
                  </p>
                  <p>
                    <span>
                      Electrică:{' '}
                      <Checkbox
                        checked={service.electrica}
                        onChange={this.handleSwitchElectrica}
                      />
                    </span>
                  </p>
                  <p>
                    <span>
                      Vopsitorie:{' '}
                      <Checkbox
                        checked={service.vopsitorie}
                        onChange={this.handleSwitchVopsitorie}
                      />
                    </span>
                  </p>{' '}
                  <p>
                    <span>
                      ITP:{' '}
                      <Checkbox
                        checked={service.itp}
                        onChange={this.handleSwitchITP}
                      />
                    </span>
                  </p>
                  <p>
                    <span>
                      Tuning:{' '}
                      <Checkbox
                        checked={service.tuning}
                        onChange={this.handleSwitchTuning}
                      />
                    </span>
                  </p>
                  <h4>
                    <strong>Tools access</strong>
                  </h4>
                  <p>
                    <span>
                      Comparator:{' '}
                      <Checkbox
                        checked={service.comparator}
                        onChange={this.handleSwitchComparator}
                      />
                    </span>
                  </p>
                  <h4>
                    <strong>Date de contact</strong>
                  </h4>
                  <div>
                    {!isEditingPhone ? (
                      <span>
                        Număr de telefon: {service.phone}
                        <Icon
                          type="edit"
                          className={css(styles.editIcon)}
                          onClick={this.handlePhoneEditClick}
                        />
                      </span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Form.Item>
                          {getFieldDecorator('phone', {
                            initialValue: service.phone,
                          })(<Input size="small" />)}
                        </Form.Item>
                        <Button
                          size="small"
                          type="primary"
                          onClick={this.handleSavePhone}
                        >
                          Salvează
                        </Button>
                      </div>
                    )}
                  </div>
                  <div>
                    {!isEditingEmail ? (
                      <span>
                        Email: {service.email}
                        <Icon
                          type="edit"
                          className={css(styles.editIcon)}
                          onClick={this.handleEmailEditClick}
                        />
                      </span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Form.Item>
                          {getFieldDecorator('email', {
                            initialValue: service.email,
                          })(<Input size="small" />)}
                        </Form.Item>
                        <Button
                          size="small"
                          type="primary"
                          onClick={this.handleSaveEmail}
                        >
                          Salvează
                        </Button>
                      </div>
                    )}
                  </div>
                  <div>
                    {!isEditingFbUrl ? (
                      <span>
                        Pagină Facebook:{' '}
                        {service.fbUrl && (
                          <a
                            href={service.fbUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Link
                          </a>
                        )}
                        <Icon
                          type="edit"
                          className={css(styles.editIcon)}
                          onClick={this.handleFbUrlEditClick}
                        />
                      </span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Form.Item>
                          {getFieldDecorator('fburl', {
                            initialValue: service.fbUrl,
                          })(<Input size="small" />)}
                        </Form.Item>
                        <Button
                          size="small"
                          type="primary"
                          onClick={this.handleSaveFbUrl}
                        >
                          Salvează
                        </Button>
                      </div>
                    )}
                  </div>
                  <div>
                    {!isEditingSold ? (
                      <span>
                        Sold: <span>{service.sold}</span>
                        <Icon
                          type="edit"
                          className={css(styles.editIcon)}
                          onClick={this.handleSoldEditClick}
                        />
                      </span>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Form.Item>
                          {getFieldDecorator('sold', {
                            initialValue: service.sold,
                          })(<InputNumber size="small" />)}
                        </Form.Item>
                        <Button
                          size="small"
                          type="primary"
                          onClick={this.handleSaveSold}
                        >
                          Salvează
                        </Button>
                      </div>
                    )}
                  </div>
                  <div>
                    <Popover
                      content={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Form.Item>
                            {getFieldDecorator('mgc', {
                              initialValue: '',
                            })(<Input size="small" />)}
                          </Form.Item>
                          <Button
                            size="small"
                            type="primary"
                            onClick={this.handleSaveManagerCode}
                          >
                            Salvează
                          </Button>
                        </div>
                      }
                      title="Schimbă codul de manager"
                      trigger="click"
                    >
                      <Button>Cod manager</Button>
                    </Popover>
                  </div>
                  <p>
                    <span>
                      Trimite SMS la programari externe Autopia:{' '}
                      <Checkbox
                        checked={service.smsExternalAppointments}
                        onChange={this.handleSwitchSmsExternalAppointments}
                      />
                    </span>
                  </p>{' '}
                </Col>
              </Row>
            )}
            {service && (
              <Row
                type="flex"
                justify="space-around"
                gutter={24}
                style={{ marginTop: '24px' }}
              >
                <Col>
                  <span
                    className={'a-link'}
                    disabled={!isSaveAvailable}
                    onClick={this.handleSaveModifications}
                  >
                    Salvează modificările
                  </span>
                </Col>
                <Col>
                  {service.active ? (
                    <Popconfirm
                      title={
                        <div>
                          <p>Ești sigur că vrei sa dezactivezi service-ul?</p>
                          <p>
                            Acesta nu va mai fi vizibil în căutările
                            utilizatorilor.
                          </p>
                        </div>
                      }
                      onConfirm={this.handleDisableService}
                      okText="Da"
                      okType="danger"
                      cancelText="Nu"
                    >
                      <span className={'a-link'} style={{ color: '#f5222d' }}>
                        Dezactivează
                      </span>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      title={
                        <div>
                          <p>Ești sigur că vrei sa activezi service-ul?</p>
                          <p>
                            Acesta va deveni vizibil în căutările
                            utilizatorilor.
                          </p>
                        </div>
                      }
                      onConfirm={this.handleEnableService}
                      okText="Da"
                      cancelText="Nu"
                    >
                      <span className={'a-link'} style={{ color: '#87d068' }}>
                        Activează
                      </span>
                    </Popconfirm>
                  )}
                </Col>
                <Col span={24}>
                  <Divider className={css(styles.divider)} />
                </Col>
              </Row>
            )}
          </Form>
          {service && (
            <Row
              type="flex"
              justify="space-between"
              style={{ marginTop: '12px' }}
            >
              <Col span={12}>
                <h3>Rampe</h3>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <span
                  className={'a-link'}
                  onClick={this.handleShowAddServiceRampForm}
                  disabled={isAddingServiceRamp}
                >
                  Adaugă rampă
                </span>
              </Col>
              {isAddingServiceRamp && (
                <Col span={24}>
                  <AddServiceRampForm
                    onCancel={this.handleCancelAddServiceRamp}
                    onSave={this.handleAddServiceRamp}
                    mechanicsList={service.mechanics}
                  />
                </Col>
              )}
              <Col span={24} style={{ marginTop: '12px' }}>
                <Table
                  dataSource={service.serviceRamps}
                  columns={this.serviceRampsTableColumns}
                  rowKey="id"
                />
              </Col>
            </Row>
          )}
          {service && (
            <Row
              type="flex"
              justify="space-between"
              style={{ marginTop: '12px' }}
            >
              <Col span={12}>
                <h3>Utilizatori</h3>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <span
                  className={'a-link'}
                  onClick={this.handleShowAddUserForm}
                  disabled={isAddingServiceRamp}
                >
                  Adaugă utilizator
                </span>
              </Col>
              {isAddingUser && (
                <Col span={24}>
                  <AddUserForm
                    onCancel={this.handleCancelAddUser}
                    onSave={this.handleAddUser}
                    mechanicsList={service.mechanics}
                  />
                </Col>
              )}
              <Col span={24} style={{ marginTop: '12px' }}>
                <Table
                  dataSource={service.mechanics}
                  columns={this.usersTableColumns}
                  rowKey="id"
                />
              </Col>
            </Row>
          )}
        </Spin>
      </React.Fragment>
    );
  }
}

const styles = StyleSheet.create({
  editIcon: {
    color: '#40a9ff',
    marginLeft: '2px',
    cursor: 'pointer',
  },
  divider: {
    marginBottom: '8px',
    marginTop: '8px',
  },
});

export default Form.create()(ServiceProfile);
