import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';

const RcaServiceCompanyNetopiaCredentials = ({ username, password }) => {
  return (
    <div style={{ marginTop: '1rem' }}>
      <h2>Credențiale Netopia</h2>
      <Descriptions bordered>
        <Descriptions.Item label="Username">{username}</Descriptions.Item>
        <Descriptions.Item label="Parola">{password}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

RcaServiceCompanyNetopiaCredentials.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired
};

RcaServiceCompanyNetopiaCredentials.defaultProps = {
  username: '',
  password: ''
};

export default RcaServiceCompanyNetopiaCredentials;
