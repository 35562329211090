import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Card, Row, Col, Spin } from 'antd';
import CountUp from 'react-countup';
import ReactSVG from 'react-svg';
import serviceImg from '../../../images/service.svg';
import serviceRampImg from '../../../images/service-ramp.svg';
import userImg from '../../../images/user.svg';
import advertisingImg from '../../../images/advertising.svg';

function NumberCard({ icon, color, title, isLoading, stats, parameter, number, suffix, decimals }) {
  const CardIcon = (icon, color) => {
    if (icon === 'service')
      return (
        <ReactSVG
          path={serviceImg}
          svgStyle={{ width: '100%', height: '100%', fill: color }}
        />
      );

    if (icon === 'service-ramp')
      return (
        <ReactSVG
          path={serviceRampImg}
          svgStyle={{ width: '100%', height: '100%', fill: color }}
        />
      );

    if (icon === 'user')
      return (
        <ReactSVG
          path={userImg}
          svgStyle={{ width: '100%', height: '100%', fill: color }}
        />
      );

    if (icon === 'advertising')
      return (
        <ReactSVG
          path={advertisingImg}
          svgStyle={{ width: '100%', height: '100%', fill: color }}
        />
      );

    return <Icon style={{ color, fontSize: 54, float: 'left' }} type={icon} />;
  };

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 0 }}
      style={{
        padding: 32,
        cursor: 'pointer',
        boxShadow: '0 0 20px rgba(0,0,0,0.05)'
      }}
    >
      <Spin spinning={isLoading || stats[parameter] === null || stats[parameter] === undefined}>
        <Row type="flex" justify="center" align="middle">
          <Col
            span={3}
            style={{
              height: '100%',
              padding: '0'
            }}
          >
            <div
              style={{
                height: '100%'
              }}
            >
              {CardIcon(icon, color)}
            </div>
          </Col>
          <Col span={21}>
            <div style={{ width: '100%', paddingLeft: 24 }}>
              <p
                style={{
                  fontSize: '16px',
                  lineHeight: '16px',
                  height: '16px',
                  marginBottom: '8px'
                }}
              >
                {title || 'No Title'}
              </p>
              <p
                style={{
                  fontSize: '24px',
                  height: '32px',
                  lineHeight: '32px',
                  marginBottom: '0px'
                }}
              >
                <CountUp
                  start={0}
                  end={stats[parameter] || 0}
                  duration={1.5}
                  useEasing
                  useGrouping
                  suffix={` ${suffix || ''}`}
                  decimal=","
                  decimals={decimals && 2}
                />
              </p>
            </div>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
}

NumberCard.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  stats: PropTypes.object,
  parameter: PropTypes.string,

  number: PropTypes.number,
  suffix: PropTypes.string,
  decimals: PropTypes.bool
};

export default NumberCard;
