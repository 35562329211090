import 'js-polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import registerServiceWorker from './registerServiceWorker';
import Helmet from 'react-helmet';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import createCompressor from 'redux-persist-transform-compress';
import createEncryptor from 'redux-persist-transform-encrypt';
import reducers from './ducks';
import axios from 'axios';
import App from './components/App/App';
import LoginPage from './components/LoginPage/LoginPage';
import NProgress from 'nprogress';
import 'moment/locale/ro';

// Disable NProgress spinner
NProgress.configure({ showSpinner: false });

// Create ReduxPersist Encryptor
const encryptor = createEncryptor({
  secretKey: '7Af7W^uP1ub0A!^RPWaBN2^G@pU*a974&a3h@&*Hc4kk8O*8',
  onError: function(error) {
    // Handle the error.
    console.log(error);
  }
});

// Create ReduxPersist Comperssor
const compressor = createCompressor();

const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor]
};

const logger = createLogger({
  collapsed: (getState, action, logEntry) => !logEntry.error
});

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, logger))
);
const persistor = persistStore(store, { transform: [compressor] });

// Set Authorization token to all axios requests
store.subscribe(() => {
  const token = store.getState().auth.token;
  if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  else axios.defaults.headers.common['Authorization'] = null;
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Helmet titleTemplate="%s - Autopia" />
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/" component={App} />
        </Switch>
      </BrowserRouter>{' '}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
