import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Input, Checkbox, Row, Col, Button, message, Spin } from 'antd';
import _ from 'lodash';
import { getPickerAboInfo, addOrEditPickerAboInfo } from '../../../api';
import './styles.css';

class PickerAboInfoPage extends Component {
  state = {
    isLoading: false,
    isAdding: false,

    price0month: 0,
    price1month: 0,
    price2month: 0,
    price0year: 0,
    price1year: 0,
    price2year: 0,

    price0monthEuro: 0,
    price1monthEuro: 0,
    price2monthEuro: 0,
    price0yearEuro: 0,
    price1yearEuro: 0,
    price2yearEuro: 0,
    content: [],
  };

  componentDidMount = () => {
    this.loadPickerAboInfo();
  };

  loadPickerAboInfo = () => {
    this.setState({ isLoading: true });
    getPickerAboInfo()
      .then((response) => {
        if (response.data) {
          let content = _.map(response.data.extra[0].cap, (cap, index) => ({
            name: cap.name,
            info: cap.info || '',
            ok0: cap.ok,
            ok1: response.data.extra[1].cap[index].ok,
            ok2: response.data.extra[2].cap[index].ok,
            extra0: cap.extra || '',
            extra1: response.data.extra[1].cap[index].extra || '',
            extra2: response.data.extra[2].cap[index].extra || '',
          }));

          this.setState({
            isLoading: false,

            price0month: response.data.extra[0].price.month,
            price1month: response.data.extra[1].price.month,
            price2month: response.data.extra[2].price.month,
            price0year: response.data.extra[0].price.year,
            price1year: response.data.extra[1].price.year,
            price2year: response.data.extra[2].price.year,

            price0monthEuro: response.data.extra[0].price.monthEuro,
            price1monthEuro: response.data.extra[1].price.monthEuro,
            price2monthEuro: response.data.extra[2].price.monthEuro,
            price0yearEuro: response.data.extra[0].price.yearEuro,
            price1yearEuro: response.data.extra[1].price.yearEuro,
            price2yearEuro: response.data.extra[2].price.yearEuro,

            content,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        message.error('Eroare la încărcare pentru editare');
        console.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  handleSaveInfo = () => {
    const {
      price0month,
      price1month,
      price2month,
      price0year,
      price1year,
      price2year,
      price0monthEuro,
      price1monthEuro,
      price2monthEuro,
      price0yearEuro,
      price1yearEuro,
      price2yearEuro,

      content,
    } = this.state;

    const final = [
      {
        price: {
          month: price0month,
          year: price0year,
          monthEuro: price0monthEuro,
          yearEuro: price0yearEuro,
        },
        cap: _.map(content, (c) => ({
          name: c.name,
          info: _.trim(c.info) || null,
          ok: c.ok0,
          extra: _.trim(c.extra0) || null,
        })),
      },
      {
        price: {
          month: price1month,
          year: price1year,
          monthEuro: price1monthEuro,
          yearEuro: price1yearEuro,
        },
        cap: _.map(content, (c) => ({
          name: c.name,
          info: _.trim(c.info) || null,
          ok: c.ok1,
          extra: _.trim(c.extra1) || null,
        })),
      },
      {
        price: {
          month: price2month,
          year: price2year,
          monthEuro: price2monthEuro,
          yearEuro: price2yearEuro,
        },
        cap: _.map(content, (c) => ({
          name: c.name,
          info: _.trim(c.info) || null,
          ok: c.ok2,
          extra: _.trim(c.extra2) || null,
        })),
      },
    ];

    addOrEditPickerAboInfo(final)
      .then((response) => {
        message.success('Operație efectuată cu succes!');
        this.setState({ isAdding: false }, this.loadPickerAboInfo);
      })
      .catch((error) => {
        message.error('Verificați corectitudinea completării datelor.');
        this.setState({ isAdding: false });
      });
  };

  generateContent = () => {
    const { content } = this.state;

    const final = _.map(content, (slice, indexSlice) => {
      return (
        <Row type={'flex'} gutter={16} className={'section'} key={indexSlice}>
          <Col span={1}>
            <Button
              onClick={() => this.handleDeleteContentSlice(indexSlice)}
              type="danger"
              icon="delete"
              size="large"
              block
            />
          </Col>
          <Col span={4}>
            <h6>Nume serviciu</h6>
            <Input
              value={slice.name}
              onChange={(e) =>
                this.handleChangeContentSliceContent(
                  indexSlice,
                  'name',
                  e.target.value
                )
              }
            />
          </Col>
          <Col span={4}>
            <h6>Info in plus</h6>
            <Input
              value={slice.info}
              onChange={(e) =>
                this.handleChangeContentSliceContent(
                  indexSlice,
                  'info',
                  e.target.value
                )
              }
            />
          </Col>
          <Col span={1}>
            <h6>Bifa verde?</h6>
            <Checkbox
              checked={slice.ok0}
              onChange={(e) =>
                this.handleChangeContentSliceContent(
                  indexSlice,
                  'ok0',
                  e.target.checked
                )
              }
            />
          </Col>
          <Col span={4}>
            <h6>Scris langa bifa sau X</h6>
            <Input
              value={slice.extra0}
              onChange={(e) =>
                this.handleChangeContentSliceContent(
                  indexSlice,
                  'extra0',
                  e.target.value
                )
              }
            />
          </Col>
          <Col span={1}>
            <h6>Bifa verde?</h6>
            <Checkbox
              checked={slice.ok1}
              onChange={(e) =>
                this.handleChangeContentSliceContent(
                  indexSlice,
                  'ok1',
                  e.target.checked
                )
              }
            />
          </Col>
          <Col span={4}>
            <h6>Scris langa bifa sau X</h6>
            <Input
              value={slice.extra1}
              onChange={(e) =>
                this.handleChangeContentSliceContent(
                  indexSlice,
                  'extra1',
                  e.target.value
                )
              }
            />
          </Col>
          <Col span={1}>
            <h6>Bifa verde?</h6>
            <Checkbox
              checked={slice.ok2}
              onChange={(e) =>
                this.handleChangeContentSliceContent(
                  indexSlice,
                  'ok2',
                  e.target.checked
                )
              }
            />
          </Col>
          <Col span={4}>
            <h6>Scris langa bifa sau X</h6>
            <Input
              value={slice.extra2}
              onChange={(e) =>
                this.handleChangeContentSliceContent(
                  indexSlice,
                  'extra2',
                  e.target.value
                )
              }
            />
          </Col>
        </Row>
      );
    });

    return (
      <React.Fragment>
        {final}
        <Row type={'flex'} gutter={16} className={'section'}>
          <Col span={24}>
            <Button onClick={this.handleAddContentSlice} block>
              Adaugă încă un tip de serviciu
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  handleChangeContentSliceContent = (index, what, value) => {
    const { content } = this.state;
    this.setState({
      content: [
        ..._.slice(content, 0, index),
        {
          ...content[index],
          [what]: value,
        },
        ..._.slice(content, index + 1, _.size(content)),
      ],
    });
  };

  handleAddContentSlice = () => {
    const { content } = this.state;
    this.setState({
      content: [
        ..._.slice(content, 0, _.size(content)),
        {
          name: '',
          info: '',
          ok0: false,
          extra0: '',
          ok1: false,
          extra1: '',
          ok2: false,
          extra2: '',
        },
      ],
    });
  };

  handleDeleteContentSlice = (index) => {
    const { content } = this.state;
    this.setState({
      content: [
        ..._.slice(content, 0, index),
        ..._.slice(content, index + 1, _.size(content)),
      ],
    });
  };

  render() {
    const {
      isLoading,
      isAdding,
      price0month,
      price1month,
      price2month,
      price0year,
      price1year,
      price2year,
      price0monthEuro,
      price1monthEuro,
      price2monthEuro,
      price0yearEuro,
      price1yearEuro,
      price2yearEuro,
    } = this.state;

    return (
      <div className={'article-add-page'}>
        <Helmet title="Modifică informatiile de abonament Greenlight" />
        <Row type="flex" justify="space-between" className={'section'}>
          <Col>
            <h3>Modifică informatiile de abonament Greenlight</h3>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={this.handleSaveInfo}
              loading={isLoading || isAdding}
            >
              Salvează modificările
            </Button>
          </Col>
        </Row>
        <Spin spinning={isLoading} tip="Se încarcă...">
          <h2>Preturi pentru abonament</h2>
          <Row type={'flex'} gutter={16} className={'section'}>
            <Col span={5} offset={9}>
              <h6>Abonament Mic pret lunar</h6>
              <Input
                value={price0month}
                onChange={(e) => this.setState({ price0month: e.target.value })}
              />
            </Col>
            <Col span={5}>
              <h6>Abonament Mediu pret lunar</h6>
              <Input
                value={price1month}
                onChange={(e) => this.setState({ price1month: e.target.value })}
              />
            </Col>
            <Col span={5}>
              <h6>Abonament Mare pret lunar</h6>
              <Input
                value={price2month}
                onChange={(e) => this.setState({ price2month: e.target.value })}
              />
            </Col>
            <Col span={5} offset={9}>
              <h6>Abonament Mic pret anual in LEI</h6>
              <Input
                value={price0year}
                onChange={(e) => this.setState({ price0year: e.target.value })}
              />
            </Col>
            <Col span={5}>
              <h6>Abonament Mediu pret anual</h6>
              <Input
                value={price1year}
                onChange={(e) => this.setState({ price1year: e.target.value })}
              />
            </Col>
            <Col span={5}>
              <h6>Abonament Mare pret anual</h6>
              <Input
                value={price2year}
                onChange={(e) => this.setState({ price2year: e.target.value })}
              />
            </Col>
          </Row>

          <h2>Preturi pentru abonament in EURO</h2>
          <Row type={'flex'} gutter={16} className={'section'}>
            <Col span={5} offset={9}>
              <h6>Abonament Mic pret lunar in EURO</h6>
              <Input
                value={price0monthEuro}
                onChange={(e) =>
                  this.setState({ price0monthEuro: e.target.value })
                }
              />
            </Col>
            <Col span={5}>
              <h6>Abonament Mediu pret lunar in EURO</h6>
              <Input
                value={price1monthEuro}
                onChange={(e) =>
                  this.setState({ price1monthEuro: e.target.value })
                }
              />
            </Col>
            <Col span={5}>
              <h6>Abonament Mare pret lunar in EURO</h6>
              <Input
                value={price2monthEuro}
                onChange={(e) =>
                  this.setState({ price2monthEuro: e.target.value })
                }
              />
            </Col>
            <Col span={5} offset={9}>
              <h6>Abonament Mic pret anual in EURO</h6>
              <Input
                value={price0yearEuro}
                onChange={(e) =>
                  this.setState({ price0yearEuro: e.target.value })
                }
              />
            </Col>
            <Col span={5}>
              <h6>Abonament Mediu pret anual in EURO</h6>
              <Input
                value={price1yearEuro}
                onChange={(e) =>
                  this.setState({ price1yearEuro: e.target.value })
                }
              />
            </Col>
            <Col span={5}>
              <h6>Abonament Mare pret anual in EURO</h6>
              <Input
                value={price2yearEuro}
                onChange={(e) =>
                  this.setState({ price2yearEuro: e.target.value })
                }
              />
            </Col>
          </Row>

          <h2>Servicii pentru abonament</h2>
          {this.generateContent()}
        </Spin>
      </div>
    );
  }
}

export default PickerAboInfoPage;
