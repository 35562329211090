import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';


const createTableColumns = () => {
  let tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Creat la',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user - b.user,
    },
    {
      title: 'Nume service',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Telefon service',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a, b) => a.phone.localeCompare(b.phone),
    },
    {
      title: 'Adresa service',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: 'Mesaj de la user',
      dataIndex: 'message',
      key: 'message',
      sorter: (a, b) => (a.message === null ? '' : a.message).localeCompare(b.message === null ? '' : b.message),
    }
  ]

  return tableColumns;
}

const VoucherServiceRecommendedTable = ({
  isLoading,
  serviceRecommended,
}) => {
  
  const tableColumns = createTableColumns()
  return (
    <Table
      bordered
      dataSource={serviceRecommended}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 30 }}
    />
  );
};

VoucherServiceRecommendedTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  serviceRecommended: PropTypes.array.isRequired,
};

export default VoucherServiceRecommendedTable;
