const prettyError = error => {
  switch (error) {
    case 'SERVER_ERROR':
      return 'Există o problemă la conexiunea cu serverul. Vă rugăm să încercați mai târziu.';
    case 'BAD_REQUEST':
      return 'Verificați corectitudinea completării câmpurilor.';
    case 'INVALID_CREDENTIALS':
      return 'Datele de autentificare nu sunt valide.';
    default:
      return error;
  }
};

export default prettyError;
