import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'antd';
import { getPartsAnalytics, getService } from '../../api';

const ANALYTICS_ACTIONS = {
  SEARCH: 'search',
  SEARCH_RESULT: 'search_result',
  BUY: 'buy',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  GET_OFFER: 'get_offer',
};

const PartsAnalyticsList = () => {
  const [analytics, setAnalytics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);

  const getServices = async (newAnalytics) => {
    const servicesNames = new Set();
    newAnalytics.forEach((item) => servicesNames.add(item.service));
    const services = await Promise.all(
      [...servicesNames].map((service) => getService(service)).filter(Boolean)
    );
    setServices(services.map((service) => service.data));
  };

  useEffect(() => {
    getPartsAnalytics()
      .then((response) => {
        setAnalytics(response.data);
        getServices(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const tableColumns = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   sorter: (a, b) => a.id - b.id,
    // },
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      sorter: (a, b) => a.service - b.service,
      filters: services.map((service) => ({
        text: service.name,
        value: service.id,
      })),
      onFilter: (value, record) => record.service === value,
      render: (value) => {
        const service = services.find((service) => service.id === value);
        if (!service) return value;
        return service.name;
      },
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => a.user - b.user,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      sorter: (a, b) => a.action.localeCompare(b.action),
      filters: Object.values(ANALYTICS_ACTIONS).map((action) => ({
        text: action,
        value: action,
      })),
      onFilter: (value, record) => record.action === value,
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      width: 700,
      render: (value, record) => {
        switch (record.action) {
          case ANALYTICS_ACTIONS.SEARCH:
            return (
              <div>
                <p>{value.search}</p>
                <p>{value.suppliers.toString()}</p>
              </div>
            );
          case ANALYTICS_ACTIONS.GET_OFFER:
            return (
              <div>
                <p>{value.cartId}</p>
              </div>
            );
          case ANALYTICS_ACTIONS.BUY:
            return (
              <div>
                <p>{value.supplier}</p>
                <p>{value.item.id}</p>
              </div>
            );
          case ANALYTICS_ACTIONS.SEARCH_RESULT:
            return (
              <div>
                <p>{value.supplier}</p>
                <p>Returned {value.displayParts.length} items</p>
                {/* <div style={{ display: 'flex', gap: 5 }}>
                  {value.displayParts.map((part) => (
                    <p key={part.name}>{part.name}</p>
                  ))}
                </div> */}
              </div>
            );
          default:
            return <p>Unknown</p>;
        }
      },
    },
  ];

  return (
    <div style={{ padding: 8 }}>
      <div>
        <p style={{ fontSize: 18 }}>Parts Analytics</p>

        <div style={{ display: 'flex', gap: 25 }}>
          <p>
            Total searches:{' '}
            {
              analytics.filter(
                (item) => item.action === ANALYTICS_ACTIONS.SEARCH
              ).length
            }
          </p>
          <p>
            Total offers:{' '}
            {
              analytics.filter(
                (item) => item.action === ANALYTICS_ACTIONS.GET_OFFER
              ).length
            }
          </p>
          <p>
            Total buys:{' '}
            {
              analytics.filter((item) => item.action === ANALYTICS_ACTIONS.BUY)
                .length
            }
          </p>
        </div>
      </div>

      <Row>
        <Col span={24}>
          <Table
            bordered
            dataSource={analytics}
            loading={isLoading}
            columns={tableColumns}
            scroll={{ x: 'max-content' }}
            rowKey="id"
            pagination={{ pageSize: 100 }}
            style={{ background: 'white' }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PartsAnalyticsList;
