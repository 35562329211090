import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NewsList from './NewsList/NewsList'
import NewsAdd from './NewsAdd/NewsAdd'
import NewsView from './NewsView/NewsView'
import NewsEdit from './NewsEdit/NewsEdit'

export default class NewsPage extends Component {
  render () {
    const { match } = this.props

    return (
      <div style={{ background: '#fff', padding: 24 }}>
        <Switch>
          <Redirect exact path={`${match.path}/`} to={`${match.path}/list`} />
          <Route path={`${match.path}/list`} component={NewsList} />
          <Route path={`${match.path}/add`} component={NewsAdd} />
          <Route path={`${match.path}/edit/:articleId`} component={NewsEdit} />
          <Route path={`${match.path}/:articleId`} component={NewsView} />
          <Redirect to={`${match.path}/list`} />
        </Switch>
      </div>
    )
  }
}
