import React, { Component } from 'react';
import VouchersCreditsGatheredTable from './VouchersCreditsGatheredTable';
import Helmet from 'react-helmet';
import { getCreditsGathered } from '../../../api';
import NProgress from 'nprogress';
import { Row, Col, Checkbox } from 'antd';
import _ from 'lodash';



export default class VouchersCreditsGatheredList extends Component {
  state = {
    isLoading: false,
    creditsGathered: [],
    hideZero: true,
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getCreditsGathered()
      .then(resp => {
        this.setState({
          creditsGathered: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  handleChangeCheckboxZero = e => {
    this.setState({
      hideZero: e.target.checked,
    });
  };

  render() {
    const { isLoading, creditsGathered, hideZero } = this.state;

    const creditsGatheredFiltered = hideZero ? _.filter(creditsGathered, row => 
      (row.creditsAdd - row.creditsSubtract) + (row.creditsAddBypass - row.creditsSubtractBypass) !== 0
    ) : creditsGathered;

    return (
      <React.Fragment>
        <Helmet title="Listă credite curente" />
        <Row
          justify="start"
          gutter={24}
          style={{ marginBottom: 16 }}
        >
          <Col span={1}>
            <Checkbox
              checked={hideZero}
              onChange={this.handleChangeCheckboxZero}
            />
          </Col>
          <Col span={10}>
            Ascunde userii care au 0 la credite totale
          </Col>
        </Row>
        <VouchersCreditsGatheredTable
          isLoading={isLoading}
          creditsGathered={creditsGatheredFiltered}
        />
      </React.Fragment>
    );
  }
}
