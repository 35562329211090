import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox, Divider, Button } from 'antd';

const ServicesTable = ({
  services,
  isLoading,
  onConfigureServiceClick,
  onEditServiceCompanyClick,
  onImpersonateServiceClick
}) => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Denumire',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Mecanică',
      key: 'mecanica',
      align: 'center',
      render: (text, record) => <Checkbox checked={record.mecanica} />
    },
    {
      title: 'Electrică',
      key: 'electrica',
      align: 'center',
      render: (text, record) => <Checkbox checked={record.electrica} />
    },
    {
      title: 'Vopsitorie',
      key: 'vopsitorie',
      align: 'center',
      render: (text, record) => <Checkbox checked={record.vopsitorie} />
    },
    {
      title: 'ITP',
      key: 'itp',
      align: 'center',
      render: (text, record) => <Checkbox checked={record.itp} />
    },
    {
      title: 'Tuning',
      key: 'tuning',
      align: 'center',
      render: (text, record) => <Checkbox checked={record.tuning} />
    },
    {
      title: 'Acțiuni',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button
            type={'link'}
            onClick={() => onConfigureServiceClick(record.id)}
          >
            Configureaza
          </Button>
          <Divider type={'vertical'} />
          <Button
            type={'link'}
            onClick={() => onEditServiceCompanyClick(record.id)}
          >
            Facturare
          </Button>
          <Divider type={'vertical'} />
          <Button
            type={'link'}
            onClick={() => onImpersonateServiceClick(record.id)}
          >
            Log in
          </Button>
        </span>
      )
    }
  ];

  return (
    <Table
      bordered
      dataSource={services}
      loading={isLoading}
      columns={tableColumns}
      scroll={{ x: 'max-content' }}
      rowKey="id"
      pagination={{ pageSize: 15 }}
    />
  );
};

ServicesTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,
  onConfigureServiceClick: PropTypes.func.isRequired,
  onImpersonateServiceClick: PropTypes.func.isRequired
};

export default ServicesTable;
