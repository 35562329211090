import React, { Component } from "react";
import { Row, Col, Table, Button, Divider, message, Popconfirm } from "antd";
import { getPartsCredentialsPlural, deletePartsCredentials } from "../../../api";
import moment from "moment";

export default class PartsCredentialsList extends Component {
  state = {
    partsCredentials: [],
    isLoading: false
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    getPartsCredentialsPlural()
      .then(response => {
        this.setState({
          partsCredentials: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleGoToAddPartsCredentials = () => {
    const { history } = this.props;
    history.push("/partscredentials/add");
  };

  tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Nume Service",
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: "Service ID",
      dataIndex: "serviceId",
      key: "serviceId",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Parolă",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "Creat la",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (text, record) => moment(record.createdAt).format('DD.MM.YYYY HH:mm:ss')
    },
    {
      title: "Acțiuni",
      key: "action",
      render: (text, record) => (
        <span>
          {(
            <React.Fragment>
              <Divider type={'vertical'} />
              <Popconfirm
                title="Ești sigur că vrei să ștergi această credențială?"
                onConfirm={() => this.handleDeletePartsCredentials(record.id)}
              >
                <Button type={'danger'}>
                  Șterge
              </Button>
              </Popconfirm>
            </React.Fragment>
            
          )}
        </span>
      )
    }
  ];

  handleDeletePartsCredentials = partsCredentialsId => {
    const { history } = this.props;
    deletePartsCredentials(partsCredentialsId)
      .then(response => {
        message.success("Credențială ștearsă cu succes!");
        history.push("/partscredentials");
      })
      .catch(error => {
        message.error("Eroare.");
        console.log(error);
      });
  };

  render() {
    const { partsCredentials, isLoading } = this.state;

    return (
      <div style={{ padding: 8 }}>
        <Row type="flex" justify="end" style={{ marginBottom: "20px" }}>
          <Col>
            <Button
              icon="plus"
              type="primary"
              onClick={this.handleGoToAddPartsCredentials}
            >
              Adaugă credențială
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              bordered
              dataSource={partsCredentials}
              loading={isLoading}
              columns={this.tableColumns}
              scroll={{ x: 'max-content' }}
              rowKey="id"
              pagination={{ pageSize: 100 }}
              style={{ background: "white" }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
