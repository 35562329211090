import React from 'react';
import { Input, Radio, Row, Col, Button, message, Spin, Table } from 'antd';
import { Helmet } from 'react-helmet';
import './styles.css';
import { getUserAboInfo, createPickerAbo } from '../../../api';

export const AddPickerAboPage = () => {
  const [aboType, setAboType] = React.useState('0');
  const [duration, setDuration] = React.useState('1');
  const [vehicles, setVehicles] = React.useState([]);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [vehicleId, setVehicleId] = React.useState(null);
  const [email, setEmail] = React.useState('soare50@yopmail.com');
  const [loading, setLoading] = React.useState(false);
  const getUserDetails = () => {
    if (!email) {
      alert('Please add an email first');
    }
    setLoading(true);
    getUserAboInfo({ email })
      .then((data) => {
        if (data.data.vehicles) {
          setVehicles(data.data.vehicles);
          setVehicleId(String(data.data.vehicles[0].id));
        }
        if (data.data.subscriptions) {
          setSubscriptions(data.data.subscriptions);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreate = () => {
    if (!duration || !email || !vehicleId) {
      message.error('Please select all the fields');
      return;
    }
    setLoading(true);
    createPickerAbo({ email, aboType, duration, vehicleId })
      .then((data) => {
        setSubscriptions((sub) => [data.data.subscription, ...sub]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={'article-add-page'}>
      <Helmet title="Adauga un abonament Greenlight" />
      <Row type="flex" justify="space-between" className={'section'}>
        <Col>
          <h3>Adauga manual un abonament Greenlight</h3>
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Row type={'flex'} gutter={16} className={'section'}>
          <Col span={8}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div>
                <h6>Email</h6>
                <Input
                  placeholder="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <Button onClick={getUserDetails}>Gaseste user</Button>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div>
                <h6>Tip Abonament</h6>
                <Radio.Group
                  onChange={(e) => setAboType(e.target.value)}
                  value={aboType}
                >
                  <Radio value={'0'}>Peace</Radio>
                  <Radio value={'1'}>Delight</Radio>
                </Radio.Group>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div>
                <h6>Perioada</h6>
                <Radio.Group
                  onChange={(e) => setDuration(e.target.value)}
                  value={duration}
                >
                  <Radio value={'1'}>1 Luna</Radio>
                  <Radio value={'12'}>1 An</Radio>
                </Radio.Group>
              </div>
            </div>
          </Col>
        </Row>

        {vehicles.length > 0 && (
          <>
            <Row type={'flex'} gutter={16} className={'section'}>
              <Col span={5}>
                <select
                  onChange={(e) => setVehicleId(e.target.value)}
                  value={vehicleId}
                >
                  {vehicles.map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.id}>
                      {vehicle.registrationNumber}
                    </option>
                  ))}
                </select>
              </Col>
              <Col span={5}>
                <Button onClick={handleCreate}>Create</Button>
              </Col>
            </Row>
            <p>Alte abonamente alte userului:</p>
            <Row>
              <Table
                dataSource={subscriptions}
                columns={[
                  {
                    title: 'Id',
                    dataIndex: 'id',
                    key: 'id',
                    defaultSortOrder: 'ascend',
                    sorter: (a, b) => b.id - a.id,
                  },
                  {
                    title: 'Start Data',
                    dataIndex: 'startDate',
                    key: 'startDate',
                  },
                  {
                    title: 'End Data',
                    dataIndex: 'endDate',
                    key: 'endDate',
                  },
                  {
                    title: 'Paid until',
                    dataIndex: 'paidUntil',
                    key: 'paidUntil',
                  },
                  {
                    title: 'Paid',
                    dataIndex: 'paid',
                    key: 'paid',
                    render: (paid) => (paid ? 'Da' : 'Nu'),
                  },
                ]}
              />
            </Row>
          </>
        )}
      </Spin>
    </div>
  );
};
