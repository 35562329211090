import React, { Component } from 'react';
import { Spin, message } from 'antd';
import NProgress from 'nprogress';
import { getRcaCompany, addOrEditRcaCompany } from '../../api';
import RcaServiceCompanyInfo from './RcaServiceCompanyInfo';
import RcaServiceCompanyNetopiaCredentials from './RcaServiceCompanyNetopiaCredentials';

export default class RcaPage extends Component {

  state = {
    isLoading: false,
    rcaServiceCompany: {},
  };

  componentDidMount() {
    this.loadRcaServiceCompanyInfo();
  }

  loadRcaServiceCompanyInfo = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getRcaCompany()
      .then(resp => {
        this.setState({
          rcaServiceCompany: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        message.error(`Eroare: nu am putut lua vechiul rca service company`);
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  handleSaveChanges = newValues => {
    const { rcaServiceCompany } = this.state;

    console.log('Old values', rcaServiceCompany);
    console.log('New values', newValues);

    this.setState({ isLoading: true });
    addOrEditRcaCompany(newValues)
      .then(result => {
        message.success(`S-a modificat cu succes`);
        console.log(result);
        this.setState({ isLoading: false }, this.loadRcaServiceCompanyInfo);
      })
      .catch(err => {
        message.error(`Eroare: nu a putut fi adaugat / modificat`);
        console.error(err);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, rcaServiceCompany } = this.state;
    return (
      <div>
        <Spin spinning={isLoading}>
          <RcaServiceCompanyInfo
            rcaServiceCompany={rcaServiceCompany ? rcaServiceCompany : {}}
            onSave={this.handleSaveChanges}
          />
          <RcaServiceCompanyNetopiaCredentials
            username={rcaServiceCompany ? rcaServiceCompany.username : null}
            password={rcaServiceCompany ? rcaServiceCompany.password : null}
          />
        </Spin>
      </div>
    );
  }
}
