import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { specialAdminRcaEmit, specialAdminRcaStuffAfterEmitOnly, specialAdminCostitemsBadsi } from '../../api';
import NProgress from 'nprogress';
import { Popconfirm, Button, message, Spin, Input, Divider, DatePicker } from 'antd';

export default class SpecialActionsPage extends Component {
  state = {
    isLoading: false,

    rcaRequestId: null,
    momentDates: [null, null]
  };

  handleActionRcaEmit = () => {
    const { rcaRequestId } = this.state;
    const { history } = this.props;

    this.setState({ isLoading: true });
    NProgress.start();
    specialAdminRcaEmit(rcaRequestId)
      .then(resp => {
        NProgress.set(0.6);
        if (resp.ok) {
          message.success('Totul a mers excelent ls emiterea poliței!');
          history.push('/specialactions');
        } else {
          message.error('A aparut o eroare la emiterea poliței')
        }
      })
      .catch(err => {
        message.error('A aparut o eroare la emiterea poliței')
        message.error('Vezi consola')
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  }

  handleActionRcaStuffAfterEmitOnly = () => {
    const { rcaRequestId } = this.state;
    const { history } = this.props;

    this.setState({ isLoading: true });
    NProgress.start();
    specialAdminRcaStuffAfterEmitOnly(rcaRequestId)
      .then(resp => {
        NProgress.set(0.6);
        if (resp.ok) {
          message.success('Totul a mers excelent la emiterea bonificațiilor!');
          history.push('/specialactions');
        } else {
          message.error('A aparut o eroare la emiterea bonificațiilor')
        }
      })
      .catch(err => {
        message.error('A aparut o eroare la emiterea bonificațiilor')
        message.error('Vezi consola')
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  }

  handleChangeRcaId = e => {
    this.setState({ rcaRequestId: e })
  }

  handleCostitemsBadsi = () => {
    const { momentDates } = this.state;

    this.setState({ isLoading: true });
    NProgress.start();
    specialAdminCostitemsBadsi(momentDates[0].format('YYYY-MM-DD'), momentDates[1].format('YYYY-MM-DD'))
      .then(resp => {
        NProgress.set(0.6);
        message.success('Totul a mers excelent la costitemele la Badsi!');
      })
      .catch(err => {
        message.error('A aparut o eroare la costitemele la Badsi')
        message.error('Vezi consola')
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  }


  render() {
    const { isLoading, rcaRequestId } = this.state;

    return (
      <React.Fragment>
        <Helmet title="ATENȚIE ATENȚIE ATENȚIE" />
        <div style={{ background: '#FF8888', padding: 24 }}>
          <Spin spinning={isLoading}>

            <h2>Asta e pentru erori la ambele emiteri (sau una daca e doar rca sau doar perfect cover) + bonificatii, insa banii s-au luat deja</h2>
            <Input placeholder="rcaRequestId" value={rcaRequestId} onChange={e => this.handleChangeRcaId(e.target.value)} />
            <Popconfirm
              title={'ATENȚIE ATENȚIE ATENȚIE?\n\nEști sigur că vrei să emiți această poliță (id-ul reprezintă id-ul rcaRequest-ului)?\n\nATENȚIE ATENȚIE ATENȚIE'}
              onConfirm={this.handleActionRcaEmit}
              okText="DA"
              cancelText="NU"
            >
              <Button type="danger">EMITE POLIȚA</Button>
            </Popconfirm>

            <Divider />
            <h2>Asta e pentru erori dupa emitere (gen s-a facut pe jumatate, dupa tu de mana ai stat sa finalizezi emiterea si sa le bagi pdf-ul in baza de date si mai ai nevoie de bonificatii doar, pdf-ul fiind deja existent in baza de date (doar helperul se considera in punctul asta ca a rulat complet (jumate automat, jumate tu de mana sau si cu totul (nu s-a trimis nici mail cu pdf-urile))))</h2>
            <Input placeholder="rcaRequestId" value={rcaRequestId} onChange={e => this.handleChangeRcaId(e.target.value)} />
            <Popconfirm
              title={'ATENȚIE ATENȚIE ATENȚIE?\n\nEști sigur că vrei să faci bonificațiile pentru această poliță (id-ul reprezintă id-ul rcaRequest-ului)?\n\nATENȚIE ATENȚIE ATENȚIE'}
              onConfirm={this.handleActionRcaStuffAfterEmitOnly}
              okText="DA"
              cancelText="NU"
            >
              <Button type="danger">EMITE BONIFICAȚII</Button>
            </Popconfirm>

            <Divider />
            <h2>Asta e pentru autocompletare baza noastra de date cu operatiuni si piese de la Badsi din Automotive de la data la data INCLUSIV</h2>
            <DatePicker.RangePicker
              format={'DD-MM-YYYY'}
              onChange={(dates) => this.setState({momentDates: dates})}
            />
            <Popconfirm
              title={'ATENȚIE ATENȚIE ATENȚIE?\n\nEști sigur să umpli baza noastra de date cu date din Automotive?\n\nATENȚIE ATENȚIE ATENȚIE'}
              onConfirm={this.handleCostitemsBadsi}
              okText="DA"
              cancelText="NU"
            >
              <Button type="danger">IA DIN AUTOMOTIVE</Button>
            </Popconfirm>

          </Spin>
        </div>
      </React.Fragment>
    );
  }
}
