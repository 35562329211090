import axios from 'axios';
import config from '../config';

const { BASE_URL } = config.backend;

/**
 * Authentication
 */

// Login
export const login = (data) => {
  return axios.post(`${BASE_URL}/auth/login/admin/`, data);
};

// Refresh token
export const refreshToken = () => {
  return axios.get(`${BASE_URL}/auth/refresh-token/`);
};

/**
 * Services
 */
// Retrieve all services
export const getServices = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/services/`);
};

// Add new service
export const addService = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/services/`, data);
};

// Add new service branch
export const addServiceBranch = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/services/branch/`, data);
};

// Retrieve a service
export const getService = (serviceId) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/services/${serviceId}/`);
};

// Edit a service
export const editService = (serviceId, data) => {
  return axios.put(`${BASE_URL}/j4qCKfMC/services/${serviceId}/`, data);
};

// Retrieve all service companies
export const getServiceCompanies = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/service-companies/`);
};

// Edit a service company
export const editServiceCompany = (serviceCompanyId, data) => {
  return axios.put(
    `${BASE_URL}/j4qCKfMC/service-companies/${serviceCompanyId}/`,
    data
  );
};

// Enables a service
export const enableService = (serviceId) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/services/${serviceId}/enable/`);
};

// Disables a service
export const disableService = (serviceId) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/services/${serviceId}/disable/`);
};

// Adds new service ramp
export const addServiceRamp = (serviceId, data) => {
  return axios.post(
    `${BASE_URL}/j4qCKfMC/services/${serviceId}/service-ramps/`,
    data
  );
};

// Edits an existing service ramp
export const editServiceRamp = (serviceId, serviceRampId, data) => {
  return axios.put(
    `${BASE_URL}/j4qCKfMC/services/${serviceId}/service-ramps/${serviceRampId}/`,
    data
  );
};

// Deletes an existing service ramp
export const deleteServiceRamp = (serviceId, serviceRampId) => {
  return axios.delete(
    `${BASE_URL}/j4qCKfMC/services/${serviceId}/service-ramps/${serviceRampId}/`
  );
};

// Adds new service user
export const addServiceUser = (serviceId, data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/services/${serviceId}/users/`, data);
};

// Get a service's auth token
export const getServiceAuthToken = (serviceId) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/services/${serviceId}/auth-token/`);
};

/**
 * Notifications
 */
// Retrieve all notifications
export const getAllNotifications = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/notifications/`);
};

// Sends a new service notification
export const sendNotification = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/notifications/`, data);
};

/**
 * Articles
 */
// Retrieve all articles
export const getArticles = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/articles/`);
};

// Retrieve an article
export const getArticle = (articleId) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/articles/${articleId}/`);
};

// Create new article
export const createArticle = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/articles/`, data);
};

// Updates an article
export const updateArticle = (articleId, data) => {
  return axios.put(`${BASE_URL}/j4qCKfMC/articles/${articleId}/`, data);
};

/**
 * ArticlesV2
 */
// Retrieve all articles V2
export const getArticlesV2 = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/articles/v2/`);
};

// Retrieve an article V2
export const getArticleV2 = (articleId) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/articles/v2/${articleId}/`);
};

// Create new article V2
export const createArticleV2 = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/articles/v2/`, data);
};

// Updates an article V2
export const updateArticleV2 = (articleId, data) => {
  return axios.put(`${BASE_URL}/j4qCKfMC/articles/v2/${articleId}/`, data);
};

// Deletes an article V2
export const deleteArticleV2 = (articleId) => {
  return axios.delete(`${BASE_URL}/j4qCKfMC/articles/v2/${articleId}/`);
};

/**
 * Geocoding API
 */
export const geocode = (address) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/geocoding/?address=${address}`);
};

/**
 * Find place id Google API
 */
export const findPlaceId = (search) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/findplaceid/?search=${search}`);
};

/**
 * Road Assistance
 */
// Retrieve all road assistance services
export const getRoadAssistanceServices = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/road-assistance/`);
};

// Retrieve one road assistance serivice
export const getRoadAssistanceService = (roadAssistanceServiceId) => {
  return axios.get(`${BASE_URL}/roadassistances/${roadAssistanceServiceId}/`);
};

// Create new  road assistance service
export const addNewRoadAssistanceService = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/road-assistance/`, data);
};

// Update one road assistance serivice
export const updateRoadAssistanceService = (roadAssistanceServiceId, data) => {
  return axios.patch(
    `${BASE_URL}/roadassistances/${roadAssistanceServiceId}/`,
    data
  );
};

// Delete one road assistance serivice
export const deleteRoadAssistanceService = (roadAssistanceServiceId) => {
  return axios.delete(
    `${BASE_URL}/roadassistances/${roadAssistanceServiceId}/`
  );
};

/**
 * Download invoice
 */
export const downloadInvoice = (invoiceId) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/invoices/${invoiceId}/`, {
    responseType: 'blob',
  });
};

/**
 * Add new invoice
 */
export const createNewInvoice = (invoiceData) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/invoices/`, invoiceData);
};

/**
 * Get web contact form responses
 */
export const getWebContact = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/webcontact/`);
};

/**
 * Get stats for dashboard
 */
export const getDashboardStats = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/dashboard/stats/`);
};

/**
 * Gets RCA company data
 */
export const getRcaCompany = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/rca/company/`);
};
/**
 * Adds or edits RCA company data
 */
export const addOrEditRcaCompany = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/rca/company/`, data);
};

/**
 * Gets picker abo data
 */
export const getPickerAboInfo = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/picker-abo/info/`);
};
/**
 * Adds or edits picker abo data
 */
export const addOrEditPickerAboInfo = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/picker-abo/info/`, data);
};
/**
 * Get user abo info
 */
export const getUserAboInfo = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/find-abo/`, data);
};
export const createPickerAbo = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/create-abo/`, data);
};

/**
 * Vouchers
 */
// Retrieve all vouchers
export const getVouchers = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/vouchers/`);
};

// Add new voucher
export const addVoucher = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/vouchers/`, data);
};

// Delete voucher
export const deleteVoucher = (type, voucherId) => {
  return axios.delete(`${BASE_URL}/j4qCKfMC/vouchers/${type}/${voucherId}/`);
};

// Delete voucher child
export const deleteVoucherChild = (childId) => {
  return axios.delete(`${BASE_URL}/j4qCKfMC/vouchers/enabled/${childId}/`);
};

// Retrieve a voucher
export const getVoucher = (type, voucherId) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/vouchers/${type}/${voucherId}/`);
};

// Edit a service
export const editVoucher = (type, voucherId, data) => {
  return axios.put(`${BASE_URL}/j4qCKfMC/vouchers/${type}/${voucherId}/`, data);
};

// Retrieve all cashback points
export const getVouchersCashbackPoints = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/cashbackpoints/`);
};

// Delete cashback points
export const deleteVoucherCashbackPoints = (cashbackPointsId) => {
  return axios.delete(
    `${BASE_URL}/j4qCKfMC/cashbackpoints/${cashbackPointsId}/`
  );
};

// Retrieve all service recommended
export const getServiceRecommended = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/servicerecommended/`);
};

// Retrieve all credits gathered grouped by user
export const getCreditsGathered = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/creditsgathered/`);
};

// Retrieve all credits consumed
export const getCreditsConsumed = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/creditsconsumed/`);
};

// Retrieve all user reviews
export const getUserReviews = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/userstorereviews/`);
};

// Retrieve database search data users
export const getDatabaseSearchUsersSize = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/dbsearch/users/size/`, {
    timeout: 600000,
  });
};
export const getDatabaseSearchUsersPartial = (from, limit) => {
  return axios.post(
    `${BASE_URL}/j4qCKfMC/dbsearch/users/partial/`,
    { from, limit },
    { timeout: 600000 }
  );
};
// Retrieve database search data vehicles
export const getDatabaseSearchVehiclesSize = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/dbsearch/vehicles/size/`, {
    timeout: 600000,
  });
};
export const getDatabaseSearchVehiclesPartial = (from, limit) => {
  return axios.post(
    `${BASE_URL}/j4qCKfMC/dbsearch/vehicles/partial/`,
    { from, limit },
    { timeout: 600000 }
  );
};
// Retrieve database search data rcas
export const getDatabaseSearchRcasSize = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/dbsearch/rcas/size/`, {
    timeout: 600000,
  });
};
export const getDatabaseSearchRcasPartial = (from, limit) => {
  return axios.post(
    `${BASE_URL}/j4qCKfMC/dbsearch/rcas/partial/`,
    { from, limit },
    { timeout: 600000 }
  );
};

// Retrieve google reviews and ratings
export const getGoogleReviews = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/google/reviews/`);
};

// CHange google ratings forced
export const updateServicesRating = () => {
  return axios.post(`${BASE_URL}/j4qCKfMC/services/ratings/`);
};

// CHange google reviews forced
export const updateServicesGoogleReviews = () => {
  return axios.post(`${BASE_URL}/j4qCKfMC/services/google/reviews/`);
};

// Mark google reviews as seen
export const seenReview = (reviewId) => {
  return axios.put(`${BASE_URL}/j4qCKfMC/google/reviews/${reviewId}/seen/`);
};

// Mark google reviews as active/inactive
export const activateReview = (reviewId, activate) => {
  return axios.put(
    `${BASE_URL}/j4qCKfMC/google/reviews/${reviewId}/activate/`,
    { activate }
  );
};

// Enable SMS for user
export const enableSmsForUser = (userId, enable) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/sms/enable/`, { userId, enable });
};

// Enable SMS for user
export const changeServiceManagerCode = (serviceId, mgc) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/service/managercode/`, {
    serviceId,
    mgc,
  });
};

/**
 * Parts Credentials
 */
// Retrieve all partscredentials
export const getPartsCredentialsPlural = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/partscredentials/`);
};

// Retrieve an partscredentials
export const getPartsCredentialsSingular = (partsCredentialsId) => {
  return axios.get(
    `${BASE_URL}/j4qCKfMC/partscredentials/${partsCredentialsId}/`
  );
};

// Create new partscredentials
export const createPartsCredentials = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/partscredentials/`, data);
};

// Updates an partscredentials
export const updatePartsCredentials = (partsCredentialsId, data) => {
  return axios.put(
    `${BASE_URL}/j4qCKfMC/partscredentials/${partsCredentialsId}/`,
    data
  );
};

// Deletes an partscredentials
export const deletePartsCredentials = (partsCredentialsId) => {
  return axios.delete(
    `${BASE_URL}/j4qCKfMC/partscredentials/${partsCredentialsId}/`
  );
};

// Get parts analytics
export const getPartsAnalytics = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/parts/analytics/all/`);
};

/**
 * Tutorials
 */
// Retrieve all tutorials
export const getTutorials = () => {
  return axios.get(`${BASE_URL}/j4qCKfMC/tutorials/`);
};

// Retrieve an tutorial
export const getTutorial = (tutorialId) => {
  return axios.get(`${BASE_URL}/j4qCKfMC/tutorials/${tutorialId}/`);
};

// Create new tutorial
export const createTutorial = (data) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/tutorials/`, data);
};

// Updates an tutorial
export const updateTutorial = (tutorialId, data) => {
  return axios.put(`${BASE_URL}/j4qCKfMC/tutorials/${tutorialId}/`, data);
};

// Deletes an tutorial
export const deleteTutorial = (tutorialId) => {
  return axios.delete(`${BASE_URL}/j4qCKfMC/tutorials/${tutorialId}/`);
};

/**
 * SPECIAL ADMIN CAUTION
 */
//
export const specialAdminRcaEmit = (rcaId) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/special/rcaemit/`, { rcaId });
};
export const specialAdminRcaStuffAfterEmitOnly = (rcaId) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/special/rcastuffafteremitonly/`, {
    rcaId,
  });
};
export const specialAdminCostitemsBadsi = (start, end) => {
  return axios.post(`${BASE_URL}/j4qCKfMC/special/costitemsbadsi/`, {
    start,
    end,
  });
};
