import React, { Component } from 'react';
import { Row, Col } from 'antd';
import NumberCard from './NumberCard/NumberCard';
import NProgress from 'nprogress';
import { getDashboardStats } from '../../api';


export default class DashboardPage extends Component {

  state = {
    isLoading: false,
    stats: {},
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    NProgress.start();
    getDashboardStats()
      .then(resp => {
        this.setState({
          stats: resp.data,
        });
        NProgress.set(0.6);
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => {
        this.setState({ isLoading: false });
        NProgress.done();
      });
  };

  render() {
    const {isLoading, stats} = this.state;
    return (
      <div style={{ padding: 8 }}>
        <Row gutter={[24, 24]}>
          <Col span={6}>
            <NumberCard
              icon="service"
              color="#1890ff"
              title="Service-uri înregistrate"
              isLoading={isLoading}
              stats={stats}
              parameter={'numberOfServices'}
            />
          </Col>
          <Col span={6}>
            <NumberCard
              icon="service-ramp"
              color="#d998cb"
              title="Total utilizatori"
              isLoading={isLoading}
              stats={stats}
              parameter={'numberOfUsers'}
            />
          </Col>
          <Col span={6}>
            <NumberCard
              icon="user"
              color="#B6D953"
              title="Utilizatori cu vehicule"
              isLoading={isLoading}
              stats={stats}
              parameter={'numberOfUsersWithVehicle'}
            />
          </Col>
          <Col span={6}>
            <NumberCard
              icon="advertising"
              color="#fac364"
              title="Vehicule înregistrate"
              isLoading={isLoading}
              stats={stats}
              parameter={'numberOfVehicles'}
            />
          </Col>
          <Col span={6}>
            <NumberCard
              icon="advertising"
              color="#fac364"
              title="Utilizatori neclienți"
              isLoading={isLoading}
              stats={stats}
              parameter={'numberOfUsersThatAreNotClient'}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
