import React, { Component } from "react";
import Helmet from "react-helmet";
import {
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Button,
  message,
  InputNumber,
  Select,
  Spin,
  Upload,
  Icon
} from "antd";
import _ from "lodash";
import { getArticleV2, createArticleV2, updateArticleV2 } from "../../../api";
import articleTypesText from "../../../utils/articleTypesText";
import "./styles.css";
import config from '../../../config';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';
import ReactMarkdown from 'react-markdown';


class ArticleAddOrEdit extends Component {
  state = {
    type: 1,
    isAdding: false,

    edit: false,
    currentArticleId: null,
    isLoading: false,

    thumbnail: null,
    fileListThumbnail: [],

    topGallery: null,
    fileListTopGallery: [],

    content: []
  };

  componentDidMount = () => {
    this.loadArticleInfo();
  };

  loadArticleInfo = () => {
    const { history, match, form } = this.props;
    const { articleId } = match.params;

    if (articleId){
      if (!(_.isInteger(parseInt(articleId)) && !isNaN(parseInt(articleId)))) {
        message.error('Eroare la încărcare pentru editarea articolului, id greșit')
        history.push('/article');
        return;
      }
      
      this.setState({
        edit: true,
        currentArticleId: parseInt(articleId)
      })
      this.setState({ isLoading: true });
      getArticleV2(articleId)
        .then(response => {
          this.setState({
            isLoading: false,
            type: response.data.type,
            fileListThumbnail: [
              {
                uid: 'thumbnail',
                name: 'thumbnail',
                status: 'done',
                url: response.data.thumbnail
              }
            ],
            thumbnail: response.data.thumbnail,
            fileListTopGallery: _.map(response.data.topGallery, (o, index) => ({
              uid: `topGallery${index}`,
              name: `topGallery${index}`,
              status: 'done',
              url: o
            })),
            topGallery: response.data.topGallery,
            content: response.data.content || [],
          })
          form.setFieldsValue({
            author: response.data.author,
            title: response.data.title,
            pinned: response.data.pinned,
            priority: response.data.priority,
            topVideo: response.data.topVideo,
          })
        })
        .catch(error => {
          message.error('Eroare la încărcare pentru editarea articolului')
          console.error(error);
          history.push('/article');
        });
    }
  }

  validationToSave = (apiCall, apiCallArguments) => {
    const { form, history } = this.props;

    form.validateFields((err, newArticleValues) => {
      if (err) return;
      if (!this.state.thumbnail) return;

      const body = {
        type: this.state.type,
        topGallery: this.state.topGallery,
        topVideo: newArticleValues.topVideo,
        content: this.state.content || [],
        author: newArticleValues.author,
        title: newArticleValues.title,
        thumbnail: this.state.thumbnail,
        pinned: newArticleValues.pinned,
        priority: newArticleValues.priority
      };

      apiCallArguments.push(body)

      this.setState({ isAdding: true });

      apiCall(...apiCallArguments)
        .then(response => {
          message.success("Operație efectuată cu succes!");
          history.push("/article");
        })
        .catch(error => {
          this.setState({ isAdding: false });
          if (error.response) {
            switch (error.response.status) {
              case 400: // Bad Request
                message.error("Verificați corectitudinea completării datelor.");
                break;
              default: {
                const { code } = error.response.data;
                if (code) {
                  message.error(`Eroare: ${code}`);
                } else {
                  message.error("Eroare.");
                }
                console.log(error);
              }
            }
          } else {
            message.error("Eroare.");
            console.log(error);
          }
        });

    });
  }

  handleSaveNewArticle = () => {
    this.validationToSave(createArticleV2, [])
  };

  handleEditArticle = () => {
    const { currentArticleId } = this.state;
    this.validationToSave(updateArticleV2, [currentArticleId])
  };


  handleChangeArticleType = type => {
    this.setState({
      type
    })
  }

  normFileThumbnail = e => {
    console.log('Upload event thumbnail:', e);
    if (e.file.response) this.setState({ thumbnail: e.file.response.url });
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  normFileTopGallery = e => {
    console.log('Upload event top gallery:', e);
    const topGallery = _.map(
      _.filter(e.fileList, file => file.response && file.response.url),
      file => file.response.url
    )
    this.setState({ topGallery })
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  generateTopFormItems = (getFieldDecorator) => {
    const { type, fileListTopGallery, topGallery } = this.state;
    const { auth, form } = this.props;
    if (type === 1) {
      return (
        <React.Fragment>
          <h2>Galeria foto de sus</h2>
          <Row type={"flex"} gutter={16} className={"section"}>
            <Col span={12}>
              <Form.Item label="Încarcă poze pentru galerie">
                <div className="dropbox">
                  {getFieldDecorator('imageTopGallery', {
                    initialValue: fileListTopGallery,
                    valuePropName: 'fileListTopGallery',
                    getValueFromEvent: this.normFileTopGallery,
                    rules: [
                      {
                        required: true,
                        message: 'Pozele din galerie sunt obligatorii!'
                      }
                    ]
                  })(
                    <Upload.Dragger
                      name="file"
                      action={`${config.backend.BASE_URL}/j4qCKfMC/images/`}
                      headers={{ authorization: `Bearer ${auth.token}` }}
                      multiple={true}
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        Click sau trage aici o imagine pentru upload
                      </p>
                      <p className="ant-upload-hint">
                        Funcționează și cu selecție multiplă
                      </p>
                    </Upload.Dragger>
                  )}
                </div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Preview galerie foto">
                {_.map(topGallery, p => (
                  <div
                    key={p}
                    style={{
                      backgroundImage: `url('${p}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      height: '300px',
                      marginBottom: '10px',

                    }}
                  />
                ))}
              </Form.Item>
            </Col>
          </Row>
        </React.Fragment>
      )
    } else if (type === 2) {
      return (
        <React.Fragment>
          <h2>Video-ul de sus</h2>
          <Row type={"flex"} gutter={16} className={"section"}>
            <Col span={12}>
              <Form.Item label="Video de sus (chiar dacă merge, verifică și în aplicația client să meargă - de obicei probleme cu copyrightul)" hasFeedback>
                {getFieldDecorator("topVideo", {
                  rules: [
                    {
                      required: true,
                      message: "Video-ul este obligatoriu!"
                    }
                  ]
                })(<Input placeholder="https://www.youtube.com/watch?v=qbXZZLa_y1U" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Preview video (chiar dacă merge, verifică și în aplicația client să meargă - de obicei probleme cu copyrightul)">
                {this.getYoutubeVideo(form.getFieldValue('topVideo'))}
              </Form.Item>
            </Col>
          </Row>
        </React.Fragment>
      )
    } else if (type === 3) {
      return (
        <React.Fragment>
          <h2>Video-ul</h2>
          <Row type={"flex"} gutter={16} className={"section"}>
            <Col span={12}>
              <Form.Item label="Video de sus (chiar dacă merge, verifică și în aplicația client să meargă - de obicei probleme cu copyrightul)" hasFeedback>
                {getFieldDecorator("topVideo", {
                  rules: [
                    {
                      required: true,
                      message: "Video-ul este obligatoriu!"
                    }
                  ]
                })(<Input placeholder="https://www.youtube.com/watch?v=qbXZZLa_y1U" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Preview video (chiar dacă merge, verifică și în aplicația client să meargă - de obicei probleme cu copyrightul)">
                {this.getYoutubeVideo(form.getFieldValue('topVideo'))}
              </Form.Item>
            </Col>
          </Row>
        </React.Fragment>
      )
    }
  }

  getYoutubeVideo = (content) => {   
    if (content) {
      //taken from client
      //do not use URLSearchParams because it is not available on client
      //we use the same code to not have any differences in parsing
      const splitted = _.split(content, '?')
      if (_.size(splitted) === 2) {
        const urlParams = _.split(splitted[1], '&')
        const arrayOfKv = _.map(urlParams, p => {
          const kv = _.split(p, '=')
          if (_.size(kv) === 2) {
            return [kv[0], kv[1]];
          }
          return null;
        })
        const idKv = _.find(arrayOfKv, kv => kv && kv[0] === 'v');
        const id = idKv ? idKv[1] : null;
        if (id) {
          return (
            <YouTube
              videoId={id}
            />
          )
        }
      }
    }
    return null;
  }

  generateContent = () => {
    const { content } = this.state;
    const { auth } = this.props;

    const final =_.map(content, (slice, indexSlice) => {
      if (!slice.type) return null;

      const firstCol = (
        <Col span={1}>
          <Button onClick={() => this.handleDeleteContentSlice(indexSlice)} type="danger" icon="delete" size="large" block/>
        </Col>
      )

      const secondCol = (
        <Col span={3}>
          <Select onChange={value => this.handleChangeContentSliceType(indexSlice, value)} value={slice.type} style={{ width: '100%' }}>
            <Select.Option size="large" key={'text'} value={'text'}>{'Conținut text'}</Select.Option>
            <Select.Option size="large" key={'image'} value={'image'}>{'Conținut imagine'}</Select.Option>
            <Select.Option size="large" key={'video'} value={'video'}>{'Conținut video'}</Select.Option>
          </Select>
        </Col>
      )

      if (slice.type === 'text') {
        return (
          <Row type={"flex"} gutter={16} className={"section"} key={indexSlice}>
            {firstCol}
            {secondCol}
            <Col span={10}>
              <Input.TextArea value={slice.content} onChange={e => this.handleChangeContentSliceContent(indexSlice, e.target.value)} rows={4} />
            </Col>
            <Col span={10}>
              <ReactMarkdown source={slice.content} linkTarget="_blank"/>
            </Col>
          </Row>
        )

      } else if (slice.type === 'image') {
        return (
          <Row type={"flex"} gutter={16} className={"section"} key={indexSlice}>
            {firstCol}
            {secondCol}
            <Col span={10}>
              <Upload.Dragger
                name="file"
                action={`${config.backend.BASE_URL}/j4qCKfMC/images/`}
                headers={{ authorization: `Bearer ${auth.token}` }}
                onChange={info => {
                  if (info && info.file && info.file.response && info.file.response.url) {
                    this.handleChangeContentSliceContent(indexSlice, info.file.response.url)
                  }
                }}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">
                  Click sau trage aici o imagine pentru upload  
                </p>
                <p className="ant-upload-hint">
                  Nu funcționează cu selecție multiplă
                </p>
              </Upload.Dragger>
            </Col>
            <Col span={10}>
              {slice.content ? (
                <div
                  style={{
                    backgroundImage: `url('${slice.content}')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    height: '300px'
                  }}
                />
              ) : null}
            </Col>
          </Row>
        )
      } else if (slice.type === 'video') {
        return (
          <Row type={"flex"} gutter={16} className={"section"} key={indexSlice}>
            {firstCol}
            {secondCol}
            <Col span={10}>
              <Input placeholder="https://www.youtube.com/watch?v=qbXZZLa_y1U" value={slice.content} onChange={e => this.handleChangeContentSliceContent(indexSlice, e.target.value)} />
              <p className="ant-upload-hint">
                Chiar dacă merge, verifică și în aplicația client să meargă - de obicei probleme cu copyrightul.
              </p>
            </Col>
            <Col span={10}>
              {this.getYoutubeVideo(slice.content)}
                  
            </Col>
          </Row>
        )
      }
    })

    return (
      <React.Fragment>
        {final}
        <Row type={"flex"} gutter={16} className={"section"}>
          <Col span={24}>
            <Button onClick={this.handleAddContentSlice} block>Adaugă încă un tip de conținut</Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  handleChangeContentSliceType = (index, value) => {
    const { content } = this.state;
    this.setState({
      content: [
        ..._.slice(content, 0, index),
        {
          type: value,
          content: null
        },
        ..._.slice(content, index+1, _.size(content))
      ]
    })
  }

  handleChangeContentSliceContent = (index, value) => {
    const { content } = this.state;
    this.setState({
      content: [
        ..._.slice(content, 0, index),
        {
          type: content[index].type,
          content: value
        },
        ..._.slice(content, index + 1, _.size(content))
      ]
    })
  }

  handleAddContentSlice = () => {
    const { content } = this.state;
    this.setState({
      content: [
        ..._.slice(content, 0, _.size(content)),
        {
          type: 'text',
          content: null
        },
      ]
    })
  }

  handleDeleteContentSlice = (index) => {
    const { content } = this.state;
    this.setState({
      content: [
        ..._.slice(content, 0, index),
        ..._.slice(content, index + 1, _.size(content))
      ]
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isAdding, edit, isLoading, fileListThumbnail, thumbnail } = this.state;
    const { auth } = this.props;

    return (
      <div className={"article-add-page"}>
        <Helmet title={edit ? 'Modifică un articol' : 'Adaugă un articol'}/>
        <Row type="flex" justify="space-between" className={"section"}>
          <Col>
            <h3>{edit ? 'Modifică un articol' : 'Adaugă un articol'}</h3>
          </Col>
          <Col>
            <Button
              type="primary"
              icon="plus"
              onClick={edit ? this.handleEditArticle : this.handleSaveNewArticle}
              loading={isAdding}
            >
              {edit ? 'Salvează modificările' : 'Salvează si adaugă articol'}
            </Button>
          </Col>
        </Row>
        <Spin spinning={isLoading} tip="Se încarcă...">

          <Form>
            <h2>Date despre articol</h2>
            <Row type={"flex"} gutter={16} className={"section"}>
              <Col span={6}>
                <Form.Item label="Tip de articol" hasFeedback>
                  {getFieldDecorator("type", {
                    initialValue: 1,
                    rules: [
                      {
                        required: true,
                        message: "Tipul este obligatoriu!"
                      }
                    ]
                  })(
                    <Select onChange={this.handleChangeArticleType}>
                      {_.map(articleTypesText, typeObject => (
                        <Select.Option key={typeObject.id} value={typeObject.id}>{typeObject.text}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Titlu" hasFeedback>
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        message: 'Titlul este obligatoriu'
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Autor" hasFeedback>
                  {getFieldDecorator("author", {
                    initialValue: 'Autopia',
                    rules: [
                      {
                        required: true,
                        message: 'Autorul este obligatoriu'
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Pin to top">
                  {getFieldDecorator("pinned", {
                    initialValue: false,
                    valuePropName: "checked",
                    rules: [
                      {
                        required: true
                      }
                    ]
                  })(<Checkbox />)}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Prioritate (0 - cea mai mică)" hasFeedback>
                  {getFieldDecorator("priority", {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        message: 'Prioritatea este obligatorie'
                      }
                    ]
                  })(<InputNumber />)}
                </Form.Item>
              </Col>
            </Row>
            <h2>Thumbnail</h2>
            <Row type={"flex"} gutter={16} className={"section"}>
              <Col span={12}>
                <Form.Item label="Încarcă thumbnail">
                  <div className="dropbox">
                    {getFieldDecorator('imageThumbnail', {
                      initialValue: fileListThumbnail,
                      valuePropName: 'fileListThumbnail',
                      getValueFromEvent: this.normFileThumbnail,
                      rules: [
                        {
                          required: true,
                          message: 'Thumbnail-ul este obligatoriu!'
                        }
                      ]
                    })(
                      <Upload.Dragger
                        name="file"
                        action={`${config.backend.BASE_URL}/j4qCKfMC/images/`}
                        headers={{ authorization: `Bearer ${auth.token}` }}
                      >
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">
                          Click sau trage aici o imagine pentru upload
                        </p>
                        <p className="ant-upload-hint">
                          Nu funcționează cu selecție multiplă
                        </p>
                      </Upload.Dragger>
                    )}
                  </div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Preview thumbnail">
                  {thumbnail ? (
                    <div
                      style={{
                        backgroundImage: `url('${thumbnail}')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        height: '300px'
                      }}
                    />
                  ) : null}
                </Form.Item>
              </Col>
            </Row>
            {this.generateTopFormItems(getFieldDecorator)}
          </Form>
          <h2>Conținut</h2>
          {this.generateContent()}
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, null)(Form.create()(ArticleAddOrEdit));
